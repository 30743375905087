import { useCallback, useEffect, useReducer, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { DataGrid } from "devextreme-react";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import {
  FilterContactStatus,
  filterStatusList,
} from "../../config/client/commonConfig";
import { formatDate } from "devextreme/localization";
import {
  fetchClientNames,
  fetchClientsInfo,
  fetchClientsInfoDataApi,
  fetchExceptionApi,
  fetchInvoiceApi,
  fetchtaskInvoiceApi,
  fetchRecentActivityAPI,
  fetchSpecificProjectApi,
  fetchTaskExecutionDetailsByIdApi,
  fetchExecutiontData,
  fetchestimateProjectApi,
  fetchTotalCostApi,
  fetchFunctionTypeApi,
  fetchExecutionDataList,
  fetchSidebarInfoApi,
  fetchClientData,
  fetchAnalyticsScript,
  fetchSpecificProjectDropdownApi,
} from "../../service/app/api-service";
import {
  collectIdsRecursively,
  transformData,
  useHelperHooks,
} from "../helper/utils";
import { TelemetryItem } from "../../pages/client/client-profile/types";
import {
  fetchDateRangeApi,
  fetchUserInformationData,
} from "../../service/admin/api-service";
import { fetchFunctionDropDownDataApi } from "../../service/datastudio/api-service";
import { replaceQueryUrl } from "../../utlis/helper";
import { GTAButtonEventHandler } from "../../utlis/GAT-event-handler";

export interface FilterState {
  client: any;
  status: string;
  urlStatus: string;
  clientId: string;
  projectId: string;
  projectName: string;
  opexParams?: any;
  showTelemetryModal: boolean;
  selectedExecution: any;
  functions: string;
}

const initialState: FilterState = {
  client: "All Clients",
  status: filterStatusList[0],
  urlStatus: "All Items",
  clientId: "",
  projectId: "",
  projectName: "All Projects",
  opexParams: { viewType: "opex" },
  showTelemetryModal: false,
  selectedExecution: null,
  functions: "All Functions",
};

const reducer = (state: FilterState, action: any) => {
  switch (action.type) {
    case "CLIENT":
      return {
        ...state,
        client: action.payload, // for client dashboard
      };
    case "PROJECTNAME":
      return {
        ...state,
        projectName: action.payload,
      };
    case "PROJECTID":
      return {
        ...state,
        projectId: action.payload,
      };
    case "STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "URLSTATUS":
      return {
        ...state,
        urlStatus: action.payload,
      };
    case "CLIENTID":
      return {
        ...state,
        clientId: action.payload, // for client dashboard
      };
    case "OPEXPARAMS":
      return {
        ...state,
        opexParams: action.payload,
      };
    case "SELECTED_EXECUTION":
      return {
        ...state,
        selectedExecution: action.payload, // used
      };
    case "SHOW_TELEMETRY_MODAL":
      return {
        ...state,
        showTelemetryModal: action.payload, // used
      };
    case "TEMPLATE_TYPE":
      return {
        ...state,
        functions: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useAppHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridRef = useRef<DataGrid>(null);

  const { data: clientDropDownData } = useFetchClientNames();
  const { data: clientData } = useFetchClient();
  const { data: templateFunction } = useFetchFunctionDropdown("template");
  const { data: sidebarInfo, isLoading: isSideInfoLoading } =
    useFetchSidebarInfo();
  const { data: projectDropDownData } = useFetchSpecificProjectDropdown(state.clientId);
  const getFunctionTypeList = templateFunction;
  const Client = useHelperHooks().getQueryParams("client");

  const formatClientData = useCallback(
    (name: any) => {
      if (name === "All Clients") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter([
          ["clientName", "=", name],
          "or",
          ["name", "=", name],
        ]);
      }
      if (clientData) {
        const selectedClient = clientData?.find(
          (client: { name: string }) => client.name === name
        );
        const clientId = selectedClient ? selectedClient.clientId : null;
        dispatch({ type: "CLIENTID", payload: clientId });
        const opexParamsData = { clientId: clientId, viewType: "opex" };
        dispatch({ type: "OPEXPARAMS", payload: opexParamsData }); // not for client dashboard
      }
      dispatch({ type: "CLIENT", payload: name });
    },
    [clientData]
  );

  useEffect(() => {
    if (Client) {
      dispatch({ type: "CLIENT", payload: Client });
      formatClientData(Client);
    }
  }, [Client, formatClientData]);

  const filterByClient = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: name }: { item: FilterContactStatus } = e;
      formatClientData(name);
      replaceQueryUrl("client", name);
      dispatch({ type: "PROJECTNAME", payload: "" });
      dispatch({ type: "PROJECTID", payload: "All Projects" });
      const eventName = `client filter ${name}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: name,
      });
    },
    [formatClientData]
  );

  const filterByProject = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item } = e;
      const { projectName, projectId } = item;
      dispatch({ type: "PROJECTID", payload: projectId });
      dispatch({ type: "PROJECTNAME", payload: projectName });
      if (projectName === "All Projects") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter([["projectName", "=", projectName]]);
      }
      const eventName = `project filter ${projectName}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: projectName,
      });
    },
    []
  );

  const filterByStatus = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: status }: { item: FilterContactStatus } = e;
      if (status === "All Items") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["status", "=", status]);
      }
      dispatch({ type: "STATUS", payload: status });
      const eventName = `status filter ${status}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: status,
      });
    },
    [gridRef]
  );

  const filterByUrlStatus = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: status }: { item: FilterContactStatus } = e;
      if (status === "All Items") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["status", "=", status]);
      }
      dispatch({ type: "URLSTATUS", payload: status });
      const eventName = `url status filter ${status}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: status,
      });
    },
    [gridRef]
  );

  const filterByFunction = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: functions }: { item: FilterContactStatus } = e;
      if (functions === "All Functions") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter([
          ["template_function", "=", functions],
        ]);
      }
      dispatch({ type: "TEMPLATE_TYPE", payload: functions });
      const eventName = `function filter ${functions}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: functions,
      });
    },
    [gridRef]
  );

  const onRowClick = useCallback(
    ({ data }: DataGridTypes.RowClickEvent) => {
      dispatch({ type: "SELECTED_EXECUTION", payload: data });
      dispatch({
        type: "SHOW_TELEMETRY_MODAL",
        payload: !state.showTelemetryModal,
      });
      const eventName = `row click ${data?.executionId}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: data?.executionId,
      });
    },
    [state.showTelemetryModal]
  ); // used

  const onPopUpCloseTelementry = useCallback(() => {
    dispatch({ type: "SHOW_TELEMETRY_MODAL", payload: false });
    const eventName = `close telemetry modal- ${window.location.pathname}`;
    GTAButtonEventHandler(eventName, {
      loaction: window.location.pathname,
    });
  }, []); // used

  return {
    state,
    filterByClient,
    filterByProject,
    filterByStatus,
    gridRef,
    clientDropDownData,
    projectDropDownData,
    filterByUrlStatus,
    clientData,
    onRowClick,
    onPopUpCloseTelementry,
    filterByFunction,
    getFunctionTypeList,
    sidebarInfo,
    isSideInfoLoading,
  };
};

export const useFetchClientNames = () => {
  return useQuery("fetch-clients-name", fetchClientNames, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const clientNames = data?.data?.map((item: any) => {
        return item?.name;
      });
      if (clientNames) {
        return ["All Clients", ...clientNames];
      }
      return ["All Clients"];
    },
  });
};

export const useFetchClient = () => {
  return useQuery("fetch-clients", fetchClientsInfo, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

const fetchRecentActivity = async ({ pageParam }: any, data: any) => {
  const response: any = await fetchRecentActivityAPI(data);
  if (response) {
    return response;
  }
};

export const useFetchRecentActivity = (fetchProps = {}) => {
  return useQuery(
    ["fetch-recent-activity", fetchProps],
    ({ pageParam = 1 }) => fetchRecentActivity({ pageParam }, fetchProps),
    {
      enabled: !!fetchProps,
      refetchOnWindowFocus: false,
      getNextPageParam: (_lastPage: any, pages: any) => {
        if (pages.length < 100) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      select: (data: any) => {
        const updatedData = data?.data?.map((item: any) => {
          return {
            ...item,
            name: item?.clientName,
          };
        });
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchExceptionData(
  clientId: string,
  projectId: string,
  executionId: string
) {
  return useQuery(
    ["fetch-exception-data"],
    () => fetchExceptionApi(clientId, projectId, executionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId && !!executionId,
      onSuccess: (data: any) => {},
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

const fetchFinopsCostApi = async (data: any) => {
  const { clientId, timePeriod, screen, startTime, endTime } = data || {};
  const queryParams = {
    clientId: clientId,
    screen: screen,
    frequency: timePeriod,
    startDate: formatDate(startTime, "yyyy-MM-dd"),
    endDate: formatDate(endTime, "yyyy-MM-dd"),
  };
  const response: any = await fetchTotalCostApi(queryParams);
  if (response) {
    return response;
  }
};

export const useFetchTotalCost = (data: any) => {
  return useQuery(["fetch-finops-cost", data], () => fetchFinopsCostApi(data), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export function useFetchTaskExecutionDetailsById(selectedExecution: any) {
  const { executionId, clientId, clientName, status, projectId } =
    selectedExecution || {};
  const isStatus = status === "Completed";
  const fnQueryClient = useQueryClient();
  return useQuery(
    ["fetch-task-execution-ById", executionId, clientId, projectId],
    () => fetchTaskExecutionDetailsByIdApi(executionId, clientId, projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!executionId && !!clientId,
      onSuccess: (data: any) => {
        if (isStatus) {
          fnQueryClient.invalidateQueries("fetch-task-execution");
        }
      },
      select: (data: any) => {
        const telemetryData = data?.data || [];
        const updatesData = [telemetryData]?.map((item: any) => {
          return {
            ...item,
            clientName: clientName,
            status: status,
            expanded: true,
            modules: item.modules?.map((module: any, index: number) => {
              const objIndex = `${index + 1}_1`;
              return {
                ...module,
                id: objIndex,
                expanded: true,
                telemetry: module.telemetry?.map(
                  (telemetry: any, telemetryIndex: number) => {
                    const telemetryId = `${objIndex}_${telemetryIndex + 1}`;
                    return {
                      ...telemetry,
                      id: telemetryId,
                      expanded: true,
                      result: [
                        {
                          id: telemetryId + "_1",
                          expanded: true,
                          executionDetails: telemetry?.executionDetails?.map(
                            (exe: any, executionId: number) => {
                              return {
                                ...exe,
                                id: `${telemetryId}_1_${executionId + 1}`,
                              };
                            }
                          ),
                          name: "Telemetry",
                        },
                        {
                          id: telemetryId + "_2",
                          expanded: true,
                          data: [{ fileName: "data.json" }],
                          name: "Data",
                        },
                        {
                          exception: [{ fileName: "exceptionData.json" }],
                          name: "Exception",
                          expanded: true,
                          id: telemetryId + "_3",
                        },
                      ],
                    };
                  }
                ),
              };
            }),
          };
        });
        // addExpandProperty(updatesData);
        const transformedData: TelemetryItem[] = updatesData
          ? transformData(updatesData)
          : [];

        if (transformedData) {
          const finalItemsId = collectIdsRecursively(transformedData);
          return { finalItemsId, source: transformedData };
        }
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchExecutionDataList(executionId: string) {
  return useQuery(
    ["fetch-execution-data-list", executionId],
    () => fetchExecutionDataList(executionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!executionId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchSelectedExecutionFileData(data: any) {
  const { projectId, fileExtension, filename, type } = data || {};
  const queryParams = {
    projectId: projectId,
    fileExtension: fileExtension,
    filename: filename,
    type: type,
  };
  return useQuery(
    ["fetch-execution-data", queryParams],
    () => fetchExecutiontData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!fileExtension && !!filename && !!type,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchDropDownData = () => {
  return useQuery("fetch-clients-info-data", fetchClientsInfoDataApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchSpecificProject = (clientId: string) => {
  return useQuery(
    ["fetch-project-byId", clientId],
    () => fetchSpecificProjectApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFinopsProject = (clientId: string) => {
  return useQuery(
    ["fetch-project-byId", clientId],
    () => fetchestimateProjectApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchInvoiceInfo = (clientId: any) => {
  return useQuery(
    ["fetch-invoice-info", clientId],
    () => fetchInvoiceApi(clientId),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchUserInformation = (userId: any) => {
  return useQuery(
    ["fetch-user-info", userId],
    () => fetchUserInformationData(userId),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDateRange = () => {
  return useQuery("fetch-date-range", fetchDateRangeApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchFunctionDropdown = (func_type: any) => {
  return useQuery(
    ["fetch-function-dropdown", func_type],
    () => fetchFunctionTypeApi(func_type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        const functionData = data?.data;
        const functions = functionData?.templateFunction;
        const functionTypes = functions?.map((item: any) => {
          return item?.types;
        });
        if (functionTypes) {
          return ["All Functions", ...functionTypes];
        }
        return ["All Functions"];
      },
    }
  );
};

export const useFetchTaskInfo = (clientId: any, projectId:any) => {
  return useQuery(
    ["fetch-invoice-info", clientId, projectId],
    () => fetchtaskInvoiceApi(clientId, projectId),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchFunctionDropDownData = (process: any) => {
  return useQuery(
    ["fetch-function-drop-down-data", process],
    () => fetchFunctionDropDownDataApi(process),
    {
      refetchOnWindowFocus: false,
      enabled: !!process,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

const transformMenu = (json: any) => {
  const icons: any = {
    Clients: "clients",
    "Data Studio": "dataStudio",
    "Digital Services": "digitalService",
    "Activity Monitor": "activityMonitor",
    "Tasks & Automation": "controlPanel",
    Finops: "finops",
    "Operations Workbench": "workbench",
    Administration: "administration",
  };

  return json.map((service: any) => ({
    text: service.service,
    icon: icons[service.service],
    path: "",
    items: service.items.map((item: any) => ({
      text: item.name,
      path: item.path,
    })),
  }));
};
export const useFetchSidebarInfo = () => {
  return useQuery(["fetch-sidebar-info"], () => fetchSidebarInfoApi(), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const navData = data?.data?.[0]?.Navigation;
      const transformedJson = transformMenu(navData);
      return transformedJson;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchSelectedProjectClientDataList = (
  id: string,
  location: string
) => {
  return useQuery(
    ["fetch-project-data", id, location],
    () => fetchClientData(id, location),
    {
      refetchOnWindowFocus: false,
      enabled: !!id && !!location,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useGetAnalyticsScript = () => {
  return useQuery(["fetch-analytics-script"], () => fetchAnalyticsScript(), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      console.log(data, "analytics");
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error, "analytics");
      console.log(error);
    },
  });
};

export function useFetchSpecificProjectDropdown(
  clientId: string
) {
  return useQuery(
    ["fetch-specific-project-dropdown", clientId],
    () => fetchSpecificProjectDropdownApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}
