import ActiveDocuments from "./activeDocuments";
import DocumentChart from "./documentSummary";
import ComputeUitilization from "./progressStages";
import DocumentGridView from "../DocumentGridView";
import "../../../../../styles/datastudio/ManagementView.scss";

const ManagementDashboard = (componentProps: any) => {
  const {
    catalogInfo,
    onRowClick,
    onRowPrepared,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    selectedDays,
    client,
    gridRef,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData
  } = componentProps;

  return (
    <>
      <div className="management-view-wrapper">
        <DocumentChart selectedDays={selectedDays} client={client} />
        <div className="management-details-wrapper">
          <ActiveDocuments selectedDays={selectedDays} />
          <ComputeUitilization selectedDays={selectedDays} client={client} />
        </div>
      </div>
      <div>
        <DocumentGridView
          catalogInfo={catalogInfo}
          onRowClick={onRowClick}
          onRowPrepared={onRowPrepared}
          handleClientLinkClick={handleClientLinkClick}
          handleProjectLinkClick={handleProjectLinkClick}
          handleTaskLinkClick={handleTaskLinkClick}
          gridRef={gridRef}
          telemetryData={telemetryData}
          setTelemetryData={setTelemetryData}
          metricsData={metricsData}
          setMetricsData={setMetricsData}
          pdfFinalData={pdfFinalData}
          setPdfFinalData={setPdfFinalData}
        />
      </div>
    </>
  );
};

export default ManagementDashboard;