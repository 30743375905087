import { useCallback } from "react";
import { Column, DataGrid } from "devextreme-react/data-grid";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { DropDownButton } from "devextreme-react";
import { CellDollar } from "../../../../../ui-components/dataGridFormat/dataGridFormat";
import "../../../../../../styles/client/resourceGrid.scss";

const OperationsAllocationGrid = (props: any) => {
  const { operationResourceData, setselectedOerations, selectedOerations } =
    props;

  const onItemClick = useCallback(
    (e: DropDownButtonTypes.ItemClickEvent) => {
      const selectedItem = { ...e?.itemData };
      const dailyCost =
        parseFloat(selectedItem?.cost_per_day?.replace("$", "")) || 0;
      selectedItem.per_month_cost = `$${(dailyCost * 30).toFixed(2)}`;
      selectedItem.quantity = "1";
      selectedItem.start_date = new Date();
      selectedItem.totalcost = `$${(
        dailyCost *
        30 *
        selectedItem.quantity
      ).toFixed(2)}`;

      const isItemAlreadyAdded = selectedOerations?.some(
        (item: { resourceId: any }) => item?.resourceId === selectedItem?.id
      );
      if (!isItemAlreadyAdded) {
        setselectedOerations((prevSelected: any) =>
          prevSelected?.some((item: any) => item?.id === selectedItem?.id)
            ? prevSelected
            : [...prevSelected, selectedItem]
        );
      }
    },
    [selectedOerations, setselectedOerations]
  );

  const onRowUpdated = (e: any) => {
    const updatedData = selectedOerations?.map((item: any) =>
      item?.id === e?.key
        ? {
            ...item,
            ...e.data,
            totalcost: `$${(
              parseFloat(item?.per_month_cost?.replace("$", "")) *
              e?.data?.quantity
            ).toFixed(2)}`,
          }
        : item
    );
    setselectedOerations(updatedData);
  };

  const onRowRemoved = (e: any) => {
    const updatedData = selectedOerations?.filter(
      (item: any) => item?.id !== e.key
    );
    setselectedOerations(updatedData);
  };

  const calculateTotalCost = (data: any) => {
    const perMonthCost =
      parseFloat(data?.per_month_cost?.replace("$", "")) || 0;
    const totalPerMonthCost = perMonthCost * data.quantity;
    return totalPerMonthCost;
  };

  return (
    <div className="resource-gird-calculations">
      <DropDownButton
        id="resource-opration-template"
        useSelectMode={false}
        items={operationResourceData}
        displayExpr="designation"
        keyExpr="id"
        text="Select Opration Resource"
        width={300}
        onItemClick={onItemClick}
      />
      <DataGrid
        className="resource-grid"
        dataSource={selectedOerations}
        keyExpr="id"
        editing={{
          mode: "row",
          allowUpdating: true,
          allowDeleting: true,
        }}
        onRowUpdated={onRowUpdated}
        onRowRemoved={onRowRemoved}
      >
        <Column
          dataField="designation"
          caption="Designation"
          allowEditing={false}
        />
        <Column
          dataField="cost_per_day"
          caption="Daily Cost"
          dataType="number"
          cellRender={CellDollar}
          allowEditing={false}
        />
        <Column
          dataField="per_month_cost"
          caption="Monthly Cost"
          dataType="number"
          allowEditing={false}
        />
        <Column dataField="start_date" caption="Start Date" dataType="date" />
        <Column dataField="end_date" caption="End Date" dataType="date" />
        <Column
          dataField="quantity"
          caption="No.of Resource"
          dataType="number"
        />
        <Column
          dataField="totalcost"
          caption="Total Cost / Month"
          calculateCellValue={calculateTotalCost}
          format={"currency"}
          allowEditing={false}
          alignment="right"
        />
      </DataGrid>
    </div>
  );
};

export default OperationsAllocationGrid;
