import { useMemo } from "react";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Button as ActionsButton,
  Editing,
  Item,
  Toolbar,
  Scrolling,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
import "../../../../../styles/client/ClientInformation.scss";
import EditProject from "../editProject";
import { useNavigate } from "react-router-dom";
import { HeaderFilter } from "devextreme-react/cjs/gantt";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellTimeZone,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../config/client/commonConfig";
import {
  useProjects,
} from "../../../../../hooks/client/useProject";
import MasterDetailView from "./helper-component/ProjectInfo";
import { GTAButtonEventHandler } from "../../../../../utlis/GAT-event-handler";

const ClientInfo = ({
  gridData,
  gridRef,
  selectedClient,
  showToolbar,
  height,
  searchvisible,
}: any) => {
  const navigate = useNavigate();

  const handleProjectLinkClick = (event: any, projectId: string) => {
    navigate(`/project-profile?projectId=${projectId}`);
    event.preventDefault();
    const eventName = `Project Profile: ${projectId}`;
    GTAButtonEventHandler(eventName);
  };

  const handleClientLinkClick = (event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
    const eventName = `Client Profile: ${clientId}`;
    GTAButtonEventHandler(eventName);
  };

  const {
    state: { editPopupVisible, selectedProject },
    onEditProjectClick,
    changeEditPopupVisibility,
  } = useProjects();

  const PanelProps = useMemo(() => {
    return {
      selectedProject,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedProject, editPopupVisible]);

  return (
    <>
    <div className="recent-activity-grid">
      <DataGrid
        className={`grid theme-dependent ${height}`}
        dataSource={gridData}
        remoteOperations={true}
        showBorders={true}
        id="gridContainer"
        allowColumnResizing={true}
        allowColumnReordering={true}
        repaintChangesOnly={true}
        noDataText={"No projects to display at this time"}
        ref={gridRef}
      >
        <Paging defaultPageSize={25} />\
        <SearchPanel visible={true} width={200}/>
        <HeaderFilter visible={true}/>
        <Column
          dataField="projectName"
          caption="Project Name"
          width={"30%"}
          cellRender={(data) => {
            const projectName = data?.data?.projectName;
            const projectId = data?.data?.projectId;
            return (
              <a href="*" onClick={(e) => handleProjectLinkClick(e, projectId)}>
                {projectName}
              </a>
            );
          }}
        />
        <Column
          dataField="clientName"
          caption="Client Name"
          width={"20%"}
          cellRender={(data) => {
            const clientName = data?.data?.clientName;
            const clientId = data?.data?.clientId;
            return (
              <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
                {clientName}
              </a>
            );
          }}
        />
        <Column
          dataField="projectDescription"
          dataType="string"
          caption="Project Description"
          width={"30%"}
        />
        <Column
          dataField="projectType"
          dataType="string"
          caption="Activity Type"
          width={"20%"}
        />
        <Column
          dataField="status"
          dataType="string"
          cellRender={ContactStatus}
          width={"10%"}
        />
        <Column
          dataField="startDate"
          dataType="string"
          cellRender={cellTimeZone}
          width={"20%"}
        />
        <Column
          dataField="endDate"
          dataType="string"
          cellRender={cellEndTime}
          width={"10%"}
          
        />
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating
          allowDeleting={true}
          key={"id"}
        />
        <Column caption="" type="buttons" width={120}>
          <ActionsButton
            name="edit"
            onClick={onEditProjectClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton
            name="delete"
            disabled={true}
            hint="delete"
            cssClass="action-image"
          />
        </Column>
        <MasterDetail enabled={true} component={MasterDetailView} />
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      <EditProject {...PanelProps} />
    </div>
    </>
  );
};

export default ClientInfo;
