import DocumentAnalyzer from "../../assets/media/background-digital-26.jpg";
import DataCatalog from "../../assets/media/background-digital-25.jpg";
import TasksAutomation from "../../assets/media/background-digital-17.jpg";
import AlertsExceptions from "../../assets/media/background-digital-6.jpg";
import DataLake from "../../assets/media/background-digital-5.jpg";
import Tutorials from "../../assets/media/background-digital-4.jpg";
import Reference from "../../assets/media/background-digital-22.jpg";

export const DataEngineeringServiceMap = [
  {
    id: 1,
    name: "Data Analyzer",
    image: DocumentAnalyzer,
    description: [
      "Ideal for interactive data analysis and transformation. Provides a visual interface for exploring and analyzing data.",
    ],
    navigation:'/data-analyzer?action=true'
  },
  {
    id: 2,
    name: "Data Catalog",
    image: DataCatalog,
    description: [
      "Manage all client document processing workflows from a single interface. Customized views provide a single point of development, QA and data distribution to client endpoints.",
    ],
    navigation:'/data-catalog?action=true'
  },
  {
    id: 3,
    name: "Tasks & Automation",
    image: TasksAutomation,
    description: [
      "Command center for managing all document processing tasks and managing client workflows. Provides real-time monitoring and alerts.",
    ],
    navigation:'/task-automation?action=true'
  },
  {
    id: 4,
    name: "Alerts & Exceptions",
    image: AlertsExceptions,
    description: [
      "Get realtime updates on document processing tasks, exceptions, and alerts. Manage exceptions and errors from a single interface. Provides access to full telemetry and error logs.",
    ],
    navigation:'/alert-exceptions?action=true'
  },
  {
    id: 5,
    name: "Client Data Lake",
    image: DataLake,
    description: [
      "Access to all client data within the Polaris Data Lake. Provides data storage, retrieval, and analysis capabilities.",
    ],
    navigation:'/data-lake-management?action=true'
  },
];

export const DataEngineeringGuides = [
  {
    id: 1,
    image: Tutorials,
    name: "Tutorials",
    description: [
      "Tutorials and guides for new users to get started with web scraping. Provides step-by-step instructions for setting up and running scraping tasks.",
    ],
    url: "",
  },
  {
    id: 2,
    name: "Reference documents",
    image: Reference,
    description: [
      "Reference documents for advanced users. Provides detailed information on web scraping best practices, tools, and techniques.",
    ],
    url: "https://www.google.com",
  }
];
