import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createUrlApi,
  fetchAllUrlsApi,
  updateUrlApi,
  deleteUrlClientById,
  fetchTemplateApi,
  fetchUrlSummaryApi,
  bulkURLsUploadApi
} from "../../service/client/api-service";
import { useCallback, useReducer } from "react";
import { UrlStateProps } from "../../pages/client/url-catalog/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

const initialState: UrlStateProps = {
  popupVisible: false,
  selectedClientData: null,
  EditpopupVisible: false,
  deletePopupVisible: false,
};

const reducer = (state: UrlStateProps, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, // used
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        EditpopupVisible: action.payload, // used
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload, // used
      };
    case "SELECTED_CLIENT_DATA":
      return {
        ...state,
        selectedClientData: action.payload, // used
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useUrl = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAddContactClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []);

  const onEditUrlClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_CLIENT_DATA", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectClient = (data: any) => {
    dispatch({ type: "SELECTED_CLIENT_DATA", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectClient(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []);

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []);

  return {
    state,
    onAddContactClick,
    changePopupVisibility,
    handleSelectClient,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditUrlClick,
    changeEditPopupVisibility,
  };
};

export const useFetchurl = () => {
  return useQuery("fetch-urls", fetchAllUrlsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const updatedData = data?.map((item: any) => {
        return {
          ...item,
          name: item?.customer_name,
        };
      });
      return updatedData;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateUrl = (
  toggleClientModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = isEdit ? updateUrlApi : createUrlApi;
  const message = isEdit ? "Client url updated successfully" : "New client url added";
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-urls");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDeleteUrlClient = (
  onDeleteHandler: () => void,
  url_id: string
) => {
  const queryClient = useQueryClient();
  return useQuery(["deleteClient", url_id], () => deleteUrlClientById(url_id), {
    enabled: !!url_id && false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      onDeleteHandler();
      queryClient.invalidateQueries("fetch-urls");
      showNotification({
        message: "Client url deleted successfully",
      });
    },
  });
};

export const useFetchTemplate = () => {
  return useQuery("fetch-template", fetchTemplateApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          ...item,
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchUrlSummary = () => {
  return useQuery(
    ["fetch-url-summary", ],
    () => fetchUrlSummaryApi(),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; 

export const useBulkUpload = (onClose:any) => {
  const fnQueryClient = useQueryClient();
  return useMutation(bulkURLsUploadApi, {
    onSuccess: () => {
      onClose();
      fnQueryClient.invalidateQueries("fetch-bulkURLsupload-project");
      showNotification({
        message: "URLs uploaded successfully",
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used