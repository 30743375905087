import DataGridPage from "./helper-component/taskDataGrid";
import {
  useFetchTask,
  useTask,
} from "../../../hooks/task/useTask";
import { useMemo } from "react";
import "../../../styles/task/TasksAutomation.scss";
import { TaskFormPopup } from "./helper-component/task-add/taskFormPopup";
import TaskDeletePopup from "./helper-component/task-start-delete/taskDeletePopup";
import TaskStartPopup from "./helper-component/task-start-delete/taskConfirmPopup";
import TaskEditPopup from "./helper-component/task-edit/taskEditPopup";
import TaskClonePopup from "./helper-component/task-clone/taskClonePopup";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import DropDownButton from "devextreme-react/drop-down-button";
import { Item } from "devextreme-react/toolbar";
import { dropDownOptions, filterStatusList } from "./types";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook } from "../../../hooks/app/useApps";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import TaskIcon from "../../../assets/icons/icon-task-2.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";

export const TasksAutomationPage = () => {
  const {
    state: {
      popupVisible,
      editPopupVisible,
      selectedTask,
      deletePopupVisible,
      startPopupVisible,
      clonePopupVisible,
    },
    onAddTaskClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onStartPopupClose,
    onEditClick,
    onEditTaskClick,
    changeEditPopupVisibility,
    onClonePopupOpen,
    onClonePopupClose,
  } = useTask();

  const {
    state: { client, status, projectName },
    filterByClient,
    filterByStatus,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef,
  } = useAppHook();

  const { data, refetch, isFetching } = useFetchTask();
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const DataGridProps = useMemo(() => {
    const renderStartTask = (cell: any) => {
      return (
        <PSIconText
          alt="Start"
          hint="Start"
          src={TaskIcon}
          width={28}
          height={28}
          onClick={() => onStartPopupOpen(cell)}
        />
      );
    };
  
    return {
      data,
      onAddTaskClick,
      onDeletePopupOpen,
      onStartPopupOpen,
      onEditClick,
      onEditTaskClick,
      onClonePopupOpen,
      onClonePopupClose,
      gridRef,
      renderStartTask,
    };
  }, [
    data,
    onEditClick,
    onEditTaskClick,
    onDeletePopupOpen,
    onStartPopupOpen,
    onAddTaskClick,
    onClonePopupOpen,
    onClonePopupClose,
    gridRef,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const PanelProps = useMemo(() => {
    return {
      selectedTask,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedTask, editPopupVisible]);

  const deleteProps = useMemo(() => {
    return {
      selectedTask,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [selectedTask, deletePopupVisible, onDeletePopupClose]);

  const startProps = useMemo(() => {
    return {
      selectedTask,
      startPopupVisible,
      onStartPopupClose,
    };
  }, [selectedTask, startPopupVisible, onStartPopupClose]);

  const cloneProps = useMemo(() => {
    return {
      selectedTask,
      clonePopupVisible,
      onClonePopupClose,
    };
  }, [selectedTask, onClonePopupClose, clonePopupVisible]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: DataGridPage,
      componentProps: DataGridProps,
    },
  ];

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={filterStatusList}
        stylingMode="text"
        text={status}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByStatus}
      />
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <DropDownButton
        stylingMode="text"
        text={projectName ? projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
      />
      <PSIconText
        text="Add Task"
        alt="Add Task"
        src={NewIcon}
        onClick={onAddTaskClick}
      />
    </Item>
  );

  const layoutProps = {
    headerText: "Tasks & Automation",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    isLoading: isFetching,
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <TaskFormPopup {...FormProps} />
      <TaskEditPopup {...PanelProps} />
      <TaskDeletePopup {...deleteProps} />
      <TaskStartPopup {...startProps} />
      <TaskClonePopup {...cloneProps} />
    </div>
  );
};
