import DataGrid, { Lookup } from "devextreme-react/data-grid";
import {
  Column,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  SearchPanel,
} from "devextreme-react/cjs/data-grid";
import { allowedPageSizes } from "../../../components/ui-components/fileViewer/types";
import { statusItems } from "../../../config/constants";
import { ContactStatus } from "../../../components/ui-components/contact-status/ContactStatus";
import { cellCreatedDate } from "../../../components/ui-components/dataGridFormat/dataGridFormat";
import PsButton from "../../../components/ui-components/button/Button";
import { useNavigate } from "react-router-dom";

const UserDataGrid = (props: any) => {
  const { userData, onRowUpdated } = props;
  const navigate = useNavigate();

  const AssignRoles = (cell: any) => {
    const { data } = cell;
    console.log(data,'data');
    const { userId } = data;
    const onAssignRoles = (event: any) => {
      navigate(`/user-administration/${userId}/permissions`);
    };
    return <PsButton text="Assign Roles" onClick={(e) => onAssignRoles(e)} />;
  };

  return (
  <div className="recent-activity-grid">
    <DataGrid
      className="grid theme-dependent height-large"
      dataSource={userData}
      noDataText={"No User to display at this time"}
      allowColumnReordering
      showBorders
      editing={{
        mode: "row",
        allowUpdating: true,
        allowDeleting: true,
      }}
      onRowUpdated={onRowUpdated}
      allowColumnResizing={true}
    >
      <SearchPanel visible={true} width={200}/>
      <HeaderFilter visible />
      <Column caption="User Name" dataField="name" dataType="string" />
      <Column caption="Given Name" dataField="given_name" dataType="string" />
      <Column
        caption="Status"
        dataField="status"
        dataType="string"
        cellRender={ContactStatus}
      >
        <Lookup dataSource={statusItems} />
      </Column>
      <Column caption="E-Mail" dataField="emails" dataType="string" />
      <Column
        caption="Assigned Roles"
        dataField="roles"
        dataType="string"
        cellRender={AssignRoles}
      />
      <Column
        caption="Created Date"
        dataField="createdDate"
        dataType="datetime"
        cellRender={cellCreatedDate}
      />
      <Selection
        mode="multiple"
        allowSelectAll={true}
        showCheckBoxesMode="always"
      />
      <Paging defaultPageSize={25} />
      <Pager
        visible={true}
        displayMode={"full"}
        allowedPageSizes={allowedPageSizes}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  </div>     
  );
};

export default UserDataGrid;
