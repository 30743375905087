import { useQueryClient } from "react-query";
import PsButton from "../../../../../ui-components/button/Button";

const CustomTitle = ({
  taskName,
  onClose,
  treeViewRef,
}: {
  taskName: string;
  onClose: any;
  treeViewRef: any;
}) => {
  const treeView = treeViewRef.current?.instance;
  const fnQueryClient = useQueryClient(); 
  
  const onExpandAllNodes = () => {
    treeView?.expandAll();
  };
  const onCollapseAllNodes = () => {
    treeView?.collapseAll();
  };

  const refresh = () => {
    fnQueryClient.invalidateQueries("fetch-task-execution-ById");
    fnQueryClient.invalidateQueries("fetch-exception-data");
    fnQueryClient.invalidateQueries("fetch-execution-data-list");
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "5px",
      }}
    >
      <div style={{ paddingLeft: "10px" }}>
        <span style={{ fontSize: "22px", fontWeight: "600" }}>
          Task Execution Details
        </span>
      </div>
      <div>
        <span style={{ fontSize: "16px", fontWeight: "500" }}> Task Name:</span>{" "}
        <span style={{ fontSize: "16px", fontWeight: "500" }}>{taskName}</span>
      </div>
      <div>
        <span
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <PsButton
            className="telementryPopupButton"
            icon="refresh"
            type="normal"
            mode="contained"
            width={'auto'}
            onClick={refresh}
            eventName='TelementryPopupButton refresh event'
          />
          <PsButton
            className="telementryPopupButton"
            icon="chevrondown"
            type="normal"
            mode="contained"
            width={'auto'}
            onClick={onExpandAllNodes}
            eventName='TelementryPopupButton expand'
          />
          <PsButton
            className="telementryPopupButton"
            icon="chevronup"
            type="normal"
            mode="contained"
            width={'auto'}
            onClick={onCollapseAllNodes}
            eventName='TelementryPopupButton collapse'
          />
          <PsButton
            className="telementryPopupButton"
            icon="close"
            type="normal"
            mode="contained"
            width={'auto'}
            onClick={onClose}
            eventName='TelementryPopupButton close'
          />
        </span>
      </div>
    </div>
  );
};

export default CustomTitle;
