import DataGrid, { Column, HeaderFilter, Paging, Pager } from "devextreme-react/data-grid";
import { allowedPageSizes } from './types';

export const GridView = (props: any) => {
  const { gridData } = props;

  const getColumnNames = (data: Array<Record<string, any>>): string[] => {
    const excludedColumns = ['clientId', 'projectId', 'taskId', 'createdBy','id','executionId','DocumentId'];
  
    if (data?.length > 0) {
      return Object.keys(data[0]).filter(columnName => !excludedColumns.includes(columnName));
    }
    return [];
  };
  
  const toSentenceCase = (str: string): string => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };
  


  const columns = getColumnNames(gridData)?.map((columnName, index) => {
    return (
      <Column
        key={index}
        dataField={columnName}
        caption={toSentenceCase(columnName)} 
        allowHeaderFiltering={true}
        alignment='left'
        cellRender={({ value }) => {
          if (typeof value === 'object' && value !== null) {
            return <pre>{JSON?.stringify(value, null, 2)}</pre>;
          }
          return <span>{value}</span>;
        }}
      />
    );
  });

  return (
    <DataGrid
      className="grid theme-dependent fileviewer-grid"
      // className="grid theme-dependent height-large"
      dataSource={gridData}
      noDataText={"No Files to display at this time"}
      rowAlternationEnabled={false}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      allowColumnReordering={true}
      hoverStateEnabled={true}
      wordWrapEnabled={true}
    >
      <HeaderFilter visible={true} />
      {columns}
      <Paging defaultPageSize={25} />
      <Pager
        visible={true}
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  );
};

export default GridView;
