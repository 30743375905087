import DataGrid, {
  Paging,
  HeaderFilter,
  Column,
  Editing,
  Pager,
} from "devextreme-react/data-grid";
import Legend from "../../../../components/ui-components/legend/legend";
import {  allowedPageSizes, dropDownOptions, GridActionItems } from "../../../../config/datastudio/commonConfig";
import { ResultGridProps } from "../types";
import { legendItems } from "../../../../config/datastudio/pageConfig";
import { DropDownButton } from "devextreme-react";
import PsCheckBox from "../../../../components/ui-components/checkbox/Checkbox";
import TableSkeleton from "../../../../components/ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton";

const ResultGrid = (props: ResultGridProps) => {
  const {
    gridDataSource,
    isGridDataLoading,
    isDataLoading,
    isDatafetching,
    handleRowPrepared,
    rowUpdated,
    getColumnNames,
  } = props;

  const handleItemClick = (e: any) => {
    console.log(e);
  };

  const onHanldeCheckbox = (e: any) => {
    e.event.stopPropagation();
    console.log(e);
  };

  const headerCellRender = () => {
    return (
      <DropDownButton
        text="Action"
        width="auto"
        stylingMode="text"
        items={GridActionItems}
        useSelectMode={true}
        displayExpr="text"
        dropDownOptions={dropDownOptions}
        onItemClick={handleItemClick}
        disabled={false}
        className="dropdown-export"
      />
    );
  };
  
  const columns = getColumnNames(gridDataSource)?.map(
    (columnName: any, index: number) => {
      if (
        columnName !== "is_duplicate" &&
        columnName !== "S.NO" &&
        columnName !== "confidence_status" &&
        columnName !== "show_null" &&
        columnName !== "status_message"
      ) {
        const formattedColumnName = columnName === columnName?.toLowerCase()
          ? columnName.charAt(0).toUpperCase() + columnName?.slice(1)
          : columnName;
        return (
          <Column
            key={index}
            dataField={columnName}
            caption={formattedColumnName?.replace(/_/g, " ")}
            allowHeaderFiltering={true}
            alignment="left"
          />
        );
      }
      return null;
    }
  );

  const renderAction = (cell: any) => {
    return (
      <PsCheckBox
        id="myfile-action"
        value={true}
        onChange={onHanldeCheckbox}
      />
    );
  };

  if (isDataLoading || isDatafetching) {
    return <TableSkeleton />;
  }
  
  return (
    <div className="recent-activity-grid">
      <DataGrid
        className="grid theme-dependent height-large"
        dataSource={gridDataSource}
        width={"100%"}
        noDataText={isGridDataLoading ? "Loading..." : "No Results to display at this time"}
        showBorders={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        onRowPrepared={handleRowPrepared}
        onRowUpdated={rowUpdated}
        wordWrapEnabled={true}
      >
        <Editing
          mode="cell"
          allowUpdating={true}
          allowDeleting={false}
          allowAdding={false}
        />
        <HeaderFilter visible={true} />
        <Column
          caption="Action"
          dataField="action"
          type="buttons"
          width={180}
          headerCellRender={headerCellRender}
          cellRender={renderAction}
          visible={gridDataSource.length > 0}
        />
        {columns}
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      <Legend items={legendItems} />
    </div>
  );
};

export default ResultGrid;
