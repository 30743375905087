import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import { Item } from "devextreme-react/toolbar";
import DropDownButton from "devextreme-react/drop-down-button";
import "../../../styles/finops/finops-dashboard.scss";
import { RevenueAnalysisCard } from "../../../components/finops/utils/revenue-analysis-card/RevenueAnalysisCard";
import { TechOpsCost } from "./helper-component/techOpCost";
import {
  useFetchInvoiceInfo,
  useFinops,
} from "../../../hooks/finops/useFinops";
import { useCallback, useMemo, useState } from "react";
import FinopsPieChart from "../../../components/finops/utils/finopsPieChart/DonutChart";
import { InfraCost } from "./helper-component/infraCost-details";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import InvoiceDetails from "../invoice/invoicedetails";
import {
  useFetchDateRange,
  useFetchTotalCost,
} from "../../../hooks/app/useApps";
import { customDateRange } from "../../../config/constants";

interface SelectedValue {
  clientId?: string;
  projectId?: string;
  projectName?: string;
  startTime?: string;
  endTime?: string;
  timePeriod?: string;
  screen?: string;
}

export const FinopsDashboardPage = () => {
  const invData = useFetchInvoiceInfo();
  const {
    state: { client },
    filterByClientProject,
    modifiedClientDropDownData,
    modifiedProjectDropDownData,
  } = useFinops();

  const [selectedValue, setSelectedValue] = useState<SelectedValue>({});
  const {
    data: finopsData,
    isLoading,
    refetch,
    isFetching,
  } = useFetchTotalCost(selectedValue);
  const { data: datedata } = useFetchDateRange();

  const filterByClientData = (e: any) => {
    const { item } = e;
    if (item && item.clientId) {
      setSelectedValue({
        ...selectedValue,
        clientId: item.clientId,
        projectId: "",
        projectName: "All Projects",
      });
      filterByClientProject(e);
    } else {
      setSelectedValue({
        ...selectedValue,
        clientId: "",
        projectId: "",
        projectName: "All Projects",
      });
    }
  };
  const filterByProject = (e: any) => {
    const { item } = e;
    if (item && item.projectId) {
      setSelectedValue({
        ...selectedValue,
        projectId: item.projectId,
        projectName: item.projectName,
      });
    } else {
      setSelectedValue({
        ...selectedValue,
        projectId: "",
        projectName: "All Projects",
      });
    }
  };


  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod] = value?.value;
      const screen = "finops-dash";
      if (timePeriod === customDateRange) {
        setSelectedValue({
          startTime: startTime,
          endTime: endTime,
          timePeriod: timePeriod,
          screen: screen,
        });
      } else {
        setSelectedValue({ timePeriod: timePeriod, screen: screen });
      }
    },
    [setSelectedValue]
  );

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        text={client.name ? client.name : "All Clients"}
        stylingMode="text"
        displayExpr="name"
        dropDownOptions={{ width: "auto" }}
        items={modifiedClientDropDownData}
        useSelectMode
        onSelectionChanged={filterByClientData}
      />
      <DropDownButton
        text={
          selectedValue.projectName ? selectedValue.projectName : "All Projects"
        }
        stylingMode="text"
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={modifiedProjectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
      />
      <DateDropDown onDateChange={onCurrentValueChange} data={datedata} />
    </Item>
  );

  const DataGridProps = useMemo(() => {
    return {
      showToolbar: false,
      height: "height-small",
    };
  }, []);

  const PageBodyComponent = () => [
    {
      title: "Invoice",
      Component: InvoiceDetails,
      componentProps: DataGridProps,
      pageRoute: "/ClientInvoice",
      reloadParams: "fetch-invoiceInfo",
    },
  ];

  const layoutProps = {
    showToolbar: false,
    refetch: invData.isFetching,
    pageBodyComponent: PageBodyComponent(),
    isLoading: invData.isFetching,
  };

  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics
        title={"FinOps Dashboard"}
        refresh={refetch}
        additionalToolbarContent={AdditionalToolbarContent}
      ></ToolbarAnalytics>
      <div className="dx-card details-card right">
        <TabPanel
          focusStateEnabled={false}
          deferRendering={false}
          width={"100%"}
          animationEnabled={true}
          swipeEnabled={true}
        >
          <TabPanelItem title="Total">
            <div
              style={{
                display: "flex",
                height: "550px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading || isFetching ? (
                <LoadIndicator id="small-indicator" height={20} width={20} />
              ) : (
                <>
                  <div style={{ flex: 1 }}>
                    <FinopsPieChart dataSource={finopsData} />
                  </div>
                  <div style={{ flex: 1 }}>
                    <RevenueAnalysisCard datasource={finopsData?.totalCost} />
                  </div>
                </>
              )}
            </div>
          </TabPanelItem>
          <TabPanelItem title="Digital Services">
            <div style={{ width: "100%", height: "550px" }}>
              <TechOpsCost
                cardData={finopsData?.techCost}
                sales={finopsData?.techCost}
                salesByState={finopsData?.techCost}
              />
            </div>
          </TabPanelItem>
          <TabPanelItem title="Operations">
            <div style={{ width: "100%", height: "550px" }}>
              <TechOpsCost
                cardData={finopsData?.operationCost}
                sales={finopsData?.operationCost}
                salesByState={finopsData?.operationCost}
              />
            </div>
          </TabPanelItem>
          <TabPanelItem title="Infrastructure">
            <div
              style={{
                width: "100%",
                height: "550px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <InfraCost
                sales={finopsData?.infraCost}
                salesByState={finopsData?.infraCost}
              />
            </div>
          </TabPanelItem>
        </TabPanel>
      </div>
      <DataGridTitleCardLayout {...layoutProps} />
    </div>
  );
};
