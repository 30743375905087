import { useCallback, useMemo, useState } from "react";
import { Item } from "devextreme-react/toolbar";
import {
  useCreateWorkBook,
  useFetchSharedData,
  useFetchWorkBooks,
  useWorkBench,
} from "../../hooks/workbench/useWorkbench";
import WorkbenchLayout from "./layout/WorkbenchLayout";
import WorkbenchCards from "./helper-component/workbenchCards/Workbenchcards";
import AddNewWorkBook from "./helper-component/form/NewWorkBook";
import { useApp } from "../../contexts/app";
import WorkbookIcon from "../../assets/media/workbook.png";
import SharedWorkbookIcon from "../../assets/media/workspace-shared-1.png";
import { newWorkBookFormData, WorkBookData } from "./types";
import NewIcon from "../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../components/ui-components/icon-with-text/IconText";

const Workbooks = () => {
  const {
    state: { isWorkBookShow },
    handleAddWorkBook,
  } = useWorkBench();

  const { user } = useApp();
  const {
    data: workBooksData,
    isLoading: isWorkBookLoading,
    isSuccess: hasWorkbooksLoaded,
  } = useFetchWorkBooks({ user: user?.userId });

  const {
    data: sharedworkBook,
    isLoading: isSharedLoading,
    isSuccess: hasSharedBookLoaded,
  } = useFetchSharedData(user?.userId, "workbook");
  const [newWorkBookData, setNewWorkBookData] = useState<WorkBookData>({
    ...newWorkBookFormData,
  });

  const {
    mutate: mutateWorkbook,
    isLoading: isWorkbookLoading,
    isSuccess: isSuccessWorkBook,
  } = useCreateWorkBook(handleAddWorkBook);

  const updateWorkbookField = useCallback(
    (field: string) => (value: any) => {
      setNewWorkBookData({ ...newWorkBookData, [field]: value });
    },
    [newWorkBookData]
  );
  const handleSaveSumbitWorkBook = useCallback(() => {
    mutateWorkbook({
      ...newWorkBookData,
      createdBy: user?.userId,
      createdName: user?.name,
    });
  }, [mutateWorkbook, newWorkBookData, user]);

  const combinedWorkbooksData = useMemo(() => {
    const regularWorkspace =
      workBooksData?.map((item: any) => ({
        ...item,
        imageIcon: WorkbookIcon,
      })) || [];
    return [...regularWorkspace];
  }, [workBooksData]);

  const combinedSharedWorkbooksData = useMemo(() => {
    const regularSharedWorkspace =
      sharedworkBook?.map((item: any) => ({
        ...item,
        imageIcon: SharedWorkbookIcon,
      })) || [];
    return [...regularSharedWorkspace];
  }, [sharedworkBook]);

  const WorkBooksProps = useMemo(() => {
    return {
      data: combinedWorkbooksData,
      isLoading: isWorkBookLoading,
      imageIcon: WorkbookIcon,
      message: `Click "${"New Workbook"}" to create your first workbook.`,
      sidepanelProps: {
        title: "New Workbook",
        Component: AddNewWorkBook,
        componentProps: {
          isOpened: isWorkBookShow,
          changePanelOpened: handleAddWorkBook,
          onSave: handleSaveSumbitWorkBook,
          newWorkBookData,
          updateField: updateWorkbookField,
          isSuccessWorkBook,
        },
        isLoadingButton: isWorkbookLoading,
        isWorkBookShow,
        handleAddWorkBook,
      },
    };
  }, [
    combinedWorkbooksData,
    isWorkBookLoading,
    isWorkBookShow,
    handleAddWorkBook,
    handleSaveSumbitWorkBook,
    newWorkBookData,
    updateWorkbookField,
    isSuccessWorkBook,
    isWorkbookLoading,
  ]);

  const SharedWorkSpaceProps = useMemo(() => {
    return {
      data: combinedSharedWorkbooksData,
      isLoading: isSharedLoading,
      imageIcon: SharedWorkbookIcon,
      // message: "Please add your workspace data.",
    };
  }, [combinedSharedWorkbooksData, isSharedLoading]);

  const cardDetails = useMemo(
    () => [
      {
        title: "My Workbooks",
        Component: WorkbenchCards,
        componentProps: WorkBooksProps,
        componentId: "workbooks-card",
        hasCard: hasWorkbooksLoaded,
      },
      {
        title: "Shared Workbooks",
        Component: WorkbenchCards,
        componentProps: SharedWorkSpaceProps,
        componentId: "shared-workbooks-card",
        hasCard: hasSharedBookLoaded,
      },
    ],
    [
      SharedWorkSpaceProps,
      WorkBooksProps,
      hasSharedBookLoaded,
      hasWorkbooksLoaded,
    ]
  );

  const additionalToolbarContent = useMemo(() => {
    return (
      <Item location="after" locateInMenu="auto">
        <PSIconText
          src={NewIcon}
          alt="New Workbook"
          onClick={handleAddWorkBook}
          text={"New Workbook"}
          eventName="Create Workbook event"
        />
      </Item>
    );
  }, [handleAddWorkBook]);

  const WorkbenchLayoutProps = useMemo(() => {
    return {
      headerText: "Workbooks",
      additionalToolbarContent,
      cardDetails,
      activityData: {},
    };
  }, [additionalToolbarContent, cardDetails]);

  return <WorkbenchLayout {...WorkbenchLayoutProps} />;
};

export default Workbooks;
