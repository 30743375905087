import { useState } from "react";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  DataGridTypes,
} from "devextreme-react/data-grid";
import PsAccordion from "../../ui-components/accordion/accordion";
import { CustomItem, CustomTitle } from "./invoiceComponent";
import ScrollView from "devextreme-react/scroll-view";
import { Popup } from "devextreme-react/popup";
import { CellDollar, cellStartTime } from "../../ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../ui-components/contact-status/ContactStatus";
import "../../../styles/core-components//invoiceInformation.scss";

const InvoiceInformations = (props: any) => {
  const { clientInfo, isLoading } = props;
  const clientInfoData = clientInfo?.[0]?.billDate;
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (e: any) => {
    setSelectedRowData(e.data);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedRowData(null);
  };

  const detailRenderTask = (detail: DataGridTypes.MasterDetailTemplateData) => {
    return (
      <div>
        <PsAccordion
          dataSource={detail.data?.invoice}
          Component={CustomItem}
          CustomTitle={CustomTitle}
        />
      </div>
    );
  };

  const clientRender = (data: any) => {
    const clientData = data?.data?.projects || [];
    return (
      <div>
        <DataGrid
          dataSource={clientData}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          showBorders={true}
          allowColumnReordering={true}
        >
          <Paging defaultPageSize={25} />
          <Column dataField="projectName" dataType="string" />
          <Column dataField="description" dataType="string" />
          <Column dataField="status" dataType="string" cellRender={ContactStatus} />
          <Column dataField="startDate" dataType="string" cellRender={cellStartTime} />
          <MasterDetail enabled={true} render={detailRenderTask}></MasterDetail>
        </DataGrid>
      </div>
    );
  };

  return (
    <ScrollView>
      <div>
        <DataGrid
          className="grid theme-dependent"
          dataSource={clientInfoData}
          noDataText={"No Invoice detail to display at this time"}
          repaintChangesOnly={true}
          height="100%"
          allowColumnReordering={true}
          columnAutoWidth={true}
          showBorders={true}
          onRowClick={handleRowClick}
        >
          <Paging defaultPageSize={25} />
          <Column dataField="invoiceId" caption="Invoice Id" dataType="string" />
          <Column dataField="bill_date" caption="Billing Period" dataType="string" />
          <Column dataField="date" caption="Invoice Date" dataType="string" />
          <Column dataField="totalcost" caption="Total Cost" dataType="string" cellRender={CellDollar} />
        </DataGrid>
      </div>
      <Popup
        visible={popupVisible}
        onHiding={closePopup}
        title="Invoice Details"
        width={1000}
        height={550}
        fullScreen={true}
      >

          {selectedRowData ? (
            <div>
              {clientRender({ data: selectedRowData })}
            </div>
          ) : (
            <div>No Data Available</div>
          )}

      </Popup>
    </ScrollView>
  );
};

export default InvoiceInformations;
