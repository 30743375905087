import { useMemo } from "react";
import PSFileManager from "../../../components/ui-components/FileManager/FileManager";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { useFileManager } from "../../../hooks/digital-service/useFileManager";
import { useHelperHooks } from "../../../hooks/helper/utils";

export const DataLakeManagement = () => {
  const { state,fileResources, handleSelectedFile,SingleFileData } = useFileManager();
  const {selectedFile} =  state;
  const { data: dataLakes, isLoading } = fileResources;
  const { data: file,isLoading:isSingleFileloading } = SingleFileData;
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));



  const DataGridProps = useMemo(() => {
    return {
      data: dataLakes,
      onHandleFileView: handleSelectedFile,
      fileviewData: file,
      extension: selectedFile?.fileExtension,
      isSingleFileloading
    };
  }, [dataLakes, handleSelectedFile, file, selectedFile, isSingleFileloading]);

  const PageBodyComponent = useMemo(
    () => [
      {
        mode: "management",
        Component: PSFileManager,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps]
  );

  const layoutProps = {
    headerText: "Data Lake Management",
    pageBodyComponent: PageBodyComponent,
    isLoading,
    layoutView: "managmentView",
    showBackButton: showBackButton,
  };

  return <DataGridMultiViewLayout {...layoutProps} />;
};

export default DataLakeManagement;
