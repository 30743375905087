import { useState } from "react";
import "../../../styles/client/client-profile.scss";
import { useCallback, useMemo } from "react";
import ClientProfileTab from "./helper-component/clientProfileTab";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { Item } from "devextreme-react/toolbar";
import ClientInfo from "../projects/helper-component/clientInformation/clientInfoV2";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import {
  useFetchProjectClientInfo,
  useFetchSpecificClient,
} from "../../../hooks/client/useClientProfile";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { customDateRange } from "../../../config/constants";

export const ClientProfile = () => {
  const { data: DateRange } = useFetchDateRange();
  const customerId = useHelperHooks().getQueryParams("clientId");
  const { data: projectInfo } = useFetchProjectClientInfo(customerId as string);
  const { data: customerDetails, isFetching, refetch } = useFetchSpecificClient(
    customerId as string
  );

  const title = customerDetails
    ? `Client 360 - ${customerDetails[0]?.name}`
    : "Client 360";

  const [selectedDays, setSelectedDays] = useState({});

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod ] = value?.value;
      const clientId = customerId;
      const screen = "homeGraph";
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod, clientId, screen});
      }
      else {
        setSelectedDays({ timePeriod, clientId, screen });
      }
    },
    [setSelectedDays, customerId]
  );

  const DataGridProps = useMemo(() => {
    return {
      clientId: customerId,
      PagerVisible: true,
      toolbarVisible: true,
      selectedDays,
      showToolbar: false,
      refetch,
      isFetching,
    };
  }, [customerId, selectedDays, refetch, isFetching]);

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      <DateDropDown onDateChange={onCurrentValueChange} data={DateRange} />
    </Item>
  );

  const pageBodyComponent = () => [
    {
      title: "",
      Component: ClientProfileTab,
      componentProps: DataGridProps,
      reloadParams: ['fetch-client-byId', 'fetch-client-document-list', 'fetch-collaboration', 'fetch-invoice-info', 'fetch-ytdmtd-cost', 'fetch-task-details', 'fetch-finops-cost']
    },
    {
      title: "Projects",
      Component: ClientInfo,
      componentProps: {
        gridData: projectInfo,
        showToolbar: false,
        height: "height-small",
      },
      pageRoute: "/projects",
      componentId: "clientinfo",
      reloadParams: ['fetch-project-clientInfo', 'fetch-projectInfo']
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: { showToolbar: false, height: "height-small", selectedDays: selectedDays, onCurrentValueChange: onCurrentValueChange },
      pageRoute: "/activity-dashboard",
      componentId: "activitydashboard",
      reloadParams: 'fetch-recent-activity'
    },
    {
      title: "Alerts & Exceptions",
      Component: AlertExceptionPage,
      componentProps: { showToolbar: false, height: "height-small", selectedDays: selectedDays, onCurrentValueChange: onCurrentValueChange },
      pageRoute: "/alert-exceptions",
      componentId: "alertsexceptions",
      reloadParams: 'fetch-alert-Exception'
    },
  ];


  const LayoutProps = {
    headerText: title,
    additionalToolbarContent,
    isLoading: isFetching,
    showBackButton: true,
    pageBodyComponent: pageBodyComponent(),
  };

  return (
    <>
      <DataGridTitleCardLayout {...LayoutProps} />
    </>
  );
};
