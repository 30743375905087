import { FunctionsProps } from "../types";
import ResultGrid from "./ResultGrid";
import ApplyIcon from "../../../../assets/icons/icon-apply-2.png";
import ResetIcon from "../../../../assets/icons/icon-reset-1.png";
import { useCallback } from "react";
import PsCheckBox from "../../../../components/ui-components/checkbox/Checkbox";
import { CheckBoxTypes } from "devextreme-react/cjs/check-box";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";

const Functions = (props: FunctionsProps) => {
  const {
    fetchFunctionDropDownFeatures,
    tabView,
    handleDuplicates,
    hanldeOnclickApply,
    handleResetButton,
    resultGridProps,
  } = props;
  const featuresData = fetchFunctionDropDownFeatures || [];

  const { toggle } = tabView['functions'];

  const onhandleBasicFunction = useCallback(
    (args: CheckBoxTypes.ValueChangedEvent) => {
      handleDuplicates(args?.element.id, args?.value);
    },
    [handleDuplicates]
  );

  const renderBasicfunctions = useCallback(() => {
    return featuresData?.basicFunctions?.map((feature: any, index: any) => (
      <div key={`basic-${index}`} style={{ padding: "5px" }}>
        <PsCheckBox
          id={feature?.value}
          text={feature?.dropdown}
          onChange={onhandleBasicFunction}
        />
      </div>
    ));
  }, [featuresData?.basicFunctions, onhandleBasicFunction]);

  const renderAdvancedFunctions = () => {
    return featuresData?.advancedFunctions?.map((feature: any, index: any) => (
      <div key={`basic-${index}`} style={{ padding: "5px" }}>
        <PsCheckBox
          id={feature?.value}
          text={feature?.dropdown}
          onChange={onhandleBasicFunction}
        />
      </div>
    ));
  };

  const renderFunctions = () => {
    return (
      <>
        <div>
          <h6>Basic Functions</h6>
          <div className="split-functions">{renderBasicfunctions()}</div>
        </div>
        <div>
          <h6>Advanced Functions</h6>
          <div className="split-functions">{renderAdvancedFunctions()}</div>
        </div>
      </>
    );
  };
  return (
    <>
      {toggle ? (
        <div className="function-wrapper">
          <div className="action">
            <PSIconText
              text="Apply"
              alt="Apply"
              src={ApplyIcon}
              onClick={hanldeOnclickApply}
            />
            <PSIconText
              text="Reset"
              alt="Reset"
              src={ResetIcon}
              onClick={handleResetButton}
            />
          </div>
          <div className="function-flex">{renderFunctions()}</div>
        </div>
      ) : null}
      <ResultGrid {...resultGridProps} />
    </>
  );
};

export default Functions;
