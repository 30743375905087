import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  Sorting,
  Scrolling,
  Pager,
  MasterDetail,
  SearchPanel,
} from "devextreme-react/data-grid";
import { cellStartTime, cellUpdatedTime } from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { allowedPageSizes } from "../../../../config/datastudio/commonConfig";
import MasterDetailView from "./CatalogGrid/DocumentInfo";
import MyFilesView from "../../../../assets/icons/icon-eye.png";
import MetricsIcon from "../../../../assets/icons/icon-metrics-4.png";
import TelemetryIcon from "../../../../assets/icons/icon-telemetry-5.png";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import { useState } from "react";
import { useFetchMetricsDataApi } from "../../../../hooks/activity-monitor/useLogsMetrics";
import { useFetchSingleFiles } from "../../../../hooks/digital-service/useFileManager";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import TelemetryPopup from "../../../../components/core-component/recentActivity/helper-component/telemetry/Telemetry";

const DocumentGridView = (props: any) => {
  const {
    catalogInfo,
    onRowClick,
    onRowPrepared,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    gridRef,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData,
    disableLinks,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [titleData, setTitleData] = useState<any>("")
  const [showTelemetryModal, setShowTelemetryModal] = useState(false);

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const { data: metricsGridData, isLoading: isMetricsLoading } = useFetchMetricsDataApi(metricsData);
  const metricsInfo = metricsGridData?.data

  const {
    data: pdfInfo,
    isLoading: isPdfLoading,
  } = useFetchSingleFiles(pdfFinalData);

  const handleViewPdf = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setMetricsData(null);
    setTelemetryData(null);
    const titleParams = {
      fileName: data?.fileName ? data?.fileName : data?.DocumentName,
      status: data?.executionStatus,
      ext: 'pdf'
    };
    const extension = data?.DocumentName?.split('.')?.pop() || data?.fileName?.split('.')?.pop();
    const params = {
      name: data?.fileName ? data?.fileName : data?.DocumentName,
      fileExtension: extension,
      projectId: data?.projectId,
      type: data?.type,
    };
    setPdfFinalData(params);
    setTitleData(titleParams);
    setOpenPopup(true);
  };

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt="View File"
        hint="View File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewPdf(e, cell)}
      />
    );
  };

  const handleViewMetrics = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setPdfFinalData(null);
    setTelemetryData(null);
    const metricsParams = {
      projectId: data?.projectId,
      clientId: data?.clientId,
      executionId: data?.executionId,
    };
    const titleParams = {
      fileName: data?.DocumentName,
      status: data?.executionStatus,
      ext: 'json'
    };
    setMetricsData(metricsParams);
    setTitleData(titleParams);
    setOpenPopup(true);
  };

  const renderViewMetricsFile = (cell: any) => {
    return (
      <PSIconText
        src={MetricsIcon}
        alt="Metrics File"
        hint="Metrics File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewMetrics(e, cell)}
        disabled={cell?.data?.executionStatus === "Pending"}
      />
    );
  };

  const handleViewTelemetry = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setPdfFinalData(null);
    setMetricsData(null);
    const telemetryParams = {
      executionId: data?.executionId,
      clientId: data?.clientId,
      projectId: data?.projectId,
      taskName: data?.taskName,
    };
    setTelemetryData(telemetryParams);
    setShowTelemetryModal(true);
  };

  const renderViewTelemetryFile = (cell: any) => {
    return (
      <PSIconText
        src={TelemetryIcon}
        alt="Telemetry File"
        hint="Telemetry File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewTelemetry(e, cell)}
        disabled={cell?.data?.executionStatus === "Pending"}
      />
    );
  };

  const title = { file: titleData?.fileName, status: titleData?.status };

  const extension = titleData?.ext

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => onRowClick(cell)}
      >
        {cell.data.DocumentName}
      </span>
    );
  };

  return (
    <>
    <div className="recent-activity-grid">
      <DataGrid
        className="grid theme-dependent height-large"
        dataSource={catalogInfo}
        noDataText={"No document processing status available at the moment"}
        allowColumnReordering
        showBorders
        onRowPrepared={onRowPrepared}
        ref={gridRef}
        allowColumnResizing={true}
      >
        <SearchPanel visible={true} width={200}/>
        <HeaderFilter visible />
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" />
        <MasterDetail enabled={true} component={MasterDetailView} />
        <Column
          dataField="clientName"
          caption="Client"
          cellRender={(data) => {
            const clientName = data?.data?.clientName;
            const clientId = data?.data?.clientId;
            return disableLinks ? (
              <span>{clientName}</span>
            ) : (
              <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
                {clientName}
              </a>
            );
          }}
        />
        <Column
          dataField="projectName"
          caption="Project Name"
          cellRender={(data) => {
            const projectName = data?.data?.projectName;
            const projectId = data?.data?.projectId;
            return disableLinks ? (
              <span>{projectName}</span>
            ) : (
              <a href="*" onClick={(e) => handleProjectLinkClick(e, projectId)}>
                {projectName}
              </a>
            );
          }}
        />
        <Column
          dataField="taskName"
          caption="Task Name"
          cellRender={(data) => {
            const TaskName = data?.data?.taskName;
            const taskId = data?.data?.taskId;
            return disableLinks ? (
              <span>{TaskName}</span>
            ) : (
              <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                {TaskName}
              </a>
            );
          }}
        />
        <Column
          caption="File Name"
          dataField="DocumentName"
          dataType="string"
          cellRender={(data) => {
            const FileName = data?.data?.DocumentName;
            return disableLinks ? (
              <span>{FileName}</span>
            ) : (
              renderFileName(data)
            );
          }}
        />
        <Column caption="File Size" dataField="fileSize" dataType="string" />
        <Column caption="Uploaded By" dataField="uploadedBy" dataType="string" />
        <Column
          caption="Status"
          dataField="executionStatus"
          dataType="string"
          cellRender={ContactStatus}
        />
        <Column
          caption="created Date"
          dataField="createdDate"
          dataType="datetime"
          cellRender={cellStartTime}
        />
        <Column
          caption="updated Date"
          dataField="updatedDate"
          dataType="datetime"
          cellRender={cellUpdatedTime}
        />
        <Column caption="" type="buttons" cellRender={renderViewMetricsFile} width={70} />
        <Column caption="" type="buttons" cellRender={renderViewTelemetryFile} width={70} />
        <Column caption="" type="buttons" cellRender={renderViewFile} width={70} />
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      <TelemetryPopup
        selectedExecution={telemetryData}
        showTelemetryModal={showTelemetryModal}
        onRowClick={() => setShowTelemetryModal(false)}
      />
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={pdfInfo || JSON?.stringify(metricsInfo, null, 2)}
        title={title}
        isLoading={isPdfLoading || isMetricsLoading}
        extensionType={extension}
      />
    </div>    
    </>
  );
};

export default DocumentGridView;
