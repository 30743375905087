import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { STATUS_LIST } from "../constants";

export const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
  25,
  50,
  100
];

export const filterStatusList = ["All Items", ...STATUS_LIST];
export type ContactStatus = (typeof STATUS_LIST)[number];
export type FilterContactStatus = ContactStatus | 'All Items';
export const exportFormats = ["xlsx", "pdf"];


export const projectViewDropdown = [ 
  {
    name: "Grid",
    id: "grid",
    value: "gridView"
  }, {
    name: "Portfolio",
    id: "porfolio",
    value: "portfolioView"
  },
  {
    name:"Managment",
    id: "management",
    value: "managmentView"
  },
  {
    name: "Operational Excellence",
    id: "opex",
    value: "opexView"
  }]

export const keyMappings: { [key: string]: string } = {
  MOM: "Month On Month",
  "Success Count": "Total URL Success",
  "Failed Count": "Total URL Failed",
  "Success Metrics": "Successful Executions",
  "Failed Metrics": "Failed Executions",
 
};

export const replaceMappings: { [key: string]: string } = {
  url: "URL",
};


export const dataConfig :{ [key: string]: string } = {
  'Data Processed': 'propertyData',
  'URLs Processed': 'urlsData',
  'Success Executions': 'successData',
  'Failed Executions': 'failedData',
};

export const documnetconfig: { [key: string]: string } = {
  'Pending': 'pendingData',
  'Ready For Review': 'reviewData',
  'In Progress': 'inProgressData',  
  'Failed': 'failedData',
  'Rejected': 'rejectedData',
  'Completed': 'completedData',
};

export const opexPivotViewData = [
  {
    "name":"Data Processed",
    "value":"dataProcessed"
  },
  {
    "name":"URLs Processed",
    "value":"dataErrors"
  },
  {
    "name":"Success Executions",
    "value":"resources"
  },
  {
    "name":"Failed Executions",
    "value":"resources"
  },
  ]

  export const documenntData = [
    { "name": "Pending", "value": "pendingData" },
    { "name": "Ready For Review", "value": "reviewData" },
    { "name": "In Progress", "value": "inProgressData" },
    { "name": "Failed", "value": "failedData" },
    { "name": "Rejected", "value": "rejectedData" },
    { "name": "Completed", "value": "completedData" },
  ];
   

  export const activityDataType = ['Web Scraping','Document']

  export const location: any={
    "1_1_1":"Bronze",
    "1_2_1":"Silver",
    "1_3_1":"Gold",
  }

  export const clientLocation: any={
    "1_1":"Bronze",
    "1_2":"Silver",
    "1_3":"Gold",
  }
