import DataGrid, { Column } from 'devextreme-react/data-grid';
import InvoiceInformations from '../../../../client-profile/helper-component/invoiceInformation';
import { TreeView } from "devextreme-react/tree-view";
import { useNavigate } from 'react-router-dom';
import { ContactStatus } from '../../../../../../components/ui-components/contact-status/ContactStatus';
import { CellDollar, cellStartTime } from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import FileViewer from '../../../../../../components/ui-components/fileViewer/FileViewer';
import { cellEndTime } from '../../../../../../components/ui-components/dataGridFormat/dataGridFormat';
import { transformProjectStorageData, useFetchSelectedProjectFileData, useProjectProfile } from '../../../../../../hooks/client/useProjectProfile';
import { useMemo } from 'react';
import { GridComponent } from '../../../../../../components/core-component/dataLakeGrid/GridComponent';
import { location } from '../../../../../../config/client/commonConfig';

export function CustomTitle(data: any) {
  return <>
    <div className="header">{data?.title}</div>
  </>
}

export function CustomItem(data: any, activityType: any, projectName: string, params: any) {
  const navigate = useNavigate();

  const handleTaskLinkClick = (
    event: any,
    taskId: string
  ) => {
    navigate(`/client-task?taskId=${taskId}`);
    event.preventDefault();
  };
  const finops = data?.finops;

  const {
    state: { fileViewerPopupVisible, selectedFileData },
    onViewScrapingButtonClick,
  } = useProjectProfile();

  const { data: SelectedProjectFileData, isLoading } = useFetchSelectedProjectFileData(selectedFileData);

  const renderItem = (item: any) => {
    const gridProps = { 
      id: {"projectId":params?.projectId},
      location: location[item?.id],
      onViewScrapingButtonClick 
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data  ? (
          <GridComponent {...gridProps} />
        ) : null}
      </div>
    );
  };

  const transformData = useMemo(() => {
    if (data?.clientData?.length > 0) {
      return transformProjectStorageData(data?.clientData?.[0], projectName);
    }
    return [];
  }, [data, projectName]);

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <div>
      {data.departments && (
        <>
          <div className="header-title">{data.departments[0]?.title}</div>
          <DataGrid
            dataSource={data.departments[0]?.digitalServices}
            allowColumnReordering={true}
            showBorders={true}
            width="100%"
          >
            <Column dataField="designation" caption="Role" />
            <Column dataField='cost_per_day' caption='Rate' cellRender={CellDollar} />
            <Column dataField="status" caption="Status" cellRender={ContactStatus} />
            <Column dataField="startDate" caption="Start Date" cellRender={cellStartTime} />
            <Column dataField="endDate" caption="End Date" cellRender={cellEndTime} />
          </DataGrid>
        </>
      )}
      {data.departments && (
        <>
          <div className="header-title">{data.departments[1]?.title}</div>
          <DataGrid
            dataSource={data.departments[1]?.operations}
            allowColumnReordering={true}
            showBorders={true}
            width="100%"
          >
            <Column dataField="designation" caption="Role" />
            <Column dataField='cost_per_day' caption='Rate' cellRender={CellDollar} />
            <Column dataField="status" caption="Status" cellRender={ContactStatus} />
            <Column dataField="startDate" caption="Start Date" cellRender={cellStartTime} />
            <Column dataField="endDate" caption="End Date" cellRender={cellEndTime} />
          </DataGrid>
        </>
      )}
      {data.departments && (
        <>
          <div className="header-title">{data.departments[2]?.title}</div>
          <DataGrid
            dataSource={data.departments[2]?.infrastructure}
            allowColumnReordering={true}
            showBorders={true}
            width="100%"
          >
            <Column dataField="resource" caption="Role" />
            <Column dataField='cost_per_day' caption='Rate' cellRender={CellDollar} />
            <Column dataField="status" caption="Status" cellRender={ContactStatus} />
            <Column dataField="startDate" caption="Start Date" cellRender={cellStartTime} />
            <Column dataField="endDate" caption="End Date" cellRender={cellEndTime} />
          </DataGrid>
        </>
      )}
      {data?.tasks && (
        <DataGrid
          dataSource={data?.tasks}
          allowColumnReordering={true}
          showBorders={true}
          width="100%"
        >
          <Column dataField="taskName" caption='Task Name'
            cellRender={(data) => {
              const taskDescription = data?.data?.taskName;
              const taskId = data?.data?.taskId;

              return (
                <a
                  href={'null'}
                  onClick={(e) => handleTaskLinkClick(e, taskId)}
                >
                  {taskDescription}
                </a>
              );
            }}
          />
          <Column dataField="taskDescription" caption='Task Description' />
          <Column dataField='status' caption='Status' cellRender={ContactStatus} />

        </DataGrid>
      )}
      {data.finops && (
        <>
          <InvoiceInformations clientInfo={finops} isLoading={"isLoading"} />
        </>
      )}
      {data?.clientData && (
        <>
          <TreeView
            id="simple-treeview"
            items={transformData}
            itemRender={renderItem}
          />
        </>
      )}

      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewScrapingButtonClick}
        data={
          selectedFileData?.fileExtension === "pdf"
            ? SelectedProjectFileData?.data
            : JSON.stringify(SelectedProjectFileData?.data)
        }
        title={title}
        isLoading={isLoading}
        extensionType={selectedFileData?.fileExtension}
      />
    </div>
  );
}

