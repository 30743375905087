import {
  fetchInfraDetailsApi,
  stopContainerInstanceApi,
} from "../../service/task/api-service";
import { useMutation, useQuery } from "react-query";

export const useFetchInfraDetails = () => {
  return useQuery("fetch-infra", fetchInfraDetailsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.infra_data?.map((item: any) => {
        return item?.data;
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useStopProgress = () => {
  return useMutation(stopContainerInstanceApi, {
    onError: (error: Error) => {
      console.log(error);
    },
  });
};
