import { useCallback, useMemo } from "react";
import {
  ReactFlow,
  addEdge,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  Panel,
  ReactFlowProvider,
} from "@xyflow/react"; // Your custom node component
import TextUpdaterNode from "./CustomNode"; // Ensure this is your correct import

const initialNodess: any[] = [
  {
    id: "1",
    data: { label: "Step-1", description: "This is step 1", additionalInfo: "Additional Info" },
    sourcePosition: "right",
    position: { x: 0, y: 0 },
    type: "textUpdater",
  },
  {
    id: "2",
    data: { label: "Step-2", description: "This is step 2", additionalInfo: "Additional Info" },
    sourcePosition: "right",
    targetPosition: "left",
    position: { x: 200, y: 0 },
    type: "textUpdater",
  },
  {
    id: "3",
    data: { label: "Step-3", description: "This is step 3", additionalInfo: "Additional Info" },
    targetPosition: "left",
    position: { x: 400, y: 0 },
    type: "textUpdater",
  },
];

const initialEdgess = [
  { id: "1-2", source: "1", target: "2" },
  { id: "2-3", source: "2", target: "3" },
];

const EdgeTypesFlow = () => {
  const [nodes, , onNodesChange] = useNodesState(initialNodess);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdgess);
  const onConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const nodeTypes = useMemo(() => ({ textUpdater: TextUpdaterNode }), []);

  const onAddNode = () => {
    const newNodeId = (nodes.length + 1).toString(); // Generate a new unique ID
    const newNode:any = {
      id: newNodeId,
      data: {
        label: `Step-${newNodeId}`,
        description: `This is step ${newNodeId}`,
        additionalInfo: "Additional Info",
      },
      sourcePosition: "right",
      targetPosition: "left",
      position: { x: 600, y: 0 },
      type: "textUpdater",
    };

    const newEdge = {
      id: `${nodes[nodes.length - 1].id}-${newNodeId}`,
      source: nodes[nodes.length - 1].id,
      target: newNodeId,
    };
    setEdges((eds) => addEdge(newEdge, eds));
    onNodesChange([{ type: "add", item: newNode }]);
  };

  return (
    <ReactFlowProvider>
      <div style={{ width: "100%", height: "100vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          minZoom={0.2}
          maxZoom={2}
          fitView
        />
        <Panel>
          <button onClick={onAddNode}>Add</button>
        </Panel>
      </div>
    </ReactFlowProvider>
  );
};

export default EdgeTypesFlow;
