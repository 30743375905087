import { useNavigate } from "react-router-dom";
import "../../../styles/core-components/recentActivity.scss";
import { useCallback, useMemo, useState } from "react";
import TelemetryPopup from "./helper-component/telemetry/Telemetry";
import DataGridMultiViewLayout from "../../ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import RecentActivityGridView from "./helper-component/GirdView";
import RecentActivityBoardView from "./helper-component/BoardView";
import { DropDownButton } from "devextreme-react";
import DateDropDown from "../../ui-components/datebox/DateBox";
import { Item } from "devextreme-react/toolbar";
import { dropDownOptions } from "../../../config/constants";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook, useFetchRecentActivity } from "../../../hooks/app/useApps";

export const recentStatus = ["In Progress", "Completed", "Failed"];
interface ViewType {
  text: string;
  value: "gridView" | "boardView";
}

const viewOptions: ViewType[] = [
  { text: "Grid View", value: "gridView" },
  { text: "Board View", value: "boardView" },
];

const RecentActivityDataGrid = (props: any) => {
  const {
    showToolbar,
    dataSource,
    externalDataSource,
    filteredClientId,
    height,
    onCurrentValueChange,
    selectedDays,
    searchvisible,

  } = props;
  const projectId = useHelperHooks().getQueryParams("projectId");
  const clientId = useHelperHooks().getQueryParams("clientId");
  const taskId = useHelperHooks().getQueryParams("taskId");
  const [viewType, setViewType] = useState<"gridView" | "board">("gridView");
  const { data: DateRange } = useFetchDateRange();

  const {
    state: { selectedExecution, showTelemetryModal, client, projectName},
    onPopUpCloseTelementry,
    onRowClick,
    filterByClient,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef,
  } = useAppHook();

  const TelemetryProps = useMemo(() => {
    return {
      selectedExecution,
      showTelemetryModal,
      onRowClick,
      onPopUpCloseTelementry,
    };
  }, [
    onRowClick,
    showTelemetryModal,
    onPopUpCloseTelementry,
    selectedExecution,
  ]);

  const {
    data = [],
    refetch,
    isFetching,
  } = useFetchRecentActivity({
    ...selectedDays,
    projectId,
    clientId: filteredClientId ? filteredClientId : clientId,
    taskId,
  });

  const navigate = useNavigate();

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleViewChange = (e: any) => {
    const { item } = e;
    if (item) {
      setViewType(item.value);
    }
  };

  const DataGridProps = useMemo(() => {
    return {
      data: externalDataSource ? dataSource : data,
      onRowClick,
      PagerVisible: true,
      handleClientLinkClick,
      handleTaskLinkClick,
      handleProjectLinkClick,
      gridRef,
      client,
      height: height ? height : "height-large",
      searchvisible,
    };
  }, [
    externalDataSource,
    dataSource,
    data,
    onRowClick,
    handleClientLinkClick,
    handleTaskLinkClick,
    handleProjectLinkClick,
    gridRef,
    client,
    height,
    searchvisible,

  ]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: RecentActivityGridView,
      componentProps: DataGridProps,
    },
    {
      mode: "board",
      Component: RecentActivityBoardView,
      componentProps: DataGridProps,
    },
  ];

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <DropDownButton
        stylingMode="text"
        text={projectName ? projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
      />
      <DropDownButton
        text={
          viewOptions.find((item) => item.value === viewType)?.text || "View"
        }
        stylingMode="text"
        displayExpr="text"
        items={viewOptions}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={handleViewChange}
      />
      <DateDropDown onDateChange={onCurrentValueChange} data={DateRange} />
    </Item>
  );

  const layoutProps = {
    headerText: "Recent Activity",
    refetch,
    additionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: viewType,
    isLoading: isFetching,
    showToolbar: showToolbar,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <TelemetryPopup {...TelemetryProps} />
    </>
  );
};

export default RecentActivityDataGrid;
