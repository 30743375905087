import React, { ReactNode } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import "../../../styles/ui-components/ToolbarAnalytics.scss";
import PsButton from "../button/Button";
import PSIconText from "../icon-with-text/IconText";
import RefreshIcon from "../../../assets/icons/icon-reload-1.png";
import ExportIcon from "../../../assets/icons/icon-export-1.png";

type ToolbarAnalyticsProps = {
  title: string;
  additionalToolbarContent?: ReactNode;
  additionalToolbarBeforeContent?: ReactNode;
  showBackButton?: boolean;
  showToolbar?: boolean;
  refresh?: () => void;
  disabledRefresh?: boolean;
};

const ToolbarAnalytics = ({
  title,
  additionalToolbarContent,
  additionalToolbarBeforeContent,
  showBackButton = false,
  showToolbar = true,
  refresh,
  disabledRefresh,
}: React.PropsWithChildren<ToolbarAnalyticsProps>) => {
  const handleBackButtonClick = () => {
    window.history.back();
  };
  return (
    <Toolbar className="theme-dependent" id="ps-toolbar" visible={showToolbar}>
      {additionalToolbarBeforeContent}
      <Item location="before" visible={showBackButton}>
        <PsButton
          icon="arrowleft"
          mode="text"
          width={"auto"}
          onClick={handleBackButtonClick}
          eventName="Toolbar Back button event"
        />
      </Item>
      <Item location="before">
        <span className="toolbar-header">{title}</span>
      </Item>
      {additionalToolbarContent}
      <Item
        location="after"
        locateInMenu="auto"
        visible={refresh ? true : false}
        cssClass="toolbar-item-flex refresh"
      >
        <PSIconText
          text="Refresh"
          alt="refresh"
          src={RefreshIcon}
          disabled={disabledRefresh}
          onClick={() => {
            refresh && refresh();
          }}
          eventName="Toolbar refresh button event"
        />
      </Item>
      <Item location="after" locateInMenu="auto" visible={false}>
        <div className="separator" />
      </Item>
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        visible={false}
      >
        <PSIconText
          src={ExportIcon}
          text="Export"
          alt="export"
          onClick={() => {}}
          eventName="Toolbar export button event"
        />
      </Item>
    </Toolbar>
  );
};

export default ToolbarAnalytics;
