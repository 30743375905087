import Popup from "devextreme-react/popup";
import PsButton from "../../../../../components/ui-components/button/Button";
import { useDeleteTask } from "../../../../../hooks/task/useTask";

const TaskDeletePopup = (props: any) => {
  const { deletePopupVisible, onDeletePopupClose, selectedTask } = props;
  const { row } = selectedTask || {};
  const { data } = row || {};
  const { taskId, taskDescription } = data || {};
  const { isRefetching, refetch } = useDeleteTask(onDeletePopupClose, taskId);

  const loaderProps = {
    show: isRefetching,
    loadertext: "Please Wait...",
  };

  return (
    <Popup
      title="Delete Task"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height='auto'
    >
        <div>
          <p>Are you sure you want to delete the <b>{taskDescription}</b> Task ?</p>
          <p>If you delete your Task Details, you will not be able to recover it.</p>
        </div>
        <PsButton onClick={() => refetch()} text="confirm" type="normal" mode='contained' loaderProps={loaderProps} />
        <div style={{ float: 'right' }}>
          <PsButton onClick={onDeletePopupClose} text="Close" type="normal" mode='contained' />
        </div>
    </Popup>
  );
};

export default TaskDeletePopup;