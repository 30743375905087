import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import DataGrid, {
  Column,
  HeaderFilter,
  Sorting,
  Scrolling
} from "devextreme-react/data-grid";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import MyFilesView from "../../../../../assets/icons/icon-eye.png";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import FileViewer from "../../../../../components/ui-components/fileViewer/FileViewer";
import { useFetchHierarchyInfo } from "../../../../../hooks/datastudio/useDocumentCatalog";
import PsSkeleton from "../../../../../components/ui-components/skeleton/PsSkeleton";
import { useState } from "react";
import { useFetchSingleFiles } from "../../../../../hooks/digital-service/useFileManager";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const status = props.data?.data?.executionStatus;
  const dataCatalogId = props.data?.data?.DocumentId;
  const processType = "Document Catalog";
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  
  const closePopupException = () => {
    setOpenPopup(false);
  };

  const { data: hierarchyData, isLoading: isHierarchyLoading } = useFetchHierarchyInfo(status, processType, dataCatalogId);
  const detaildataView = hierarchyData

  const {
    data: fileViewerData,
    isLoading: fileViewerLoading,
  } = useFetchSingleFiles(selectedData);

  const handleViewFile = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = { 
      name: data?.fileName,
      fileExtension: data?.assetType,
      projectId: data?.projectId,
      type: data?.type,
    };
    setSelectedData(params);
    setOpenPopup(true);
  };

  const title = {file: selectedData?.name}

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt="View File"
        width={28}
        hint="View File"
        height={28}
      onClick={(e: any) => handleViewFile(e, cell)}
      />
    );
  };

  if (isHierarchyLoading) {
    return <PsSkeleton height={30} count={4} />;
  }

  return (
    <>

      <DataGrid
        dataSource={detaildataView}
        allowColumnReordering
        showBorders
        allowColumnResizing={true}
      >
        <HeaderFilter visible />
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" />
        <Column caption="File Name" dataField="fileName" dataType="string" />
        <Column caption="File Size" dataField="fileSize" dataType="string" />
        <Column caption="File Type" dataField="fileType" dataType="string" />
        <Column caption="Created Date" dataField="createdDate" dataType="datetime" cellRender={cellStartTime} />
        <Column caption="Modified Date" dataField="lastUpdated" dataType="datetime" cellRender={cellUpdatedTime} />
        <Column type="buttons" caption="" cellRender={renderViewFile} />
        <Scrolling rowRenderingMode="virtual"></Scrolling>
      </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={fileViewerLoading}
        extensionType={selectedData?.fileExtension}
      />
     
    </>
  );
};

export default MasterDetailView;
