import { formatDate } from "devextreme/localization";
import { get } from "../api/api";
import { constructDynamicUrl, makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchMetricsDataApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`logs/v1/logmetrics/executionInfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDataProcessDropDownApi = async (id: string) => {
  const response = await get(`core/v1/dropdown/client?clientId=${id}`);
  return response;
}; 

export const fetchSpecificProjectEditDetailsApi = async (id: string) => {
  const response = await get(`client/v1/project/info/project/${id}`);
  return response;
}; 
export const fetchClientAlertExceptionApi = async (queryParams: any) => {
  const frequency = {
    startTime: formatDate(queryParams.startTime, "yyyy-MM-dd"),
    endTime: formatDate(queryParams.endTime, "yyyy-MM-dd"),
    timePeriod: queryParams.timePeriod
  }
  const query = makeQueryParam(frequency);
  const templatequery  = constructDynamicUrl(queryParams);
  const validUrl = validateEndPoint(
    `automation/v1/alertsException/info/${templatequery}${query}`
  );
  const response = await get(validUrl);
  return response;
}; 
