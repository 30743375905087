import {
  fetchTaskDescriptionApi,
  createTaskApi,
  updateTaskApi,
  fetchImageApi,
  StartProgressApi,
  deleteTaskById,
  fetchTaskDetailApi,
  fetchSpecificProjectDetailsApi,
  fetchResouceDetailsApi,
  fetchUrlsApi,
  createCloneTaskApi,
  fetchProjectTypeDropdown,
  fetchtTaskData,
  fetchTaskDataList,
} from "../../service/task/api-service";
import { useCallback, useReducer } from "react";
import { TaskState } from "../../pages/task/tasksAutomation/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHelperHooks } from "../helper/utils";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

const initialState: TaskState = {
  popupVisible: false,
  taskId: 0,
  selectedTask: null,
  deletePopupVisible: false,
  startPopupVisible: false,
  editPopupVisible: false,
  clonePopupVisible: false,
  fileViewerPopupVisible: false,
  selectedFileData: null,
};

const reducer = (state: TaskState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, 
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload, 
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload,
      };
    case "SELECTED_TASK":
      return {
        ...state,
        selectedTask: action.payload,
      };
    case "SELECTED_CLIENT_DATA":
      return {
        ...state,
        selectedClientData: action.payload,
      };
    case "START_POPUP_VISIBLE":
      return {
        ...state,
        startPopupVisible: action.payload,
      };
    case "CLONE_POPUP_VISIBLE":
      return {
        ...state,
        clonePopupVisible: action.payload,
      };
    case "SHOW_FILE_VIEWER_POPUP":
      return {
        ...state,
        fileViewerPopupVisible: action.payload,
      };
    case "SELECTED_FILE_DATA":
      return {
        ...state,
        selectedFileData: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useTask = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAddTaskClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []); 

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  ); 

  const handleSelectTask = (data: any) => {
    dispatch({ type: "SELECTED_TASK", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []); 

  const onStartPopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "START_POPUP_VISIBLE", payload: true });
  }, []); 

  const handleSelectClient = (data: any) => {
    dispatch({ type: "SELECTED_CLIENT_DATA", payload: data });
  };

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []); 

  const onStartPopupClose = useCallback(() => {
    dispatch({ type: "START_POPUP_VISIBLE", payload: false });
  }, []); 

  const onEditTaskClick = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "SELECTED_TASK", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []); 

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: false });
    },
    []
  ); //used

  const onClonePopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "CLONE_POPUP_VISIBLE", payload: true });
  }, []); 

  const onClonePopupClose = useCallback(() => {
    dispatch({ type: "CLONE_POPUP_VISIBLE", payload: false });
  }, []); 

  const onEditClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_TASK", payload: data });
    dispatch({ type: "IS_PANEL_OPENED", payload: true });
  }, []); 

  const onViewScrapingButtonClick = useCallback(
    (e?: any, cell?: any) => {
      const data = cell?.row?.data || {};
      dispatch({
        type: "SHOW_FILE_VIEWER_POPUP",
        payload: !state.fileViewerPopupVisible,
      });
      dispatch({ type: "SELECTED_FILE_DATA", payload: data });
    },
    [state.fileViewerPopupVisible]
  );

  return {
    state,
    onAddTaskClick,
    changePopupVisibility,
    handleSelectClient,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onStartPopupClose,
    onEditClick,
    onEditTaskClick,
    onClonePopupOpen,
    onClonePopupClose,
    changeEditPopupVisibility,
    onViewScrapingButtonClick
  };
};
export const useFetchTask = () => {
  return useQuery("fetch-task", fetchTaskDescriptionApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; 

export const useFetchTaskDetails = () => {
  const taskId = useHelperHooks().getQueryParams("taskId");
  const QueryClient = useQueryClient();
  return useQuery(
    ["fetch-task-detail", taskId],
    () => fetchTaskDetailApi(taskId),
    {
      refetchOnWindowFocus: false,
      enabled: !!taskId,
      onSuccess: (data: any) => {
        QueryClient.setQueryData("task-details", data);
      },
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; 

export const useCreateTask = (
  toggleClientModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = isEdit ? updateTaskApi : createTaskApi;
  const message = isEdit ? "Task updated successfully" : "New task added successfully";
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-task");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateCloneTask = (
  toggleClientModal: () => void,
  taskId: string
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = async (params: any) => {
    const type = "clone";
    const { payload } = params;
    const payloadWithCloneType = { ...payload };
    const apiFunc = createCloneTaskApi(type, taskId, payloadWithCloneType);
    return await apiFunc;
  };
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-task");
      showNotification({
        message: "Task cloned successfully",
      });
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
  });
};

export const useFetchImage = () => {
  return useQuery("fetch-url-image", fetchImageApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          label: item.clientName,
          imageName: item.imageName,
          postProcessorModule: item.postProcessorModule
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; 

export const useStartProgress = (_id: string, taskId: string, userId: string, name: string) => {
  return useQuery(
    ["start-progress", _id, taskId, userId, name],
    () => StartProgressApi(_id, taskId, userId, name),
    {
      enabled: !!_id && !!taskId && !!userId && !!name && false,
      refetchOnWindowFocus: false,
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; 

export const useDeleteTask = (onDeleteHandler: () => void, taskId: string) => {
  const queryClient = useQueryClient();
  return useQuery(["deleteTask", taskId], () => deleteTaskById(taskId), {
    enabled: !!taskId && false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      onDeleteHandler();
      queryClient.invalidateQueries("fetch-task");
      showNotification({
        message: "Task deleted successfully",
      });
    },
  });
}; 

const fetchProjectDetailsApi = async (id: any) => {
  const response: any = await fetchSpecificProjectDetailsApi(id);
  if (response) {
    return response;
  }
};

export const useFetchProject = (id: string) => {
  return useQuery(["fetch-project", id], () => fetchProjectDetailsApi(id), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};


export const useFetchResouce = (type: string) => {
  return useQuery(
    ["fetch-resource", type],
    () => fetchResouceDetailsApi(type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; 

export function useFetchUrls(_id: string) {
  return useQuery(["fetch-url", _id], () => fetchUrlsApi(_id), {
    enabled: !!_id,
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          ...item,
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
} 

export const useFetchProjectTypeDropdown = () => {
  return useQuery("fetch-project-type-dropdown", fetchProjectTypeDropdown, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; 

export function useFetchTaskDataList(taskIds: any) {
  // const queryParams = {
  //   executionId: JSON.stringify(executionIds),
  //   taskId: JSON.stringify(taskIds),
  // };
  return useQuery(
    ["fetch-task-webscraping-list", taskIds],
    () => fetchTaskDataList(taskIds),
    {
      refetchOnWindowFocus: false,
      enabled: !!taskIds,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
} 

export function useFetchSelectedTaskFileData(
  data:any
) {
  const {projectId,fileExtension,filename,type} = data || {};
  const queryParams = {
    projectId: projectId,
    fileExtension: fileExtension,
    filename: filename,
    type: type,
  };
  return useQuery(
    ["fetch-project-data", queryParams],
    () => fetchtTaskData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!fileExtension && !!filename && !!type,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}


export const transformTaskStorageData = (data: any, taskName:string) => {
  const result: { id: string; text: string; expanded: boolean; items: any[] }[] = [
      {
          id: "1",
          text: `Polaris-data-lake (${taskName})`,
          expanded: true,
          items: []
      }
  ];

  let categoryId = 1;

  Object?.keys(data)?.forEach(category => {
      const categoryData = data[category] || [];

      const categoryNode = {
          id: `1_${categoryId}`,
          text: category,
          expanded: false,
          items: [
              {
                  id: `1_${categoryId}_1`,
                  text: "",
                  expanded: false,
                  items: [],
                  data: categoryData
              }
          ]
      };

      result[0]?.items?.push(categoryNode);
      categoryId++;
  });

  return result;
};