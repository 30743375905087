// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.telemetry-title {
  font-weight: bold;
  font-size: 18px;
}

.telemetry-sub-title {
  font-weight: bold;
}

.telementryPopupButton {
  height: 25px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/telemetry.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".telemetry-title{\n    font-weight:bold;\n    font-size:18px;\n}\n\n.telemetry-sub-title{\n    font-weight:bold;\n}\n\n.telementryPopupButton{\n    height: 25px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
