import { removeUndefinedProperties } from "../../../utlis/helper";

export const validateEndPoint = (endPoint: string) => {
    if (endPoint.endsWith('/') || endPoint.endsWith('?')) {
        return endPoint.slice(0, -1);
    }
    return endPoint;
};

//[TODo] Use create params methd
export const makeEndPoint = (params: any) => {
    const result = removeUndefinedProperties(params);
    let endPoint = Object.values(result).join('/');
    return validateEndPoint(endPoint);
};

export const makeQueryParam = (params: any) => {
    const result: any = removeUndefinedProperties(params);
    return `?${new URLSearchParams(result).toString()}`;
};

export const constructDynamicUrl = (data: any) => {
    const { projectId, clientId, taskId } = data || {};
  
    if (projectId) {
      return `project/${projectId}`;
    } else if (taskId) {
      return `task/${taskId}`;
    } else if (clientId) {
      return `client/${clientId}`;
    }else{
        return "all";
    }
  };
  