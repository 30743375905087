import "../../../styles/client/client-dashboard.scss";
import DataGrid, { Column, Paging,HeaderFilter } from "devextreme-react/data-grid";
import { useFetchTotalCost } from "../../../hooks/app/useApps";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { convertToUSD } from "../../../utlis/helper";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

type TotalCost = {
  name: string;
  value: string;
};

type ClientInfo = {
  clientName: string;
  clientid: string;
  totalCost: TotalCost[];
};

type TransformedData = {
  clientName: string;
  Total?: string;
  Infrastructure?: string;
  Digital_Services?: string;
  Operations?: string;
};

const transformData = (clientInfo: ClientInfo[]): TransformedData[] => {
  return clientInfo.map((client) => {
    const cost: Partial<TransformedData> = client.totalCost.reduce(
      (acc, item) => {
        const key = item.name.replace(" ", "_") as keyof TransformedData;
        const value = convertToUSD(item.value); 
        acc[key] = value;
        return acc;
      },
      {} as Partial<TransformedData>
    );
    return {
      clientName: client.clientName,
      clientId: client.clientid,
      ...cost,
    };
  });
};

const ComputeUtilization = (props: any) => {
  const { selectedDays } = props;
  const { data: totalCost, isFetching } = useFetchTotalCost(selectedDays);
  const clientInfo = totalCost?.client_info;
  
  let totalCostData = clientInfo ? transformData(clientInfo) : [];

  totalCostData = totalCostData.sort((a, b) => {
    const totalA = parseFloat(a.Total?.replace(/[^0-9.-]+/g,"") || "0");
    const totalB = parseFloat(b.Total?.replace(/[^0-9.-]+/g,"") || "0");
    return totalB - totalA;
  });

  const navigate = useNavigate();


  const handleClientLinkClick = (event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
    const eventName =   `clicent-dashboard: ${clientId}`;
    GTAButtonEventHandler(eventName);
  };


  if (isFetching) {
    return <Skeleton count={5} />;
  }

  return (
    <DataGrid
      className={`grid theme-dependent`}
      dataSource={totalCostData}
      noDataText={"No compute cost  to display at this time"}
      showBorders={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      >
    <HeaderFilter visible={true}/>
      <Column dataField="clientName" caption="Client"
        cellRender={(data) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;
          return (
            <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }}
       />
      <Column
        dataField="Digital_Services"
        caption="Digital Services"
        alignment="right"
      />

      <Column
        dataField="Operations"
        caption="Operations"
        alignment="right"
      />
      <Column
        dataField="Infrastructure"
        caption="Infrastructure"
        alignment="right"
      />
      <Column
        dataField="Total"
        caption="Total"
        alignment="right"
      />

      <Paging defaultPageSize={5} />
    </DataGrid>
  
  );
};

export default ComputeUtilization;
