import { useEffect, useState } from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import Bullet, { Size, Tooltip } from "devextreme-react/bullet";
import { ContactStatus } from "../../../../../../components/ui-components/contact-status/ContactStatus";

const parseAmount = (amountString: string) => {
  const amountNumber = parseFloat(amountString?.replace(/[^0-9.-]+/g, ""));
  return isNaN(amountNumber) ? 0 : amountNumber;
};

const renderBulletCell = (
  cellInfo: any,
  maxAmount: number,
  dataField: string
) => {
  const amountString = cellInfo.data[dataField];
  const amount = parseAmount(amountString);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px" }}>{amountString}</div>
      <Bullet
        showTarget={false}
        showZeroLevel={true}
        value={amount}
        startScaleValue={0}
        endScaleValue={maxAmount}
      >
        <Tooltip enabled={false} />
        <Size width={200} height={20} />
      </Bullet>
    </div>
  );
};

export function CustomTitle(data: any) {
  return (
    <>
      <div className="header">{data?.title}</div>
    </>
  );
}

export function CustomItem(data: any) {
  const [maxAmount, setMaxAmount] = useState(0);

  useEffect(() => {
    const technicalAmounts =
      data.digitalServices?.map((item: any) =>
        parseAmount(item.cost_per_day)
      ) || [];
    const operationalAmounts =
      data.operations?.map((item: any) =>
        parseAmount(item.cost_per_day)
      ) || [];
    const infraAmounts =
      data.infrastructure?.map((item: any) => parseAmount(item.cost_per_day)) ||
      [];
    const allAmounts = [
      ...technicalAmounts,
      ...operationalAmounts,
      ...infraAmounts,
    ];

    if (allAmounts.length > 0) {
      setMaxAmount(Math.max(...allAmounts));
    }
  }, [data]);

  return (
    <div>
      {data.digitalServices && (
        <DataGrid
          dataSource={data.digitalServices}
          columnAutoWidth={true}
          showBorders={true}
          noDataText={"No DigitalServices  to display at this time"}
        >
          <Column dataField="designation" caption="Role" />
          <Column
            dataField="cost_per_day"
            caption="Value"
            dataType="string"
            cellRender={(cellInfo) =>
              renderBulletCell(cellInfo, maxAmount, "cost_per_day")
            }
          />
          <Column
            dataField="status"
            caption="Status"
            cellRender={ContactStatus}
          />
          <Column dataField="startDate" caption="Start Date" />
        </DataGrid>
      )}
      {data.operations && (
        <DataGrid
          dataSource={data.operations}
          columnAutoWidth={true}
          noDataText={"No Operations  to display at this time"}
          showBorders={true}
        >
          <Column dataField="designation" caption="Role" />
          <Column
            dataField="cost_per_day"
            caption="Value"
            cellRender={(cellInfo) =>
              renderBulletCell(cellInfo, maxAmount, "cost_per_day")
            }
          />
          <Column
            dataField="status"
            caption="Status"
            cellRender={ContactStatus}
          />
          <Column dataField="startDate" caption="Start Date" />
        </DataGrid>
      )}
      {data.infrastructure && (
        <DataGrid
          dataSource={data.infrastructure}
          columnAutoWidth={true}
          noDataText={"No Infrastructure to display at this time"}
          showBorders={true}
        >
          <Column dataField="resource" caption="Role" />
          <Column
            dataField="cost_per_day"
            caption="Value"
            cellRender={(cellInfo) =>
              renderBulletCell(cellInfo, maxAmount, "cost_per_day")
            }
          />
          <Column
            dataField="status"
            caption="Status"
            cellRender={ContactStatus}
          />
          <Column dataField="startDate" caption="Start Date" />
        </DataGrid>
      )}
    </div>
  );
}
