import AIMl from "../assets/media/background-20.jpg";
import DocumentProcessing from "../assets/media/background-18.jpg";
import DataEngineering from "../assets/media/background-17.jpg";
import Dataprocess from "../assets/media/background-11.jpg";
import DocumentInteligence from "../assets/media/background-16.jpg";
import RealTimeAnalytics from "../assets/media/background-2.jpg";
import Webscraping from "../assets/media/background-6.jpg";
import DataEntry from "../assets/media/background-8.jpg";
import InteligenceSearch from "../assets/media/background-19.jpg";
import LllmIntegration from "../assets/media/background-21.jpg";
import ApplicationModernization from "../assets/media/background-14.jpg";

export const ProjectMap = [
  {
    id: 1,
    image: DocumentProcessing,
    name: "Document Processing",
    description: [
      "Effortlessly extract valuable information from your PDF documents.",
      "Transform unstructured data into organized formats for easy analysis.",
      "Identify patterns, keywords, and trends within your documents.",
    ],
  },
  {
    id: 2,
    name: "Web Scraping",
    image: Webscraping,
    description: [
      "Gather specific data from multiple websites with ease.",
      "Build customized datasets for research, analysis, or business intelligence.",
      "Save time by automating the process of extracting online information.",
    ],
  },
  {
    id: 3,
    name: "Data Processing & Data Science",
    image: Dataprocess,
    description: [
      "Explore your data through advanced analysis techniques.",
      "Uncover hidden patterns, correlations, and insights.Clean, transform, and prepare your data for modeling and machine learning.",
      "Make data-driven decisions that optimize your business strategies.",
    ],
  },
  {
    id: 4,
    name: "Data Engineering",
    image: DataEngineering,
    description: [
      "Design and implement efficient data pipelines for reliable processing. ",
      "Integrate data from various sources, including databases and APIs. ",
      "Automate data ingestion, transformation, and storage tasks.",
      "Ensure data quality, consistency, and accessibility."
    ],
  },
  {
    id: 5,
    name: "Data Entry & Reconciliation",
    image: DataEntry,
    description: [
      "Enter and validate data from various sources, such as forms or spreadsheets.",
      "Ensure accuracy and completeness of client information.",
      "Reconcile discrepancies and resolve data inconsistencies.",
    ],
  },
  {
    id: 6,
    name: "Realtime Analytics",
    image: RealTimeAnalytics,
    description: [
      "Monitor key metrics, track performance, and identify trends.",
      "Gain a comprehensive overview of your business operations.",
      "Make informed decisions based on real-time data analysis.",
    ],
  },
  {
    id: 7,
    name: "AI & ML",
    image: AIMl,
    description: [
      "Build and train custom machine learning models for your specific needs.",
      "Leverage the power of artificial intelligence to automate tasks and gain insights.",
      "Optimize your business processes with data-driven decision-making.",
    ],
  },
  {
    id: 8,
    name: "Document Intelligence",
    image: DocumentInteligence,
    description: [
      "Extract valuable information and insights from your text documents.",
      "Summarize key points, identify entities, and understand sentiment.",
      "Automate the analysis of contracts, reports, and other unstructured text.",
    ],
  },
  {
    id: 9,
    name: "Intelligent Search",
    image: InteligenceSearch,
    description: [
      "Find relevant information quickly and easily with vector database-based search.",
      "Ask questions in natural language and receive accurate answers.",
      "Retrieve relevant documents, snippets, and summaries based on semantic meaning.",
    ],
  },
  {
    id: 10,
    name: "LLM Integration",
    image: LllmIntegration,
    description: [
      "Create custom chatbots that understand and respond to natural language.",
      "Provide personalized support, answer questions and complete tasks.",
      "Leverage large language models (LLMs) to automate customer interactions.",
    ],
  },
  {
    id: 11,
    name: "Data Lake",
    image: DocumentProcessing,
    description: [
      "Store and manage your data in a scalable and intelligent data lake.",
      "Integrate data from various sources, including databases and APIs.",
      "Utilize built-in intelligence to catalog, classify, and search your data.",
    ],
  },
  {
    id: 12,
    name: "Application Modernization",
    image: ApplicationModernization,
    description: [
      "Transform your legacy applications into modern, cloud-native solutions.",
      "Leverage serverless architecture for scalability, reliability, and cost efficiency.",
      "Unlock the benefits of the cloud, such as agility and rapid deployment.",
    ],
  },
];

export const Sharedworkspaces = [
  {
    id: 1,
    image: Webscraping,
    name: "Rental Beast",
    description: [
      "Effortlessly extract valuable information from your PDF documents.",
      "Transform unstructured data into organized formats for easy analysis.",
      "Identify patterns, keywords, and trends within your documents.",
    ],
    url: "",
  },
  {
    id: 2,
    name: "Web Scraping",
    image: Webscraping,
    description: [
      "Gather specific data from multiple websites with ease.",
      "Build customized datasets for research, analysis, or business intelligence.",
      "Save time by automating the process of extracting online information.",
    ],
    url: "https://www.google.com",
  },
  {
    id: 3,
    name: "Data Processing & Data Science",
    image: Webscraping,
    description: [
      "Explore your data through advanced analysis techniques.",
      "Uncover hidden patterns, correlations, and insights.Clean, transform, and prepare your data for modeling and machine learning.",
      "Make data-driven decisions that optimize your business strategies.",
    ],
  },
  {
    id: 4,
    name: "Data Engineering",
    image: Webscraping,
    description: [
      "Design and implement efficient data pipelines for reliable processing. ",
      "Integrate data from various sources, including databases and APIs. ",
      "Automate data ingestion, transformation, and storage tasks.",
      "Ensure data quality, consistency, and accessibility.",
    ],
  },
  {
    id: 5,
    name: "Data Entry & Reconciliation",
    image: Webscraping,
    description: [
      "Enter and validate data from various sources, such as forms or spreadsheets.",
      "Ensure accuracy and completeness of client information.",
      "Reconcile discrepancies and resolve data inconsistencies.",
    ],
  },
];