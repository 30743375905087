import { useReducer } from "react";
import { useQuery } from "react-query";
import { fetchFilesApi, fetchSingleFilesApi } from "../../service/digital-service/api-service";

const initialState: any = {
  selectedFile: '',
};

type FileItem = {
  filename: string;
  fileExtension: string;
  projectId: string;
  userId: string;
  size: string;
  createdDate: string;
  type: string;
};

type InputItem = {
  [key: string]: Array<{ [key: string]: any } | FileItem>;
};

type OutputItem = {
  name: string;
  isDirectory: boolean;
  items: Array<OutputItem | { name: string; fileExtension: string, projectId: string, userId: string, size: any, createdDate: string, type: string }>;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SELECTED_FILE":
      return {
        ...state,
        selectedFile: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

const transformData = (input: InputItem): OutputItem[] => {
  const transformObject = (obj: InputItem, key: string): OutputItem => {
    const items: any = obj[key];

    return {
      name: key,
      isDirectory: true,
      items: items?.flatMap((item: any) => {
        if ("filename" in item) {
          return [
            {
              name: (item as FileItem).filename,
              fileExtension: (item as FileItem).fileExtension,
              projectId: (item as FileItem).projectId,
              userId: (item as FileItem).userId,
              size: (item as any).fileSize,
              createdDate: (item as FileItem).createdDate,
              type: (item as FileItem).type,
            },
          ];
        } else {
          const subItems = Object.keys(item).map((subKey) =>
            transformObject(item, subKey)
          );
          return subItems;
        }
      }),
    };
  };

  return Object.keys(input).map((key) => transformObject(input, key));
};

export const useFileManager = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const data = useFetchFiles();
  const SingleFileData = useFetchSingleFiles(state.selectedFile);

  const handleSelectedFile = (file: any) => {
    dispatch({ type: "CLEAR" });
    dispatch({ type: "SELECTED_FILE", payload: file });
  };

  return {
    state,
    fileResources: data,
    SingleFileData,
    handleSelectedFile,
  };
};

export const useFetchFiles = () => {
  return useQuery("fetch-data-lakes", fetchFilesApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const res: any = data?.data[0] || {};
      const transformedData = transformData(res);
      return transformedData;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchSingleFiles = (details: any) => {
  const ext = details?.fileExtension;
  const params = { 
    filename: details?.name,
    fileExtension: details?.fileExtension,
    userId: details?.userId,
    projectId: details?.projectId,
    type: details?.type,
  };
  return useQuery(
    ["fetch-single-data-lakes", params],
    () => fetchSingleFilesApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.filename,
      select: (data: any) => {
        const type = ext
        if (type === "json" || type === "csv" || type === "data" || type === "xlsx" || type === "xls" || type === "txt" || type === "map" || type === "xml" || type === "di") {
          const res = JSON?.stringify(data?.data, null, 2) ;
          return res;
        }
        return data?.data || '';
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
