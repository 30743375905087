import { useCallback, useMemo } from "react";
import "./DataEngineering.scss";
import PsImageWithTextCard from "../../../components/ui-components/PsImageWithTextCard/ItemCard";
import ContentExpandCollapse from "../../../components/ui-components/content-expand-collapse/ContentExpandCollapse";
import {
  DataEngineeringGuides,
  DataEngineeringServiceMap,
} from "../../../config/digital-service/data-engineering";
import { useNavigate } from "react-router-dom";

export const DataEngineeringHomePage = () => {
  const navigate = useNavigate();

  const onCardClickData = useCallback((e:any)  => {
    const { navigation } = e || {};
    navigate(navigation)
  },[]);

  const serviceProps = useMemo(() => {
    return {
      cardItems: DataEngineeringServiceMap,
      onCardClick: onCardClickData,
    };
  }, []);

  const referenceProps = useMemo(() => {
    return {
      cardItems: DataEngineeringGuides,
      onCardClick: () => {},
    };
  }, []);

  const cardDetails = useMemo(
    () => [
      {
        title: "Services",
        Component: PsImageWithTextCard,
        componentProps: serviceProps,
        componentId: "document-processing-service",
      },
      {
        title: "Quick Start Guides & Reference Library",
        Component: PsImageWithTextCard,
        componentProps: referenceProps,
        componentId: "document-processing-reference",
      },
    ],
    [referenceProps, serviceProps]
  );

  return (
    <>
      <div className="data-engineering-home-page ">
        <div className="jumbotron">
          <div className="jumbotron-content">
            <h1>Data Engineering</h1>
            <h3>
              Automated Data Importing and Analysis - No Programming Required
            </h3>
            <p>
              Simplify your data processing workflow with our automated data
              importing and analysis service. Designed for ease of use, our
              solution requires no programming knowledge. Import your data with
              just a few clicks and let our powerful algorithms handle the rest,
              delivering comprehensive analysis and insights.
            </p>
          </div>
        </div>
      </div>
      <ContentExpandCollapse cardDetails={cardDetails} />
    </>
  );
};

export default DataEngineeringHomePage;
