export const GTAPageEventHandler = (location: any) => {
  gtag("event", "polaris_page_view", {
    page_title: document.title,
    page_location: location.href,
    page_path: location.pathname,
  });
};

export const GTAButtonEventHandler = (labelName: string, value?: any) => {
  // eslint-disable-next-line no-restricted-globals
  gtag("event", `polaris-${labelName}-location:${location.href}`, {
    event_category: "User Interaction",
    event_label: `Polaris-${labelName}`,
    value: {
      // eslint-disable-next-line no-restricted-globals
      page_location: location.href,
      ...value,
    },
  });
};
