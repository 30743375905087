import DataGrid, {
  Column,
  Paging,
  MasterDetail,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";
import { useState, useCallback } from "react";
import "../../../../styles/client/resourcesTab.scss";
import uploadIcon from "../../../../assets/images/icons/user-plus.png";
import { AddResource } from "./Add-Resource/AddResource";
import DetailInfoRender from "./helper-component/ResourceDetailsGrid";
import { Resource, RestructuredData, ResourceData } from "../../../../pages/client/project-profile/types";
import { ScrollView } from "devextreme-react";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import "../../../../styles/client/resourcesTab.scss";

const ResourcesTab = (props: any) => {
  const { ResourceData, refetch } = props;
  const projectId = useHelperHooks().getQueryParams("projectId");
  const [popupVisible, setPopupVisible] = useState(false);

  const mapResources = (
    resources: Resource[],
    teamName: string
  ): RestructuredData => ({
    Team: teamName,
    Data: resources.map((resource) => ({
      Name: resource?.designation || "",
      Amount: resource?.cost_per_day,
      status: resource?.status,
      quantity: resource?.quantity,
      StartDate: resource?.start_date,
    })),
  });

  const restructureResources = (
    resourceData: ResourceData | null
  ): RestructuredData[] => {
    if (!resourceData) {
      return [];
    }

    const { digitalServices = [], operations = [] } = resourceData;
    return [
      mapResources(digitalServices, "Digital Services"),
     
      mapResources(operations, "Operations"),
    ];
    
  };
  

  const restructuredResources = restructureResources(ResourceData?.[0]);

  const togglePopupVisibility = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  return (
    <ScrollView>
      <DataGrid
        className="grid resourceTab"
        noDataText={"No resources to display at this time"}
        dataSource={restructuredResources}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showColumnHeaders={false}
        showBorders={true}
        width="100%"
        allowColumnResizing={true}
      >
        <Toolbar>
          <Item location="after" locateInMenu="auto" cssClass="resource-add">
              <PSIconText
                alt="addFiles"
                text="Add Resource"
                src={uploadIcon}
                onClick={() => togglePopupVisibility()}  
              />
          </Item>
        </Toolbar>
        <Column dataField="Team" caption=" " />
        <Paging defaultPageSize={25} />
        <MasterDetail enabled={true} render={DetailInfoRender}></MasterDetail>
      </DataGrid>
      {popupVisible && (
        <AddResource
          popupVisible={popupVisible}
          changePopupVisibility={togglePopupVisibility}
          projectId={projectId}
          refetch={refetch}
          ResourceData={ResourceData}
        />
      )}
    </ScrollView>
  );
};

export default ResourcesTab;
