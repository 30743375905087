export const msalConfig = {
  auth: {
    clientId: "aec0405c-a8e4-407e-a3c4-0a82f4b0bd57",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://polaris-dev.azurewebsites.net/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {},
};

export const loginRequest = {
  scopes: [
    "https://SpiritDigix1.onmicrosoft.com/aec0405c-a8e4-407e-a3c4-0a82f4b0bd57/PublicInfo.Read",
  ],
};


export const ID_TOKEN = "id_token";