import { useCallback, useState } from "react";
import TemplateNewForm from "./template-form/TemplateNewForm";
import { Template } from "../types";
import { newTemplate } from "../../../../../config/constants";
import { useCreateTemplate } from "../../../../../hooks/digital-service/useTemplate";
import { FormPopup } from "../../../../../components/ui-components/form/form-popup/FormPopup";

const NewTemplate = (props: any) => {
  const { popupVisible, changePopupVisibility, getTemplateTypeList } = props;

  const [newTemplateData, setNewTemplateData] = useState<Template>({
    ...newTemplate,
  });

  const updateField = (field: string) => (value: any) => {
    setNewTemplateData({ ...newTemplateData, ...{ [field]: value } });
  };

  const { mutate, isLoading } = useCreateTemplate(
    changePopupVisibility
  );

  const handleFormSubmit = useCallback(() => {
    const formData = new FormData();
    formData.append("template_name", newTemplateData.template_name);
    formData.append("template_function", newTemplateData.template_function);
    formData.append("status", newTemplateData.status);
    formData.append("notes", newTemplateData.notes);
    formData.append("file", newTemplateData.file);
    mutate(formData);
  }, [mutate, newTemplateData]);

  const onTemplateTypeChanged = (e: string) => {
    setNewTemplateData({ ...newTemplateData, ...{ template_function: e } });
  };

  const onStatusChanged = (e: string) => {
    setNewTemplateData({ ...newTemplateData, ...{ status: e } });
  };

  return (
    <FormPopup
      title="New Template"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
      fullScreen={true}
    >
      <TemplateNewForm
        newTemplateData={newTemplateData}
        updateField={updateField}
        onTemplateTypeChanged={onTemplateTypeChanged}
        onStatusChanged={onStatusChanged}
        getTemplateTypeList={getTemplateTypeList}
      />
    </FormPopup>
  );
};

export default NewTemplate;
