import {
  fetchCatalogApi,
  fetchReportInfoApi,
  fetchActiveDocumentApi,
  fetchDocumentSummaryApi,
  fetchDataProcessDropDownApi,
  fileUploadProjectApi,
  fetchHierarchyInfoApi
} from "../../service/datastudio/api-service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatDate } from "devextreme/localization";
import { DataProcessingState } from "../../pages/datastudio/dataAnalyze/types";
import { useReducer } from "react";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

export const initialState: any = {
  clientId: "",
  projectId: "",
  telemetryData: {},
  metricsData: {},
  pdfFinalData: {},
};

export const reducer = (state: DataProcessingState, action: any) => {
  switch (action?.type) {
    case "CLIENTID":
      return {
        ...state,
        clientId: action.payload, // used 
      };
    case "PROJECTID":
      return {
        ...state,
        projectId: action.payload, // used 
      };
    case "SETTELEMETRYDATA":
      return {
        ...state,
        telemetryData: action.payload,
      }
    case "SETMETRICSDATA":
      return {
        ...state,
        metricsData: action.payload,
      }
    case "SETPDFDATA":
      return {
        ...state,
        pdfFinalData: action.payload,
      }
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useDocumentCatalog = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: fetchDropDown }: { data: any; isLoading: boolean } =
    useFetchDataProcessDropDownDataApi(state.clientId); // used 

  const getClientId = (e: any) => {
    const { value } = e || {};
    const { clientId } = value || {};
    dispatch({ type: "CLIENTID", payload: clientId });
  }; // used 

  const getProjectId = (e: any) => {
    const { value } = e || {};
    const { projectId } = value || {};
    dispatch({ type: "PROJECTID", payload: projectId });
  }; // used 

  const setTelemetryData = (value: any) => {
    dispatch({ type: "SETTELEMETRYDATA", payload: value })
  }

  const setMetricsData = (value: any) => {
    dispatch({ type: "SETMETRICSDATA", payload: value })
  }
  
  const setPdfFinalData = (value: any) => {
    dispatch({ type: "SETPDFDATA", payload: value })
  }

  return {
    state,
    fetchDropDown,
    getClientId,
    getProjectId,
    setTelemetryData,
    setMetricsData,
    setPdfFinalData,
  };
};

export const useFetchDataProcessDropDownDataApi = (id: string) => {
  return useQuery(
    ["fetch-dataprocess-drop-down", id],
    () => fetchDataProcessDropDownApi(id),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

const fetchDocumentCatalog = async (data: any) => {
  const { startTime, endTime } = data || {};
  const queryParams = {
    startTime: formatDate(startTime, "yyyy-MM-dd"),
    endTime: formatDate(endTime, "yyyy-MM-dd"),
  };
  const response: any = await fetchCatalogApi(queryParams);
  if (response) {
    return response;
  }
}; // used 

export const useCatalogInfo = (data: any) => {
  return useQuery(
    ["fetch-document-catalogss", data],
    () => fetchDocumentCatalog(data),
    {
      refetchOnWindowFocus: false,
      enabled: !!data,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

const isDateFormatted = (date: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(date);
};


const fetchDocumentReports = async (data: any) => {
  const { startTime, endTime, clientId, ProjectId, status, taskId,processType } = data || {};
  
  const queryParams = {
    startTime: isDateFormatted(startTime) ? startTime : formatDate(startTime, "yyyy-MM-dd"),
    endTime: isDateFormatted(endTime) ? endTime : formatDate(endTime, "yyyy-MM-dd"),
    clientId,
    ProjectId,
    status,
    taskId, 
    processType,
  };

  const response: any = await fetchReportInfoApi(queryParams);
  if (response) {
    return response;
  }
};

export const useReportsInfo = (data: any) => {
  return useQuery(
    ["fetch-document-catalogs", data],
    () => fetchDocumentReports(data),
    {
      refetchOnWindowFocus: false,
      enabled: !!data,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};


export const useFetchFileUploadProject = (onClose: any) => {
  const fnQueryClient = useQueryClient();
  return useMutation(fileUploadProjectApi, {
    onSuccess: () => {
      onClose();
      fnQueryClient.invalidateQueries("fetch-fileupload-project");
      fnQueryClient.invalidateQueries("fetch-document-catalogss");
      showNotification({
        message: "File uploaded successfully",
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

const fetchDocumentSummary = async (data: any) => {
  const { startTime, endTime } = data || {};
  const queryParams = {
    startTime: formatDate(startTime, "yyyy-MM-dd"),
    endTime: formatDate(endTime, "yyyy-MM-dd"),
  };
  const response: any = await fetchDocumentSummaryApi(queryParams);
  if (response) {
    return response;
  }
}; // used 

export const useFetchDocumentSummary = (data: any) => {
  return useQuery(
    ["fetch-document-summary", data],
    () => fetchDocumentSummary(data),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

const fetchActiveDocument = async (data: any) => {
  const { startTime, endTime } = data || {};
  const queryParams = {
    startTime: formatDate(startTime, "yyyy-MM-dd"),
    endTime: formatDate(endTime, "yyyy-MM-dd"),
  };
  const response: any = await fetchActiveDocumentApi(queryParams);
  if (response) {
    return response;
  }
}; // used 

export const useFetchActiveDocument = (data: any) => {
  return useQuery(
    ["fetch-active-document", data],
    () => fetchActiveDocument(data),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

export const useFetchHierarchyInfo = (processType: string, status: string, dataCatalogId: any) => {
  return useQuery(
    ["fetch-hierarchy-info", processType, status, dataCatalogId],
    () => fetchHierarchyInfoApi(processType, status, dataCatalogId),
    {
      enabled: !!processType && !!status && !!dataCatalogId,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};