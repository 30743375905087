import PortfolioRowCard from "./helper-component/tileview"
import SideTileView from './helper-component/side-tile';
import ClientInfo   from '../clientInformation/clientInfoV2';
import '../../../../../styles/client/portfolio.scss'

const PortfolioDashboard = (props: any) => {
  const { gridData, showToolbar, gridRef, selectedClient, selectedDays } = props

  return (
    <div className="opexDashboard">
      <div className="opex-leftPane">
        <PortfolioRowCard selectedValue={selectedDays} />
        <ClientInfo gridData={gridData} gridRef={gridRef} selectedClient={selectedClient} showToolbar={showToolbar} height="height-large" />
      </div>
      <div className="opex-rightPane">
        <SideTileView selectedDays={selectedDays} />
      </div>
    </div>
  );
}

export default PortfolioDashboard;






