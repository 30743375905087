import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  DataGridTypes,
} from "devextreme-react/data-grid";
import PsAccordion from "../../../../components/ui-components/accordion/accordion";
import { CustomItem, CustomTitle } from "./invoiceComponent";
import ScrollView from "devextreme-react/scroll-view";
import "../../../../styles/client/invoiceInformation.scss";
import {
  CellDollar,
  cellStartTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";

const detailRender = (detail: DataGridTypes.MasterDetailTemplateData) => {
  const clientData = detail?.data?.billDate || [];
  return (
    <div>
      <DataGrid
        dataSource={clientData}
        repaintChangesOnly={true}
        columnAutoWidth={true}
        noDataText={"No Invoice  to display at this time"}
        showBorders={true}
      >
        
        <Column dataField="invoiceId" caption="Invoice Id" dataType="string" />
        <Column
          dataField="bill_date"
          caption="Billing Period"
          dataType="string"
        />
        <Column dataField="date" caption="Invoice Date" dataType="string" />
        <Column
          dataField="totalcost"
          caption="Total Cost"
          dataType="string"
          cellRender={CellDollar}
        />
        <MasterDetail enabled={true} render={clientRender}></MasterDetail>
      </DataGrid>
    </div>
  );
};

const clientRender = (detail: DataGridTypes.MasterDetailTemplateData) => {
  const clientData = detail?.data?.projects || [];
  return (
    <div>
      <DataGrid
        dataSource={clientData}
        repaintChangesOnly={true}
        columnAutoWidth={true}
        showBorders={true}
        noDataText={"No Invoice  to display at this time"}
        allowColumnReordering={true}
      >
        <Column dataField="projectName" dataType="string" />
        <Column dataField="description" dataType="string" />
        <Column dataField="status" dataType="string" />
        <Column
          dataField="startDate"
          dataType="string"
          cellRender={cellStartTime}
        />
        <MasterDetail enabled={true} render={detailRenderTask}></MasterDetail>
      </DataGrid>
    </div>
  );
};

const detailRenderTask = (detail: DataGridTypes.MasterDetailTemplateData) => {
  return (
    <>
      <div>
        <PsAccordion
          dataSource={detail?.data?.invoice}
          Component={CustomItem}
          CustomTitle={CustomTitle}
        />
      </div>
    </>
  );
};

const InvoiceInformations = (props: any) => {
  const { clientInfo, isLoading } = props;
  const clientInfoData = clientInfo;
  return (
    <ScrollView>
      <div>
        <DataGrid
          className="grid theme-dependent"
          dataSource={clientInfoData}
          noDataText={"No Invoice  to display at this time"}
          repaintChangesOnly={true}
          height="100%"
          allowColumnReordering={true}
          columnAutoWidth={true}
          showBorders={true}
        >
          <Paging defaultPageSize={25} />
          <Column dataField="clientName" dataType="string" />
          <MasterDetail enabled={true} render={detailRender}></MasterDetail>
        </DataGrid>
      </div>
    </ScrollView>
  );
};

export default InvoiceInformations;
