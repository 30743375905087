import { DataGrid } from "devextreme-react";
import {
  Column,
  HeaderFilter,
  Button as ActionsButton,
  Paging,
  Pager,
  Editing,
  SearchPanel
} from "devextreme-react/cjs/data-grid";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import { allowedPageSizes } from "../../../../config/datastudio/commonConfig";

const CatalogGrid = (props: any) => {
  const {
    catalogData,
    isCatalogDataLoading,
    renderViewFile,
    openPopup,
    filesData,
    closePopupException,
    fileViewerData,
    isDataLoading,
    fileExtensions,
    renderFileName,
  } = props;

  const title = {
    file: filesData?.file_name,
    status: filesData?.status,
  };

  return (
    <div className="recent-activity-grid">
      <DataGrid
        className="grid theme-dependent height-large"
        dataSource={catalogData}
        noDataText={"No catalog to display at this time"}
        allowColumnReordering
        showBorders
        allowColumnResizing={true}
      >
        <SearchPanel visible={true} width={200}/>
        <HeaderFilter visible />
        <Column caption="File Name" dataField="fileName" dataType="string" cellRender={renderFileName}/>
        <Column caption="Uploaded By" dataField="userName" dataType="string"/>
        <Column
          caption="Asset Type"
          dataField="processType"
          dataType="string"
        />
        <Column
          caption="Status"
          dataField="status"
          dataType="string"
          cellRender={ContactStatus}
        />
        <Column
          caption="Uploaded On"
          dataField="created_at"
          dataType="datetime"
          cellRender={cellStartTime}
        />
        <Column
          caption="Last Updated"
          dataField="updated_at"
          dataType="datetime"
          cellRender={cellUpdatedTime}
        />
        <Column
          caption=""
          type="buttons"
          width={50}
          cellRender={renderViewFile}
        />
        <Column
          caption=""
          type="buttons"
          width={75}
        >
        <ActionsButton name="delete" cssClass="action-image" disabled={true} />
        </Column>
        <Editing mode="row" useIcons={true} allowDeleting={true} key={"id"} />          
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={isDataLoading}
        extensionType={fileExtensions}
      />
    </div>
  );
};

export default CatalogGrid;
