import { FormPopup } from "../../../ui-components/form/form-popup/FormPopup";
import Form, { Item as FormItem } from "devextreme-react/form";
import FileUploader from "devextreme-react/file-uploader";
import { getSizeQualifier } from "../../../../utlis/media-query";
import ProgressBar from "devextreme-react/progress-bar";
import { useReducer, useState } from "react";
import showNotification from "../../../ui-components/alertPopup/AlertPopup";
import { useFetchFileUploadProject } from "../../../../hooks/client/useProjectProfile";
import { useQueryClient } from "react-query";
import { useApp } from "../../../../contexts/app";
import { formatFileSize } from "../../../../utlis/helper";

const initialState = {
  totalProgress: 0,
  projectFiles: [],
  fileProgress: {},
  resetUploaderKey: 0,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "RESET_UPLOADER_KEY":
      return { ...state, resetUploaderKey: state.resetUploaderKey + 1 };
    case "SET_PROJECT_FILES":
      return { ...state, projectFiles: action.payload };
    case "SET_TOTAL_PROGRESS":
      return { ...state, totalProgress: action.payload };
    default:
      return state;
  }
};

const ProjectDataFileUploader = (props: any) => {
  const { popupAddFilesVisible, handleFormPopupClose, projectId } = props;
  const getProgressBar = (value: number) => <ProgressBar value={value} />;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { mutate, isLoading } = useFetchFileUploadProject();
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const fnQueryClient = useQueryClient(); 
  const { user } = useApp();
  const userName = user?.name || "";
  const handleSave = () => {
    const payload = {
      projectId: projectId,
      projectFiles: state?.projectFiles,
      uploadedBy: userName,
    };

    if (state?.projectFiles?.length === 0) {
      showNotification({
        message: `Please select files to upload`,
      });
      return;
    }

    const uploadPromises = state?.projectFiles?.map((file: any) => {
      return new Promise<void>((resolve) => {
        let progress = 0;
        const interval = setInterval(() => {
          progress += 10;
          setFileProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: Math.min(progress, 100),
          }));
          if (progress >= 100) {
            clearInterval(interval);
            resolve();
          }
        }, 500);
      });
    });

    Promise.all(uploadPromises).then(() => {
      mutate(payload, {
        onSuccess: () => {
          dispatch({ type: "SET_PROJECT_FILES", payload: [] });
          dispatch({ type: "RESET_UPLOADER_KEY" });
          dispatch({ type: "SET_TOTAL_PROGRESS", payload: state.totalProgress=0 });
          setFileProgress({});
          handleFormPopupClose();
          showNotification({
            message: `Files added Successfully`,
            type: "success",
          });
          fnQueryClient.invalidateQueries("fetch-project-data-list");
        },
      });
    });
  };

  const handleFilesUpload = (e: any) => {
    const files = e.value;
    const filePromises = files.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const fileSize = formatFileSize(file?.size);
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve({ name: file.name, content: reader.result, size: fileSize });
        reader.onerror = (error) => reject(error);
      });
    });
    Promise.all(filePromises).then((encodedFiles) => {
      dispatch({ type: "SET_PROJECT_FILES", payload: encodedFiles });
      const initialFileProgress: { [key: string]: number } = {};
      encodedFiles.forEach((file) => {
        initialFileProgress[file.name] = 0;
      });
      setFileProgress(initialFileProgress);
    });
  };

  return (
    <>
      <FormPopup
        title="Add Files"
        visible={popupAddFilesVisible}
        setVisible={handleFormPopupClose}
        onSave={handleSave}
        isLoading={isLoading}
      >
        <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
          <FormItem>
            <div className="file-uploader-container">
              <FileUploader
                key={state?.resetUploaderKey}
                multiple={true}
                accept={".json,.pdf,.csv,.xml,.txt"}
                uploadMode="useForm"
                onValueChanged={handleFilesUpload}
              />
              <span className="note">
               {"Supported file formats: "}
              <span>.csv, .pdf, .json, .xml, .txt</span>
            </span>
              {Object?.keys(fileProgress)?.map((fileName) => (
                <div key={fileName} className="progressDiv">
                  {fileName} {getProgressBar(fileProgress[fileName])}
                </div>
              ))}
            </div>
          </FormItem>
        </Form>
      </FormPopup>
    </>
  );
};

export default ProjectDataFileUploader;
