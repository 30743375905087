import { useQuery } from 'react-query';
import { fetchDataCatalogGridApi }  from '../../service/datastudio/api-service';

export const useFetchDataCatalogGridApi = (userId: string) => {
    return useQuery(["fetch-datacatalog-data", userId], () => fetchDataCatalogGridApi(userId), {
      refetchOnWindowFocus: false,
      enabled: !!userId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.error(error);
      }
    });
  };