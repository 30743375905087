import { useCallback, useMemo, useState } from "react";
import UrlGrid from "./helper-component/urlGrid";
import NewUrl from "./helper-component/newUrl";
import EditUrl from "./helper-component/editUrl";
import DeleteUrl from "./helper-component/deleteUrl";
import { useFetchurl, useUrl } from "../../../hooks/client/useClientUrl";
import DropDownButton from "devextreme-react/drop-down-button";
import { dropDownOptions } from "../../../config/constants";
import { ViewType } from "./types";
import { Item } from "devextreme-react/toolbar";
import { SearchPanel } from "devextreme-react/data-grid";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import UrlKanbanPage from "./helper-component/kanban";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import { useAppHook } from "../../../hooks/app/useApps";
import { useHelperHooks } from "../../../hooks/helper/utils";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import uploadIcon from "../../../assets/icons/icon-upload-1.png";
import UploadURLs from "./helper-component/uploadURLs";

const viewOptions: ViewType[] = [
  { text: "Grid View", value: "gridView" },
  { text: "Workflow View", value: "boardView" },
];

export const UrlCatalog = () => {
  const [showpopup, setPopupVisible] = useState(false);

  const {
    state: {
      popupVisible,
      selectedClientData,
      EditpopupVisible,
      deletePopupVisible,
    },
    onAddContactClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditUrlClick,
    changeEditPopupVisibility,
  } = useUrl();

  const {
    state: { client, urlStatus },
    filterByClient,
    clientDropDownData,
    filterByUrlStatus,
    gridRef
  } = useAppHook();

  const [viewType, setViewType] = useState<"gridView" | "boardView">(
    "gridView"
  );

  const { data, refetch, isFetching } = useFetchurl();
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const DataGridProps = useMemo(() => {
    return {
      data,
      onEditUrlClick,
      onAddContactClick,
      onDeletePopupOpen,
      gridRef,
      client,
      urlStatus
    };
  }, [data, gridRef, client, urlStatus, onAddContactClick, onDeletePopupOpen, onEditUrlClick]);

  const handleAddFilesClick = useCallback(() => {
    setPopupVisible(!showpopup);
  }, [showpopup]);

  const uploadURLsProps = useMemo(() => {
    return {
      showpopup,
      onClose: handleAddFilesClick,
    };
  }
  , [handleAddFilesClick, showpopup]);

  const uniqueStatus = [
    "All Items",
    ...Array?.from(new Set(data?.map((obj: { status: any }) => obj?.status))),
  ];

  const handleViewChange = (e: any) => {
    const { item } = e;
    if (item) {
      setViewType(item.value);
    }
  };

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const UrlEditProps = useMemo(() => {
    return {
      selectedClientData,
      EditpopupVisible,
      changeEditPopupVisibility,
    };
  }, [selectedClientData, EditpopupVisible, changeEditPopupVisibility]);

  const urlDeleteProps = useMemo(() => {
    return {
      selectedClientData,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [deletePopupVisible, onDeletePopupClose, selectedClientData]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: UrlGrid,
      componentProps: DataGridProps,
    },
    {
      mode: "board",
      Component: UrlKanbanPage,
      componentProps: DataGridProps,
    },
  ];

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass='toolbar-item-flex'>
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <DropDownButton
        items={uniqueStatus}
        stylingMode="text"
        text={urlStatus}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByUrlStatus}
      />
      <DropDownButton
        text={
          viewOptions.find((item) => item.value === viewType)?.text || "View"
        }
        stylingMode="text"
        displayExpr="text"
        items={viewOptions}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={handleViewChange}
      />
      <PSIconText
        text="Upload URLs"
        src={uploadIcon}
        alt="addFiles"
        onClick={handleAddFilesClick}
      />
      <PSIconText
        text="Add URL"
        src={NewIcon}
        alt="Add URL"
        onClick={onAddContactClick}
      />
      <SearchPanel placeholder="Client Search" />
    </Item>
  );

  const layoutProps = {
    headerText: "URL Catalog",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: viewType,
    isLoading: isFetching,
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <NewUrl {...FormProps} />
      <EditUrl {...UrlEditProps} />
      <DeleteUrl {...urlDeleteProps} />
      <UploadURLs {...uploadURLsProps} />
    </div>
  );
};
