import { useQuery } from "react-query";
import { fetchClientAlertExceptionApi, fetchSpecificProjectEditDetailsApi } from "../../service/activity-monitor/api-service";
import { formatDate } from "devextreme/localization";

export const useFetchSpecificProjectDetails = (projectId: string) => {
  return useQuery(
    ["fetch-project-byId", projectId],
    () => fetchSpecificProjectEditDetailsApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

const fetchAlertException = async ({ pageParam }: any, data: any) => {
  const response: any = await fetchClientAlertExceptionApi(data);
  if (response) {
    return response;
  }
};

export const useFetchAlerException = (fetchProps: any) => {
  return useQuery(
    ["fetch-alert-Exception", fetchProps],
    ({ pageParam = 1 }) => fetchAlertException({ pageParam }, fetchProps),
    {
      enabled: !!fetchProps,
      refetchOnWindowFocus: false,
      getNextPageParam: (_lastPage: any, pages: any) => {
        if (pages.length < 100) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      select: (data: any) => {
        const updatedData = data?.data?.map((item: any) => {
          return {
            ...item,
            name: item?.clientName,
          };
        });
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
