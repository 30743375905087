import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
import {
  editCellStatusRender,
  cellNameCombine,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { onExporting } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { allowedPageSizes } from "../../../../config/client/commonConfig";

const ClientGrid = (props: any) => {
  const {
    onRowClick,
    onEditClick,
    data,
    onDeletePopupOpen,
    gridRef
  } = props;
  const navigate = useNavigate();

  const handleClientLinkClick = (event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
  };

  return (
    <>
    <div className="recent-activity-grid">
      <DataGrid
        className="grid theme-dependent height-large"
        noDataText={"No clients to display at this time"}
        focusedRowEnabled
        dataSource={data}
        keyExpr={"clientId"}
        onRowClick={onRowClick}
        onExporting={onExporting}
        allowColumnReordering
        showBorders
        ref={gridRef}
        allowColumnResizing={true}
      >
        <SearchPanel visible={true} width={200}/>
        <HeaderFilter visible />
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" />
        <Column
          dataField="name"
          caption="Client"
          cellRender={(data) => {
            const clientName = data?.data?.name;
            const clientId = data?.data?.clientId;
            return (
              <a
                href="null"
                onClick={(e) => handleClientLinkClick(e, clientId)}
              >
                {clientName}
              </a>
            );
          }}
        />
        <Column
          dataField="primary_contact"
          caption="Primary Contact"
          cellRender={cellNameCombine}
          minWidth={100}
        />
        <Column
          dataField="primary_email"
          caption="Primary Email"
          minWidth={100}
        />
        <Column dataField="note" caption="Notes" />
        <Column
          dataField="status"
          caption="Status"
          cellRender={ContactStatus}
          editCellRender={editCellStatusRender}
          width={150}
        />
        <Column
          dataField="projectCount"
          caption="Projects Count"
          alignment="left"
          width={150}
        />

        <Column
          dataField="taskCount"
          caption="Tasks Count"
          alignment="left"
          width={150}
          
        />
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating
          allowDeleting={true}
          key={"clientId"}
        />
        <Column caption="" type="buttons" minWidth={150}>
          <ActionsButton
            name="edit"
            onClick={onEditClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton name="delete" cssClass="action-image" onClick={onDeletePopupOpen} />
        </Column>
        <Scrolling rowRenderingMode="virtual"></Scrolling>
          <Paging defaultPageSize={25} />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}/>
      
      </DataGrid>
    </div>  
    </>
  );
};

export default ClientGrid;
