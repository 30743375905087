import DataGrid, {
  Scrolling,
  Column,
  Paging,
  Pager,
  HeaderFilter,
  SearchPanel,
} from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { cellTimeStamp  } from "../../../ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";

const RecentAlertExceptionGridView = (props: any) => {
  const {
    FinalData,
    PagerVisible = false,
    openPopup,
    handleClientLinkClick,
    isExceptionsLoading,
    closePopupException,
    fileViewer,
    handleTaskLinkClick,
    handleExceptionClick,
    gridRef,
    height,
  } = props;

  return (
    <>
        <div className="recent-activity-grid">

    <DataGrid
      className={`grid theme-dependent ${height}`}
      noDataText={"No alerts & exceptions to display at this time"}
      dataSource={FinalData}
      keyExpr="executionId"
      allowColumnReordering
      showBorders
      ref={gridRef}
      allowColumnResizing={true}
    >
      <SearchPanel visible={true} width={200}/>
      <HeaderFilter visible={true} />

      <Column
        dataField="clientName"
        caption="Client"
        cellRender={(data) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;

          return (
            <a href="null" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }}
      />
        
      <Column
        dataField="taskName"
        caption="Task Name"
        cellRender={(data) => {
          const TaskName = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return (
            <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {TaskName}
            </a>
          );
        }}
      />
      <Column
        dataField="executionId"
        caption="Execution ID"
        cellRender={(data) => {
          const executionId = data?.data?.executionId;
          const truncatedId = executionId
            ? executionId.toString().substr(0, 10)
            : "";
          return <>{truncatedId}</>;
        }}
      />
      <Column
        dataField="exception"
        caption="Exceptions"
        cellRender={(data) => {
          const exceptionData = data?.data?.exception;
          return (
            <a
              href="null"
              onClick={(e) => handleExceptionClick(e, exceptionData)}
            >
              List of Exceptions
            </a>
          );
        }}
      />
      <Column
        dataField="timeStamp"
        caption="Time Stamp"
        cellRender={cellTimeStamp}
      />
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      <Paging defaultPageSize={25} />
      <Pager
        visible={PagerVisible}
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewer || []}
        title={''}
        isLoading={isExceptionsLoading}
        extensionType={"json"}
      />
    </div>
    </>
  );
};

export default RecentAlertExceptionGridView;
