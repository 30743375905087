import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createClientApi,
  deleteClientById,
  updateClientApi,
  fetchClientCatalogApi
} from "../../service/client/api-service";
import { useCallback, useReducer, useRef } from "react";
import DataGrid, { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { useNavigate } from "react-router-dom";
import { ClientState } from "../../pages/client/client-catalog/types";

const initialState: ClientState = {
  popupVisible: false,
  EditpopupVisible: false,
  clientId: 0,
  selectedClient: null,
  deletePopupVisible: false,
};

const reducer = (state: ClientState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, // used
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        EditpopupVisible: action.payload, // used
      };
    case "CLIENT_ID":
      return {
        ...state,
        clientId: action.payload,
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload, // used
      };
    case "SELECTED_CLIENT":
      return {
        ...state,
        selectedClient: action.payload, // used
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useClientCatlog = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridRef = useRef<DataGrid>(null);
  const navigate = useNavigate();

  const onAddContactClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []);

  const onRowClick = useCallback(
    ({ data }: DataGridTypes.RowClickEvent) => {
      navigate(`/client-profile?clientId=${data.clientId}`);
    },
    [navigate]
  );

  const onEditClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_CLIENT", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectClient = (data: any) => {
    dispatch({ type: "SELECTED_CLIENT", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectClient(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []);

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []);

  return {
    state,
    gridRef,
    onAddContactClick,
    onDeletePopupOpen,
    onRowClick,
    onEditClick,
    changeEditPopupVisibility,
    changePopupVisibility,
    onDeletePopupClose,
  };
};

export const useCreateClient = (
  toggleClientModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = isEdit ? updateClientApi : createClientApi;
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-client-catalog");
      fnQueryClient.invalidateQueries("fetch-clients");
      fnQueryClient.invalidateQueries("fetch-clients-name");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDeleteClient = (
  onDeleteHandler: () => void,
  clientId: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteClient", clientId],
    () => deleteClientById(clientId),
    {
      enabled: !!clientId && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-clients");
        queryClient.invalidateQueries("fetch-client-catalog");
      },
    }
  );
};

export const useFetchClientCatalog = () => {
  return useQuery("fetch-client-catalog", fetchClientCatalogApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};