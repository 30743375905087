import { del, get, post, update } from "../../service/api/api";
import { makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchUrlsApi = async (_id: string) => {
  const response = await get(`webscraping/v1/urls/urls-info/${_id}`);
  return response;
};

export const fetchTaskDescriptionApi = async () => {
  const response = await get("automation/v1/automation/info/all");
  return response;
};

export const createTaskApi = async (payload: any) => {
  const response = await post("automation/v1/automation/create", payload);
  return response;
};

export const createCloneTaskApi = async (
  type: string,
  taskId: string,
  payload: any
) => {
  const response = await post(
    `automation/v1/automation/clone?type=${type}&taskId=${taskId}`,
    payload
  );
  return response;
};

export const updateTaskApi = async (payload: any) => {
  const response = await update("automation/v1/automation/update", payload);
  return response;
};

export const fetchImageApi = async () => {
  const response = await get("automation/v1/image/info/all");
  return response;
};

export const StartProgressApi = async (_id: string, taskId: string, userId: string, name: string) => {
  const response = await get(
    `automation/v1/process/process_trigger/${_id}/${taskId}/${userId}/${name}`
  );
  return response;
};

export const fetchTaskDetailApi = async (taskId: string | null) => {
  const response = await get(`automation/v1/automation/info/task/${taskId}`);
  return response;
};

export const deleteTaskById = async (id: string) => {
  const response = await del(`automation/v1/automation/delete/${id}`);
  return response;
};

export const fetchInfraDetailsApi = async () => {
  const response = await get(`admin/v1/infrDetails/infra-info`);
  return response;
};

export const fetchSpecificProjectDetailsApi = async (id: any) => {
  const response = await get(`client/v1/project/info/client/${id}`);
  return response;
};

export const fetchResouceDetailsApi = async (type: string) => {
  const response = await get(`finops/v1/ratecard/resource?type=${type}`);
  return response;
};

export const stopContainerInstanceApi = async (payload: any) => {
  const response = await post("admin/v1/infrDetails/infrastop", payload);
  return response;
};

export const fetchProjectTypeDropdown = async () => {
  const response = await get(`drop-down/v1/template_dropdown?func_type=True`);
  return response;
};

export const fetchTaskDataList = async (taskIds: any) => {
  const response = await get(`datalake/v1/file-manager/retrieve/task/${taskIds}?structure=true`);
  return response;
};

export const fetchtTaskData = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `datalake/v1/file-manager/retrieve/viewfile${query}`
  );
  const response = await get(validUrl);
  return response;
}; 