import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Paging,
  Pager,
  MasterDetail,
  SearchPanel
} from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/task/commonConfig";
import ProcessDetails from "./process-Details/ProcessDetails";
import { truncateString } from "../../../../utlis/helper";

export const InfraStructureDataGrid = (props: any) => {
  const { data, handleClientLinkClick, gridRef, handleTaskLinkClick } = props;
  return (
  <div className="recent-activity-grid">
    <DataGrid
      className="grid theme-dependent height-large"
      noDataText={"No infraStructure data to display at this time"}
      dataSource={data}
      allowColumnReordering
      showBorders
      ref={gridRef}
      allowColumnResizing={true}
    >
      <SearchPanel visible={true} width={200}/>
      <MasterDetail enabled={true} component={ProcessDetails} />
      <HeaderFilter visible />
      <Sorting mode="multiple" />
      <Scrolling mode="virtual" />
      <Column
        dataField="customerName"
        caption="Client"
        cellRender={(data) => {
          const clientName = data?.data?.customerName;
          const clientId = data?.data?.clientId;
          return (
            <a href="null" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }}
      />
      <Column
        dataField="taskName"
        caption="Task Name"
        cellRender={(data) => {
          const TaskName = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return (
            <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {TaskName}
            </a>
          );
        }}
      />
      <Column
        dataField="executionId"
        caption="Execution ID"
        cellRender={(data) => {
          const executionId = data?.data?.executionId;
          return <>{truncateString(executionId, 10)}</>;
        }}
      />
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      <Paging defaultPageSize={25} />
      <Pager
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  </div>    
  );
};
