import DataGrid, {
  Column,
  DataGridTypes,
} from "devextreme-react/data-grid";
import {
  cellEndTime,
  cellStartTime,
} from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";

const AciProcessDetails = (props: DataGridTypes.MasterDetailTemplateData) => {
  const { containers } =
    props?.data?.data || {};
    console.log(props,"propspropspropsprops")

  return (
    <>
      <DataGrid dataSource={containers} showBorders={true}>
        <Column dataField="container_name" caption="Container Name" />
        <Column
          dataField="starttime"
          caption="Start Time"
          cellRender={cellStartTime}
        />
        <Column
          dataField="endTime"
          caption="End Time"
          cellRender={cellEndTime}
        />
        <Column dataField="status" caption="Status" />
      </DataGrid>
      
    </>
  );
};

export default AciProcessDetails;
