export const legendItems = [
  { color: "#f7f49e", text: "Duplicate" },
  { color: "#acdfff", text: "Null Rows" },
];

export const exportItems = [
  {
    text: "Download",
  },
  {
    text: "Export to DataLake",
  },
];

export const validStatuses = ["Ready for Review", "Completed", "Rejected"];

export const fileExtensions = [".pdf"];
