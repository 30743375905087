import ProjectNewForm from "../helper-component/project-form/ProjectNewForm";

const NewProject = (props: any) => {
  const { 
    newProjectData,
    updateField,
    onOptionChanged,
    onOptionTaskActive,
    handleStartDateChange,
    handleProjectTypeChange,
    handleEstimatorTypeChange,
    selectedStartDate,
    getProjectTypeList
   } = props;

  return (
    <>
      <ProjectNewForm
        newProjectData={newProjectData}
        onOptionChanged={onOptionChanged}
        onOptionTaskActive={onOptionTaskActive}
        updateField={updateField}
        handleProjectTypeChange={handleProjectTypeChange}
        handleStartDateChange={handleStartDateChange}
        selectedStartDate={selectedStartDate}
        handleEstimatorTypeChange={handleEstimatorTypeChange}
        getAllProjectType={getProjectTypeList}
      />

    </>
  );
};

export default NewProject;
