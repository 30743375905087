
import FileManager, {
  Permissions,
  ItemView,
  FileManagerTypes,
  IItemViewProps,
} from "devextreme-react/file-manager";
import "../../../styles/ui-components/FileManager.scss";
import { useCallback, useState } from "react";
import FileViewer from "../../ui-components/fileViewer/FileViewer";

const PSFileManager = (props: any) => {
  const { data, onHandleFileView, fileviewData ,extension,isSingleFileloading} = props;

  const [itemViewMode, setItemViewMode] =
    useState<IItemViewProps["mode"]>("thumbnails");
  const [popupVisible, setPopupVisible] = useState(false);

  const onOptionChanged = useCallback(
    (e: FileManagerTypes.OptionChangedEvent) => {
      if (e.fullName === "itemView.mode") {
        setItemViewMode(e.value);
      }
    },
    [setItemViewMode]
  );

  const displayImagePopup = useCallback(
    (e: FileManagerTypes.SelectedFileOpenedEvent) => {
      setPopupVisible(true);
      onHandleFileView(e?.file?.dataItem);
    },
    [onHandleFileView]
  );

  const hideImagePopup = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  // Customize Detail Columns
  const customizeDetailColumns = (columns:any) => {
    // Remove the default 'dateModified' and 'size' columns
    const modifiedColumns = columns.filter(
      (column:any) => column.dataField !== "dateModified" && column.dataField !== "size"
    );

    modifiedColumns.push(
      {
        dataField: "createdDate",
        caption: "Created Date",
        dataType: "date",  
        width: 200,       
      },
      {
        dataField: "size",
        caption: "File Size",
        dataType: "string", 
        width: 100,         
      }
    );

    return modifiedColumns;
  };

  return (
    <>
      <FileManager
        fileSystemProvider={data}
        height={`calc(100vh - 200px)`}
        onOptionChanged={onOptionChanged}
        onSelectedFileOpened={displayImagePopup}
        customizeDetailColumns={customizeDetailColumns}  
        className="file-manager"
      >
        <ItemView mode={itemViewMode} />
        <Permissions
          create={true}
          copy={true}
          move={true}
          delete={true}
          rename={true}
          upload={true}
          download={true}
        />
      </FileManager>

      <FileViewer
        visible={popupVisible}
        onHiding={hideImagePopup}
        data={fileviewData}
        title={`Files Viewer`}
        isLoading={isSingleFileloading}
        extensionType={extension}
      />
    </>
  );
};

export default PSFileManager;
