import { ContactStatus as ContactStatusType } from '../client-catalog/types';

export type FilterContactStatus = ContactStatusType | 'All';

export interface UrlStateProps {
  popupVisible: boolean,
  selectedClientData: ClientUrl | null,
  EditpopupVisible: boolean,
  deletePopupVisible: boolean
}

export interface ClientUrl {
  complexity: any;
  url_template_path: string;
  url_keyword: string;
  url_file_name: string;
  driver_wait_time: any;
  note: string,
  url: string,
  clientName: string;
  status: string,
}

export type CreateClientProps = {
  name: string;
  email: string;
  phone: string;
  state: string;
  city: string;
  id?: string;
};


export interface ViewType {
  text: string;
  value: "gridView" | "boardView";
}

export  const options = ['LOW', 'MEDIUM', 'HIGH'];

export const statusOptions = ['New'];
