import { useEffect, useReducer } from 'react';
import DataGrid, {
  Column, Editing, HeaderFilter,
  Pager,
  Paging,
  Scrolling,
} from 'devextreme-react/data-grid';
import { LogsAndMetricsFilter } from './filter-toolbar/filterToolbar';
import {
  useLogsAndMetrics,
  useFetchMetricsDataApi
} from '../../../hooks/activity-monitor/useLogsMetrics';
import { ToolbarAnalytics } from '../../../components/ui-components/toolbar-analytics';
import { allowedPageSizes } from '../../../config/activity-monitor/commonConfig';
import TableSkeleton from '../../../components/ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton';
import { useFetchDropDownData } from '../../../hooks/app/useApps';

const initialState = {
  selectedClient: null,
  selectedProject: null,
  selectedTask: null,
  selectedExecution: null,
  taskData: [],
  executionData: [],
  projectData: [],
  isDataGridVisible: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_SELECTED_CLIENT':
      return { ...state, selectedClient: action.payload, isDataGridVisible: false };
    case 'SET_SELECTED_PROJECT':
      return { ...state, selectedProject: action.payload, isDataGridVisible: false };
    case 'SET_SELECTED_TASK':
      return { ...state, selectedTask: action.payload, isDataGridVisible: false };
    case 'SET_SELECTED_EXECUTION':
      return { ...state, selectedExecution: action.payload, isDataGridVisible: true };
    case 'SET_TASK_DATA':
      return { ...state, taskData: action.payload };
    case 'SET_EXECUTION_DATA':
      return { ...state, executionData: action.payload };
    case 'SET_PROJECT_DATA':
      return { ...state, projectData: action.payload };
    default:
      return state;
  }
};

export const LogsAndMetrics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    state: { clientId, projectId, taskId, executionId},
    filterByClientData,
    filterByProject,
    filterByTask,
    filterByExecutions,
    fetchDropDown,
  } = useLogsAndMetrics();

  const logmetricsParams = {
    clientId: clientId,
    projectId: projectId,
    executionId: executionId,
  };

  const { data: metricsGridData, isLoading: isMetricsLoading, refetch } = useFetchMetricsDataApi(logmetricsParams);
  const metricsData = metricsGridData?.data || [];
  const { data: clientSpecificDropDownData } = useFetchDropDownData();

  const {
    selectedClient,
    selectedProject,
    selectedTask,
    selectedExecution,
    taskData,
    executionData,
    projectData,
  } = state;

  useEffect(() => {
    dispatch({ type: 'SET_PROJECT_DATA', payload: fetchDropDown?.projects[clientId] || [] });
  }, [fetchDropDown, clientId]);

  useEffect(() => {
    dispatch({ type: 'SET_TASK_DATA', payload: fetchDropDown?.tasks[projectId] || [] });
  }, [fetchDropDown?.tasks, projectId]);

  useEffect(() => {
    dispatch({ type: 'SET_EXECUTION_DATA', payload: fetchDropDown?.executions[taskId] || [] });
  }, [fetchDropDown?.executions, taskId]);

  const processErrorData = (data: any) => {
    return data?.map((item: any) => {
      return {
        ...item,
        process_error: item?.process_error?.error_description || ''
      };
    });
  };
  
  const metricsFileData = processErrorData(metricsData)

  const getColumnNames = (data:any) => {
    try{
      if (data?.length > 0) {
        return Object?.keys(data[0]) || [];
      }
      return [];
    }catch(error){
      return [];
    }
  };

  const columns = getColumnNames(metricsFileData || [])?.map((columnName, index) => {
    if (columnName !== "is_duplicate" && columnName !== "S.NO" && columnName !== "confidence_status") {
      return (
        <Column
          key={index}
          dataField={columnName}
          caption={columnName?.replace(/_/g, " ")}
          allowHeaderFiltering={true}
          alignment="left"
        />
      );
    }
    return null;
  });

  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics
        title={"Logs & Metrics"}
        refresh={refetch}
      />
      <LogsAndMetricsFilter
        clientDropDownData={clientSpecificDropDownData}
        filterByClientData={(value: any) => {
          dispatch({ type: 'SET_SELECTED_CLIENT', payload: value });
          filterByClientData(value);
        }}
        filterByProject={(value: any) => {
          dispatch({ type: 'SET_SELECTED_PROJECT', payload: value });
          filterByProject(value);
        }}
        filterByTask={(value: any) => {
          dispatch({ type: 'SET_SELECTED_TASK', payload: value });
          filterByTask(value);
        }}
        filterByExecutions={(value: any) => {
          dispatch({ type: 'SET_SELECTED_EXECUTION', payload: value });
          filterByExecutions(value);
        }}

        projectData={projectData}
        taskData={taskData}
        executionData={executionData}
        selectedClient={selectedClient}
        selectedProject={selectedProject}
        selectedTask={selectedTask}
        selectedExecution={selectedExecution}
      />
        <div className="grid-container">
          {!isMetricsLoading ? (
            <div className="grid-container">
              <div className="horizontal-scroll">
                <DataGrid
                  className="grid theme-dependent height-large"
                  dataSource={metricsFileData || []}
                  focusedRowEnabled
                  noDataText={"No Logs & Metrics to display at this time"}
                  width={'100%'}
                  height='100%'
                  keyExpr={"metric_id"}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                >
                  <Editing
                    mode='cell'
                    allowUpdating={true}
                    allowDeleting={false}
                    allowAdding={false} />
                  <HeaderFilter visible={true} />
                  {columns}
                  <Scrolling rowRenderingMode='virtual'></Scrolling>
                  <Paging defaultPageSize={25} />
                  <Pager
                    visible={true}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                </DataGrid>
              </div>
            </div>
          ) : (
            <TableSkeleton />
          )}
        </div>
    </ div>
  );
};

export default LogsAndMetrics;