import Popup from "devextreme-react/popup";
import TreeView from "devextreme-react/tree-view";
import "../../../../../styles/client//telemetry.scss";
import { useCallback, useEffect, useRef } from "react";
import { LoadPanel } from "devextreme-react";
import CustomTitle from "./helper-component/Title";
import RenderTreeViewItem from "./helper-component/TreeViewItems";
import { useFetchTaskExecutionDetailsById } from "../../../../../hooks/app/useApps";

const TelemetryPopup = (props: any) => {
  const { selectedExecution, showTelemetryModal, onRowClick } = props;

  const treeViewRef = useRef<TreeView>(null);

  const { data, isLoading } =
    useFetchTaskExecutionDetailsById(selectedExecution);

  const { source = [], finalItemsId = [] } = data || {};

  const focusNode = useCallback(() => {
    const nodeId =
      finalItemsId?.length > 0 ? finalItemsId[finalItemsId.length - 1] : null;
    const treeView = treeViewRef.current?.instance;
    if (treeView && nodeId) {
      const nodeElement = treeView
        .element()
        .querySelector(`[data-item-id="${nodeId}"]`);
      nodeElement?.scrollIntoView({
        block: "end",
      });
    }
  }, [finalItemsId]);

  useEffect(() => {
    if (showTelemetryModal && finalItemsId.length > 0) {
      setTimeout(focusNode, 100);
    }
  }, [showTelemetryModal, finalItemsId, focusNode]);

  const renderTreeViewItem = (item: any) => {
    return (
      <RenderTreeViewItem {...item} selectedExecution={selectedExecution} />
    );
  };

  const renderTreeView = () => {
    if (isLoading) {
      return <LoadPanel visible={true} />;
    }
    return (
      <TreeView
        id="treeview"
        ref={treeViewRef}
        keyExpr="id"
        items={source}
        itemRender={renderTreeViewItem}
      />
    );
  };

  return (
    <>
      <Popup
        visible={showTelemetryModal}
        onHiding={onRowClick}
        fullScreen={true}
        titleRender={() => (
          <CustomTitle
            taskName={selectedExecution?.taskName}
            onClose={onRowClick}
            treeViewRef={treeViewRef as any}
          />
        )}
        width={1000}
        height={"auto"}
      >
          <div className="telemetry-popup">
            {renderTreeView()}
          </div>
      </Popup>
    </>
  );
};

export default TelemetryPopup;
