import { useCallback, useState } from "react";
import UrlNewForm from "./url-form/UrlNewForm";
import { ClientUrl } from "../types";
import { newClientUrl } from "../../../../config/constants";
import { useCreateUrl } from "../../../../hooks/client/useClientUrl";
import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";

const NewUrl = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;

  const [newContactData, setNewContactData] = useState<ClientUrl>({
    ...newClientUrl,
  });

  const updateField = (field: string) => (value: any) => {
    setNewContactData({ ...newContactData, ...{ [field]: value } });
  };

  const { mutate, isLoading } = useCreateUrl(changePopupVisibility);

  const handleFormSubmit = useCallback(() => {
    mutate(newContactData);
  }, [mutate, newContactData]);

  const onOptionChanged = (e: { clientId: string, name: string }) => {
    if (e && e.clientId) {
      setNewContactData({ ...newContactData, ...{ "clientId": e.clientId, "clientName": e.name } });
    }
  };

  const onTemplatePathChanged = (e: string) => {
    setNewContactData({ ...newContactData, ...{ "url_template_path": e } });
  };

  const onComplexityChanged = (e: string) => {
    setNewContactData({ ...newContactData, ...{ "complexity": e } });
  };

  const onStatusChanged = (e: string) => {
    setNewContactData({ ...newContactData, ...{ "status": e } });
  };

  return (
    <FormPopup
      title="New URLs"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <UrlNewForm
        newContactData={newContactData}
        onOptionChanged={onOptionChanged}
        updateField={updateField}
        onComplexityChanged={onComplexityChanged}
        onStatusChanged={onStatusChanged}
        onTemplatePathChanged={onTemplatePathChanged}
      />
    </FormPopup>
  );
};

export default NewUrl;