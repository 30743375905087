import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useNavigate } from "react-router";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellLastExecutionTime,
  editCellStatusRender,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../config/task/commonConfig";

const TaskDataGridPage = (props: any) => {
  const {
    data,
    onDeletePopupOpen,
    onEditTaskClick,
    onClonePopupOpen,
    gridRef,
    renderStartTask
  } = props;

  const navigate = useNavigate();

  const handleClientLinkClick = (event: any, clientId: string) => {
    event.preventDefault();
    navigate(`/client-profile?clientId=${clientId}`);
  };

  const handleProjectLinkClick = (event: any, projectId: string) => {
    navigate(`/project-profile?projectId=${projectId}`);
    event.preventDefault();
  };

  const handleTaskLinkClick = (event: any, taskId: string) => {
    event.preventDefault();
    navigate(`/client-task?taskId=${taskId}`);
  };

  return (
  <div className="recent-activity-grid">
    <DataGrid
      className="grid theme-dependent height-large"
      noDataText={"No tasks to display at this time"}
      focusedRowEnabled
      dataSource={data}
      keyExpr={"taskId"}
      allowColumnReordering
      showBorders
      ref={gridRef}
      allowColumnResizing={true}
    >
     <SearchPanel visible={true} width={200}/>
      <HeaderFilter visible />
      <Sorting mode="multiple" />
      <Scrolling mode="virtual" />
      <Column
        dataField="clientName"
        caption="Client"
        cellRender={(data) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;
          return (
            <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }}
      />
      <Column
        dataField="projectName"
        caption="Project Name"
        cellRender={(data) => {
          const projectName = data?.data?.projectName;
          const projectId = data?.data?.projectId;
          return (
            <a href="*" onClick={(e) => handleProjectLinkClick(e, projectId)}>
              {projectName}
            </a>
          );
        }}
      />
      <Column
        dataField="taskName"
        caption="Task Name"
        cellRender={(data) => {
          const taskDescription = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return (
            <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {taskDescription}
            </a>
          );
        }}
      />
      <Column dataField="projectType" caption="Activity Type" />
      <Column dataField="taskScheduling" caption="Frequency" />
      <Column
        dataField="status"
        caption="Status"
        cellRender={ContactStatus}
        editCellRender={editCellStatusRender}
      />
      <Column dataField="maxContainerGroup" caption="Max Container Group" alignment="left" />
      <Column dataField="containersPerGroup" caption="Containers Per Group" alignment="left" />
      <Column dataField="containerSize" caption="Container Size" />
      <Column
        dataField="latest_execution"
        caption="Last Execution"
        cellRender={cellLastExecutionTime}
      />
      <Editing
        mode="row"
        useIcons={true}
        allowUpdating
        allowDeleting={true}
        key={"id"}
      />
      <Column caption="" type="buttons" width={60} cellRender={renderStartTask}/>
      <Column caption="" type="buttons" width={140}>
        <ActionsButton
          name="edit"
          onClick={onEditTaskClick}
          hint="Edit"
          cssClass="action-image"
        />
        <ActionsButton
          text="Clone"
          hint="Clone"
          icon="copy"
          cssClass="action-image"
          onClick={onClonePopupOpen}
        />
        <ActionsButton
          name="delete"
          cssClass="action-image"
          onClick={onDeletePopupOpen}
        />
      </Column>
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      <Paging defaultPageSize={25} />
      <Pager
        visible={true}
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  </div>  
  );
};

export default TaskDataGridPage;
