import DataGrid, {
  Column,
  Paging,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";
import { Resource, ResourceData, RestructuredResource } from "../../../../pages/client/project-profile/types";
import uploadIcon from "../../../../assets/images/icons/user-plus.png";
import { useCallback, useState } from "react";
import { AddResource } from "../ResourcesTab/Add-Resource/AddResource";
import { ScrollView } from "devextreme-react";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import "../../../../styles/client/infrastructureTab.scss";

const InfrastructureTab = (props: any) => {
  const { ResourceData, refetch } = props;
  const projectId = useHelperHooks().getQueryParams("projectId");
  const [popupVisible, setPopupVisible] = useState(false);

  const mapResources = (resources: Resource[]): RestructuredResource[] => {
    return resources?.map((resource) => ({
      Name: resource?.resource || "",
      Amount: resource?.cost_per_day || "00.00",
      status: resource?.status,
      quantity: resource?.quantity,
      StartDate: resource?.start_date,
    }));
  };

  const togglePopupVisibility = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const restructureResources = (
    resourceData: ResourceData | null
  ): RestructuredResource[] => {
    if (!resourceData) {
      return [];
    }

    const { infrastructure = [] } = resourceData;

    return mapResources(infrastructure);
  };

  const restructuredResources = restructureResources(ResourceData?.[0]);

  return (
    <ScrollView>
      <DataGrid
        className="grid resourceTab"
        noDataText={"No Infra detail to display at this time"}
        dataSource={restructuredResources}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        repaintChangesOnly={false}
        columnAutoWidth={true}
        showBorders={true}
        width="100%"
        onRowInserted={togglePopupVisibility}
        allowColumnResizing={true}
      >
        <Toolbar>
          <Item location="after" locateInMenu="auto">
            <PSIconText
                alt="addFiles"
                text="Add Resource"
                src={uploadIcon}
                onClick={() => togglePopupVisibility()}  
              />
          </Item>
        </Toolbar>
        <Paging defaultPageSize={25} />
        <Column dataField="Name" caption="Resource" dataType="string" />
        <Column dataField="Amount" caption="Daily Cost" format={"currency"} />
        <Column dataField="quantity" caption="No.of Resource" />
        <Column
          dataField="status"
          caption="Status"
          cellRender={ContactStatus}
        />
        <Column dataField="StartDate" caption="StartDate" dataType="date" />
      </DataGrid>
      {popupVisible && (
        <AddResource
          popupVisible={popupVisible}
          changePopupVisibility={togglePopupVisibility}
          projectId={projectId}
          refetch={refetch}
          ResourceData={ResourceData}
        />
      )}
    </ScrollView>
  );
};

export default InfrastructureTab;
