import { useApp } from "../../../contexts/app";
import { useFetchDataCatalogGridApi } from "../../../hooks/datastudio/useDataCatalog";
import { useState } from "react";
import { useGetDataProcessingDataApi } from "../../../hooks/datastudio/useDataAnalyzer";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import CatalogGrid from "./helper-component/CatalogGrid";
import { useHelperHooks } from "../../../hooks/helper/utils";
import MyFilesView from "../../../assets/icons/icon-eye.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import { useNavigate } from "react-router-dom";

export const DataCatalog = () => {
  const { user } = useApp();
  const userId = user?.userId || "";
  const [filesData, setFilesData] = useState<any>();
  const [openPopup, setOpenPopup] = useState(false);
  const { data: catalogData, isLoading: isCatalogDataLoading, isFetching, refetch} = useFetchDataCatalogGridApi(userId);
  const { file_name, folder_path } = filesData || {};
  const { data: getFilesData, isLoading: isDataLoading } = useGetDataProcessingDataApi({filename: file_name, filepath: folder_path, isFileUploaded: true});
  const fileExtensions = file_name?.split(".").pop();
  const fileViewerData = fileExtensions === "pdf" ? getFilesData?.data?.data?.[0]?.filePath : JSON?.stringify(getFilesData?.data, null, 2);
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));
  const navigate = useNavigate();

  const title = "Data Catalog";
  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleViewFiles = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      file_name: data?.fileName,
      folder_path: data?.filePath,
      status: data?.status,
      processType: data?.processType,
    };
    setFilesData(params);
    setOpenPopup(true);
  };

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt = "View File"
        width={28}
        hint="View File"
        height={28}
        onClick={(e: any) => handleViewFiles(e, cell)}
      />
    );
  };

  const handleRowClick = (cell: any) => {
    const { data } = cell;
    const filename = data?.fileName;
    const filepath = data?.filePath;
    const dataCatalogId = data?.dataCatalogId;
    const userId = data?.userId;
    const processType = data?.processType;
    const status = data?.status;
    if (data?.processType === "Data Analyzer") {
      navigate(`/data-analyzer?fileName=${filename}&filePath=${filepath}&action=${true}`);
    } else if (data?.processType === "Document Analyzer") {
      navigate(`/document-analyzer?fileName=${filename}&dataCatalogId=${dataCatalogId}&userId=${userId}&processType=${processType}&status=${status}&action=${true}`);
    }
  };

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.fileName}
      </span>
    );
  };

  const DataGridProps = {
    catalogData,
    isCatalogDataLoading,
    refetch,
    handleViewFiles,
    renderViewFile,
    renderFileName,
    filesData,
    openPopup,
    closePopupException,
    fileViewerData,
    isDataLoading,
    fileExtensions,
  };

  const pageBodyComponent = () => [
    {
      mode: "grid",
      Component: CatalogGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: title,
    refetch,
    additionalToolbarContent: null,
    pageBodyComponent: pageBodyComponent(),
    isLoading: isFetching,
    showBackButton: showBackButton,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
    </>
  );
};

export default DataCatalog;
