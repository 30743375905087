import { useCallback, useMemo, useState } from "react";
import SummaryChart from "../../../components/client/client-dashboard/dataSummary";
import "../../../styles/client/client-dashboard.scss";
import ComputeUitilization from "../../../components/client/client-dashboard/computeUitilization";
import ActiveTask from "../../../components/client/client-dashboard/activeTask";
import { Item } from "devextreme-react/toolbar";
import { Projects } from "../projects/projects";
import { DropDownButton } from "devextreme-react";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { customDateRange, dropDownOptions } from "../../../config/constants";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { useAppHook } from "../../../hooks/app/useApps";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

export const ClientDashboard = () => {
  const [selectedDays, setSelectedDays] = useState({});

  const {
    state: { client, clientId },
    filterByClient,
    clientDropDownData,
    onRowClick
  } = useAppHook();

  const { data: DateRange } = useFetchDateRange();

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod ] = value?.value;
      const screen = "homeGraph";
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod, screen, clientId });
      }
      else {
        setSelectedDays({ timePeriod, screen, clientId });
      }
      const eventName = `Date Range: ${timePeriod}-${screen}`;
      GTAButtonEventHandler(eventName);
    },
    [setSelectedDays, clientId]
  );

  const DataGridProps = useMemo(() => {
    return {
      onRowClick,
      showToolbar: false,
      selectedDays,
      clientDropDownData,
      clientId
    };
  }, [clientDropDownData, onRowClick,clientId, selectedDays]);

  const AnalyticsComponent = useCallback(() => {
    return (
      <div className="view-wrapper-analytics">
        <SummaryChart {...DataGridProps} />
        <div className="datails-wrapper">
          <ActiveTask {...DataGridProps} />
          <ComputeUitilization {...DataGridProps} />
        </div>
      </div>
     
    );
  }, [DataGridProps]);

  const pageBodyComponent = useMemo(
    () => [
      {
        title: "Cost Summary",
        Component: AnalyticsComponent,
        componentProps: DataGridProps,
        reloadParams: ['fetch-finops-cost', 'fetch-active-task']
      },
      {
        title: "Project Details",
        Component: Projects,
        componentProps: {
          showToolbar: false,
          filteredClientId: clientId,
          height: "height-small",
        },
        pageRoute: "/projects",
        componentId: "projects",
        reloadParams: 'fetch-project-clientInfo'  
      },
      {
        title: "Recent Activity",
        Component: RecentActivityDataGrid,
        componentProps: {
          showToolbar: false,
          filteredClientId: clientId,
          height: "height-small",
          selectedDays: selectedDays,
          onCurrentValueChange: onCurrentValueChange
        },
        pageRoute: "/activity-dashboard",
        componentId: "activitydashboard",
        reloadParams: 'fetch-recent-activity'
      },
      {
        title: "Alerts & Exceptions",
        Component: AlertExceptionPage,
        componentProps: {
          showToolbar: false,
          filteredClientId: clientId,
          height: "height-small",
          selectedDays: selectedDays,
          onCurrentValueChange: onCurrentValueChange
        },
        pageRoute: "/alert-exceptions",
        componentId: "alert&exceptions",
        reloadParams: 'fetch-alert-Exception'
      },
    ],
    [AnalyticsComponent, DataGridProps, clientId, onCurrentValueChange, selectedDays]
  );

  const additionalToolbarContent = useMemo(() => {
    return (
      <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
        <DropDownButton
          items={clientDropDownData || ["Loading..."]}
          stylingMode="text"
          text={client}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
        />
        <DateDropDown onDateChange={onCurrentValueChange} data={DateRange} />
      </Item>
    );
  }, [
    client,
    clientDropDownData,
    DateRange,
    filterByClient,
    onCurrentValueChange,
  ]);

  const LayoutProps = useMemo(() => {
    return {
      headerText: "Client Activity Summary",
      additionalToolbarContent,
      pageBodyComponent: pageBodyComponent,
    };
  }, [additionalToolbarContent, pageBodyComponent]);

  return <DataGridTitleCardLayout {...LayoutProps} />;
};
