import React, { useCallback, useEffect, useState } from "react";
import DateRangeBox from "devextreme-react/date-range-box";
import DropDownButton from "devextreme-react/drop-down-button";
import { DateboxProps } from "./types";
import "../../../styles/ui-components/DateBox.scss";

const DateDropDown = (props: DateboxProps) => {
  const { onDateChange, data } = props;
  const [selectedOption, setSelectedOption] = useState<string | null>("");
  const [dateboxData, setDateboxData] = useState<null | any>(null);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      setDateboxData(data);
    }
  }, [data]);

  const handleOptionSelect = useCallback(
    (option: string) => {
      setSelectedOption(option);
      setDropdownVisible(true);
      const endTime = new Date();
      switch (option) {
        case dateboxData?.dateRange?.[0].datepick:
          const startOfWeek = new Date(
            endTime?.getFullYear(),
            endTime?.getMonth(),
            endTime?.getDate() - endTime?.getDay()
          );
          const frequencyWeek = "thisWeek";
          let thisWeek = { value: [startOfWeek, endTime, frequencyWeek] };
          onDateChange(thisWeek);
          break;
        case dateboxData?.dateRange?.[1].datepick:
          const startOfMonth = new Date(
            endTime?.getFullYear(),
            endTime?.getMonth(),
            1
          );
          const frequencyMonth = "thisMonth";
          let thisMonth = { value: [startOfMonth, endTime, frequencyMonth] };
          onDateChange(thisMonth);
          break;
        case dateboxData?.dateRange?.[2].datepick:
          setDropdownVisible(false);
          break;
        case dateboxData?.dateRange?.[3].datepick:
          const last7DaysStartDate = new Date();
          last7DaysStartDate?.setDate(last7DaysStartDate?.getDate() - 6);
          const frequency7days = "last7Days";
          let lastSevenDays = {
            value: [last7DaysStartDate, endTime, frequency7days],
          };
          onDateChange(lastSevenDays);
          break;
        case dateboxData?.dateRange?.[4].datepick:
          const last30DaysStartDate = new Date();
          last30DaysStartDate?.setDate(last30DaysStartDate?.getDate() - 29);
          const frequency30days = "last30Days";
          let lastThirtyDays = {
            value: [last30DaysStartDate, endTime, frequency30days],
          };
          onDateChange(lastThirtyDays);
          break;
        default:
          break;
      }
    },
    [onDateChange, dateboxData?.dateRange]
  );

  const handleCustomDateChange = (e: any) => {
    const { value } = e;
    if (value) {
      const customdays = "CustomDateRange";
      const customDateRange = { value: [value?.[0], value?.[1], customdays] };
      onDateChange(customDateRange);
    }
  };

  useEffect(() => {
    if (dateboxData) {
      handleOptionSelect(dateboxData?.dateRange?.[4].datepick ?? "");
    }
  }, [handleOptionSelect, dateboxData]);

  return (
    <div className="datebox">
        <DropDownButton
          dropDownOptions={{ width: "auto" }}
          splitButton={false}
          stylingMode="text"
          text={selectedOption || ""}
          displayExpr="datepick"
          items={dateboxData?.dateRange}
          onItemClick={(e) => handleOptionSelect(e?.itemData?.datepick)}
        />
      {selectedOption === dateboxData?.dateRange?.[2]?.datepick && (
        <div className="custom-date-range-container">
          <DateRangeBox
            className="custom-date-range"
            multiView={false}
            onValueChanged={handleCustomDateChange}
          />
        </div>
      )}
    </div>
  );
};

export default DateDropDown;
