import { useCallback, useState } from "react";
import TaskNewForm from './taskNewFormPopup'
import { TaskData } from "../../types";
import { newTaskFormData } from "../../types";
import { useCreateTask, useFetchProject, useFetchUrls } from '../../../../../hooks/task/useTask';
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { DropDownBoxTypes } from "devextreme-react/cjs/drop-down-box";
import CustomStore from "devextreme/data/custom_store";
import { FormPopup } from "../../../../../components/ui-components/form/form-popup/FormPopup";

export const TaskFormPopup = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;
  const [gridBoxValue, setGridBoxValue] = useState([""]);

  const [newTaskData, setNewTaskData] = useState<TaskData>({
    ...newTaskFormData,
  });

  const updateField = (field: string) => (value: any) => {
    setNewTaskData({ ...newTaskData, ...{ [field]: value } });
  };

  const { mutate,isLoading } = useCreateTask(changePopupVisibility);
  const [selectedValues, setSelectedValues] = useState<any>('');
  const { data: urlGridProp, isLoading: isUrlLoading } = useFetchUrls(selectedValues) as any;
  const { data: fetchProject } = useFetchProject(selectedValues) as any;
  const [selectedProjectType, setSelectedProjectType] = useState<any>(null);

  const makeLocalDataSource = (localData: any[]) => {
    if (!Array.isArray(localData) || localData.length === 0 || !localData[0].hasOwnProperty("url_id")) {
      return null;
    }

    return new CustomStore({
      loadMode: "raw",
      key: "url_id",
      load() {
        return localData;
      },
    });
  };

  const gridDataSourceMy = urlGridProp ? makeLocalDataSource(urlGridProp) : null;

  const handleFormSubmit = useCallback(() => {
    mutate(newTaskData);
  }, [mutate, newTaskData]);

  const onOptionChanged = (e: any) => {
    if (e && e.clientId) {
      setNewTaskData({ ...newTaskData, ...{ "clientId": e.clientId, "clientName": e.name, "projectId": '', "projectName": '' } });
      setSelectedValues(e.clientId);
    }
  };

  const onImageOptionChanged = (e: { imageName: string,postProcessorModule:string }) => {
    if (e && e.imageName) {
      setNewTaskData({ ...newTaskData, ...{ "imageName": e.imageName,"postProcessorModule":e.postProcessorModule } });
    }
  };

  const onProjectOptionChanged = (e: { projectName: string, projectId: string ,projectType: string}) => {
    if (e && newTaskData.clientId) {
      setNewTaskData({ ...newTaskData, ...{ "projectName": e.projectName, "projectId": e.projectId, "projectType":e.projectType } });
      setSelectedProjectType(e.projectType);
    }
  };

  const onOptionMaxContainer = (e: { value: string }) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "containersPerGroup": e } });
    }
  };

  const onOptionTaskScedule = (e: any) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "scheduler": e, "taskScheduling": e.frequency } });
    }
  };

  const onOptionTaskActive = (e: string) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "status": e } });
    }
  };

  const onOptionContainerSize = (e: string) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "containerSize": e } });
    }
  };

  const handleInfraReset = () => {
    setNewTaskData({ ...newTaskData, ...{ "infraResources": [] } });
  };

  const dataGridOnSelectionChanged = useCallback((e: DataGridTypes.SelectionChangedEvent) => {
    setGridBoxValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
    setNewTaskData((prevData) => ({
      ...prevData,
      "urls": e.selectedRowKeys,
      "urlList": e.selectedRowsData
    }));

  }, []);

  const syncDataGridSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
    setGridBoxValue(e.value || []);
  }, []);

  const handleInfraTypeChange = (value: any) => {
    setNewTaskData({ ...newTaskData, ...{ "infraResources": value } });
  };

  if (typeof window === 'undefined') return null;

  return (
    <FormPopup
      title="Add New Task"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <TaskNewForm
        newTaskData={newTaskData}
        onImageOptionChanged={onImageOptionChanged}
        onOptionChanged={onOptionChanged}
        onOptionTaskScedule={onOptionTaskScedule}
        onOptionMaxContainer={onOptionMaxContainer}
        onOptionContainerSize={onOptionContainerSize}
        onOptionTaskActive={onOptionTaskActive}
        dataGridOnSelectionChanged={dataGridOnSelectionChanged}
        gridBoxValue={gridBoxValue}
        updateField={updateField}
        syncDataGridSelection={syncDataGridSelection}
        urlGridData={gridDataSourceMy}
        selectedProjectType={selectedProjectType}
        fetchProject={fetchProject}
        onProjectOptionChanged={onProjectOptionChanged}
        handleInfraTypeChange={handleInfraTypeChange}
        handleInfraReset={handleInfraReset}
        isUrlLoading={isUrlLoading}
      />
    </FormPopup>
  );
};


