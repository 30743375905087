import { ProjectMap } from "../../../../../fixture/project";
import { useCallback, useMemo, useState } from "react";
import {
  useCreateProject,
  useFetchProjectType,
  useProjects,
} from "../../../../../hooks/client/useProject";
import { RightSidePannel } from "../../../../../components/ui-components/right-side-panel/right-side-panel";
import NewProject from "../newProject";
import { ToolbarAnalytics } from "../../../../../components/ui-components/toolbar-analytics";
import {
  newProjectFormData,
  ProjectData,
} from "../../types";
import { useNavigate } from "react-router-dom";
import PsImageWithTextCard from "../../../../../components/ui-components/PsImageWithTextCard/ItemCard";
import "../../../../../styles/client/Project.scss";
import { GTAButtonEventHandler } from "../../../../../utlis/GAT-event-handler";

export const ProjectsList = () => {
  const { state, onToggleProjectClick } = useProjects();
  const { mutate, isLoading, isSuccess, responseData } = useCreateProject();
  const navigate = useNavigate();
  const func_type = "function";
  const { data: projectTypeData } = useFetchProjectType(func_type);
  const getProjectTypeList = projectTypeData?.functionType
  const [newProjectData, setNewProjectData] = useState<ProjectData>({
    ...newProjectFormData,
  });

  const responseProjectId = useMemo(() => {
    return responseData?.data?.[0]?.projectId || "";
  }, [responseData]);

  const updateField = useCallback(
    (field: string) => (value: any) => {
      setNewProjectData({ ...newProjectData, ...{ [field]: value } });
    },
    [newProjectData]
  );

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  

  const onOptionChanged = useCallback(
    (e: { clientId: string; name: string }) => {
      if (e) {
        setNewProjectData({
          ...newProjectData,
          ...{ clientId: e.clientId, clientName: e.name },
        });
        const eventName = `Project Option Changed: ${e.name}`;
        GTAButtonEventHandler(eventName);
      }
    },
    [newProjectData]
  );

  const onOptionTaskActive = useCallback(
    (e: string) => {
      if (e) {
        setNewProjectData({ ...newProjectData, ...{ status: e } });
        const eventName = `Project task active: ${e}`;
        GTAButtonEventHandler(eventName);
      }
    },
    [newProjectData]
  );

  const handleStartDateChange = useCallback(
    (value: any) => {
      setSelectedStartDate(value);
      setNewProjectData({ ...newProjectData, ...{ startDate: value } });
      const eventName = `Project start date: ${value}`;
      GTAButtonEventHandler(eventName);
    },
    [newProjectData]
  );

  const handleEndDateChange = useCallback(
    (value: any) => {
      setSelectedEndDate(value);
      setNewProjectData({ ...newProjectData, ...{ endDate: value } });
      const eventName = `Project end date: ${value}`;
      GTAButtonEventHandler(eventName);
    },
    [newProjectData]
  );

  const handleProjectTypeChange = useCallback(
    (e: any) => {
      const {projectType,projectModule} = e;
      setNewProjectData({ ...newProjectData, ...{ projectType: projectType, projectModule:projectModule} });
      const eventName = `Project type: ${projectType}`;
      GTAButtonEventHandler(eventName);
    },
    [newProjectData]
  );

  const handleEstimatorTypeChange = useCallback(
    (value: any) => {
      setNewProjectData({
        ...newProjectData,
        ...{ estimatorName: value?.name, estimatorId: value?.estimaterId },
      });
    },
    [newProjectData]
  );

  const handleFormSubmit = useCallback(() => {
    if (isSuccess) {
      navigate(`/project-profile?projectId=${responseProjectId}`);
    } else {
      mutate(newProjectData);
    }
    const eventName = `Project form submit: ${responseProjectId}`;
    GTAButtonEventHandler(eventName);
  }, [isSuccess, mutate, navigate, newProjectData, responseProjectId]);

  const FormProps = useMemo(() => {
    return {
      isOpened: state?.showProjectModal,
      changePanelOpened: onToggleProjectClick,
      handleFormSubmit,
      responseData,
      isLoading,
      newProjectData,
      updateField,
      onOptionChanged,
      onOptionTaskActive,
      handleStartDateChange,
      handleEndDateChange,
      handleProjectTypeChange,
      handleEstimatorTypeChange,
      selectedStartDate,
      selectedEndDate,
      getProjectTypeList
    };
  }, [
    state?.showProjectModal,
    onToggleProjectClick,
    handleFormSubmit,
    responseData,
    isLoading,
    newProjectData,
    updateField,
    onOptionChanged,
    onOptionTaskActive,
    handleStartDateChange,
    handleEndDateChange,
    handleProjectTypeChange,
    handleEstimatorTypeChange,
    selectedStartDate,
    selectedEndDate,
    getProjectTypeList
  ]);

  const ItemProps = useMemo(() => {
    return {
      cardItems: ProjectMap,
      onCardClick: onToggleProjectClick,
    };
  }, [onToggleProjectClick]);

  return (
    <>
      <ToolbarAnalytics title={"Add Projects"} showBackButton />
      <PsImageWithTextCard {...ItemProps} />
      <RightSidePannel
        isOpened={state?.showProjectModal}
        changePanelOpened={onToggleProjectClick}
        title={"Project"}
        onSave={handleFormSubmit}
        isLoading={isLoading}
        isSaveDisabled={isSuccess}
      >
        <NewProject {...FormProps} />
      </RightSidePannel>
    </>
  );
};
export default ProjectsList;
