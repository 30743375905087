import Popup from "devextreme-react/popup";
import PsButton from "../ui-components/button/Button";
import { ReplaceNullConfirmModalProps } from "./type";
import { TagBox, TextBox } from "devextreme-react";
import { useState } from "react";

const ReplaceNullConfirmModal = (props: ReplaceNullConfirmModalProps) => {
  const { show, onConfirm, columnData, onClose } = props;
  const [selectedData, setSelectedData] = useState<any>({
    columnName: "" || [],
    replaceValue: "" || [],
  });

  const loaderProps = {
    show: false,
    loadertext: "Please Wait...",
  };

  const setSelectedColumn = (e: any) => {
    const columns = e?.length === 1 ? e[0] : e;
    setSelectedData((prevData: any) => ({
      ...prevData,
      columnName: columns,
    }));
  };

  const setReplaceValue = (e: any) => {
    const replaceValue = e?.value;
    const columnNames = selectedData?.columnName;

    if (Array.isArray(columnNames)) {
      const valuesArray = replaceValue
        .split(",")
        .map((val: string) => val.trim());
      setSelectedData((prevData: any) => ({
        ...prevData,
        replaceValue: valuesArray,
      }));
    } else {
      setSelectedData((prevData: any) => ({
        ...prevData,
        replaceValue,
      }));
    }
  };

  return (
    <Popup
      title="Replace Null with?"
      visible={show}
      width="auto"
      height="auto"
      onHiding={onClose}
      onShowing={() => setSelectedData({ columnName: [], replaceValue: [] })}
    >
      <div style={{ display: "flex", marginTop: "10px", marginBottom: "20px" }}>
        <TagBox
          items={columnData}
          placeholder="Select column(s)"
          onValueChange={(e) => {
            setSelectedColumn(e);
          }}
          showClearButton={true}
          showSelectionControls={true}
          width={280}
          value={
            Array.isArray(selectedData?.columnName)
              ? selectedData?.columnName
              : [selectedData?.columnName]
          }
        />
        <div style={{ marginLeft: "20px" }}>
          <TextBox
            placeholder={
              Array.isArray(selectedData?.columnName) &&
              selectedData?.columnName.length > 1
                ? "Enter values separated by comma"
                : "Enter value(s)"
            }
            showClearButton={true}
            width={280}
            onValueChanged={setReplaceValue}
            value={
              Array.isArray(selectedData?.replaceValue)
                ? selectedData?.replaceValue.join(", ")
                : selectedData?.replaceValue
            }
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PsButton
          text="Replace"
          onClick={() => onConfirm(selectedData)}
          loaderProps={loaderProps}
          mode="contained"
          type="default"
        />
        <PsButton text="Close" onClick={onClose} type="normal" />
      </div>
    </Popup>
  );
};

export default ReplaceNullConfirmModal;
