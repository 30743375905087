import React, { useState, useEffect, useMemo, useCallback } from "react";
import DataGrid, {
  Column,
  DataGridTypes,
  MasterDetail,
  Button as ActionButton,
} from "devextreme-react/data-grid";
import { useStopProgress } from "../../../../../hooks/task/useInfra";
import "../../../../../styles/task/infra-data.scss";
import {
  cellEndTime,
  cellStartTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import ConfirmModal from "./helper-component/ConfirmModal";
import showNotification from "../../../../../components/ui-components/alertPopup/AlertPopup";
import AciProcessDetails from "./helper-component/AciProcessDetails";

const ProcessDetails = (props: DataGridTypes.MasterDetailTemplateData) => {
  const { executions, post_execution, aci_instances,  clientId,
    taskId,
    executionId,
    clientTag,
    customerName,
    taskName } =
    props?.data?.data || {};


  const [popupVisible, setPopupVisible] = useState(false);
  const { mutate, isLoading, error, isSuccess } = useStopProgress();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `terminating container instance...`,
        type: "success",
      });
    } else if (error) {
      showNotification({
        message: `Error terminating container instance: ${error.message}`,
        type: "error",
      });
    }
  }, [isSuccess, isLoading, error]);

  const onHandleConfirm = useCallback(
    (e: any) => {
      const cotainerGroupData = e?.row?.data;
      const {containers} = cotainerGroupData
      const containerName = containers[0]?.container_name;
      const queueMsg = {
        container_name: containerName,
        container_group_name: e?.row?.data?.group_name,
        module: "manual_shutdown_container",
        customer_id: clientId,
        taskId: taskId,
        executionId: executionId,
        clientTag: clientTag,
        clientName: customerName,
        taskName: taskName,
      };
      mutate(queueMsg);
    },
    [clientId, clientTag, customerName, executionId, mutate, taskId, taskName]
  );

  const onHandleModal = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const ConfirmModalProps = useMemo(() => {
    return {
      onHandleConfirm,
      onHandleModal,
      popupVisible,
      isLoading,
    };
  }, [onHandleConfirm, onHandleModal, popupVisible, isLoading]);

  return (
    <React.Fragment>
      <div className="infra-title-header">Pre-Processor</div>
      <DataGrid dataSource={executions} showBorders={true} allowColumnReordering allowColumnResizing>
        <Column dataField="function_app_name" caption="Function App Name" />
        <Column
          dataField="starttime"
          caption="Start Time"
          cellRender={cellStartTime}
        />
        <Column
          dataField="endTime"
          caption="End Time"
          cellRender={cellEndTime}
        />
        <Column dataField="status" caption="Status" />
      </DataGrid>
      <div className="infra-title-header">Main Processor</div>
      <DataGrid dataSource={aci_instances} showBorders={true}>
        <MasterDetail enabled={true} component={AciProcessDetails} />
        <Column dataField="group_name" caption="Container Group Name" />
        <Column
          dataField="starttime"
          caption="Start Time"
          cellRender={cellStartTime}
        />
        <Column
          dataField="endTime"
          caption="End Time"
          cellRender={cellEndTime}
        />
        <Column dataField="status" caption="Status" />
        <Column caption="" type="buttons" width={130}>
          <ActionButton
            text="STOP"
            cssClass="stop-icon-image"
            onClick={(e) => onHandleConfirm(e)}
            disabled={(e) => e?.row?.data?.status === "Completed"}
          />
        </Column>
      </DataGrid>
      <div className="infra-title-header">Post-Processor</div>
      <DataGrid dataSource={post_execution} showBorders={true}>
        <Column dataField="function_app_name" caption="Function App Name" />
        <Column
          dataField="starttime"
          caption="Start Time"
          cellRender={cellStartTime}
        />
        <Column
          dataField="endTime"
          caption="End Time"
          cellRender={cellEndTime}
        />
        <Column dataField="status" caption="Status" />
      </DataGrid>
      <ConfirmModal {...ConfirmModalProps} />
    </React.Fragment>
  );
};

export default ProcessDetails;
