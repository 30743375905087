import DataGrid, {
  Scrolling,
  Column,
  Paging,
  Pager,
  HeaderFilter,
  SearchPanel
} from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellStartTime,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import { onExporting } from "../../../../config/constants";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import { useState } from "react";
import { useFetchMetricsDataApi } from "../../../../hooks/activity-monitor/useLogsMetrics";
import MetricsIcon from "../../../../assets/icons/icon-metrics-4.png";
import PSIconText from "../../../ui-components/icon-with-text/IconText";

export const recentStatus = ["In Progress", "Completed", "Failed"];

const RecentActivityGridView = (props: any) => {
  const {
    data,
    onRowClick,
    PagerVisible,
    handleProjectLinkClick,
    handleClientLinkClick,
    handleTaskLinkClick,
    gridRef,
    height,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [filesData, setFilesData] = useState<any>();
  const logmetricsParams = {
    projectId: filesData?.projectId,
    clientId: filesData?.clientId,
    executionId: filesData?.executionId,
  };
  const { data: metricsGridData, isLoading: isMetricsLoading } = useFetchMetricsDataApi(logmetricsParams);
  const metricsData = metricsGridData?.data || [];
  const fileViewerData = JSON?.stringify(metricsData, null, 2);

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleViewFiles = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      executionId: data?.executionId,
      clientName: data?.clientName,
      projectName: data?.projectName,
      taskName: data?.taskName,
      projectId: data?.projectId,
      clientId: data?.clientId,
    };
    setFilesData(params);
    setOpenPopup(true);
  }

  const renderViewMetrics = (cell: any) => {
    return (
      <PSIconText
        src={MetricsIcon}
        alt="Metrics"
        hint="Metrics"
        width={22}
        height={22}
        onClick={(e: any) => handleViewFiles(e, cell)}
      />
    );
  }

  const title = {
    clientName: filesData?.clientName,
    projectName: filesData?.projectName,
    taskName: filesData?.taskName,
  }

  return (
    <>
  <div className="recent-activity-grid">
    <DataGrid
      className={`grid theme-dependent ${height}`}
      noDataText={"No recent activity to display at this time"}
      dataSource={data}
      onExporting={onExporting}
      allowColumnReordering
      showBorders
      onRowClick={onRowClick}
      ref={gridRef}
      allowColumnResizing={true}
    >
      <HeaderFilter visible={true} />
      <SearchPanel visible={true} width={200}/>
      <Column
        dataField="clientName"
        caption="Client"
        cellRender={(data) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;
          return (
            <a href="null" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }}
      />
      <Column
        dataField="projectName"
        caption="Project Name"
        cellRender={(data) => {
          const projectName = data?.data?.projectName;
          const projectId = data?.data?.projectId;
          return (
            <a
              href="null"
              onClick={(e) => handleProjectLinkClick(e, projectId)}
            >
              {projectName}
            </a>
          );
        }}
      />
      <Column
        dataField="taskName"
        caption="Task Name"
        cellRender={(data) => {
          const TaskName = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return (
            <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {TaskName}
            </a>
          );
        }}
      />
      <Column
        dataField="executionId"
        caption="Execution ID"
        cellRender={(data) => {
          const executionId = data?.data?.executionId;
          const truncatedId = executionId ? executionId.substr(0, 10) : "";
          return <>{truncatedId}</>;
        }}
      />
      <Column
        dataField="projectType"
        caption="Activity Type"
        dataType="string"
      />
      <Column
        dataField="status"
        caption="Status"
        dataType="string"
        cellRender={ContactStatus}
      />
      <Column
        dataField="timestamp"
        caption="Time Started"
        cellRender={cellStartTime}
      />
      <Column
        dataField="endTime"
        caption="Time Completed"
        cellRender={cellEndTime}
      />
      <Column caption="" type="buttons" width={180} cellRender={renderViewMetrics}>
      </Column>
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      <Paging defaultPageSize={25} />
      <Pager
        visible={PagerVisible}
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
    <FileViewer
    visible={openPopup}
    onHiding={closePopupException}
    data={fileViewerData}
    title={title}
    isLoading={isMetricsLoading}
    extensionType={"json"}
  />
</div>  
  </>
  );
};

export default RecentActivityGridView;