import {
  Chart,
  Series,
  CommonSeriesSettings,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
  Legend
} from "devextreme-react/chart";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useFetchTotalCost } from "../../../../hooks/app/useApps";
import Card from "../../../ui-components/card/card";
import { convertToUSD } from "../../../../utlis/helper";

const CostChart = ({ selectedDays }: any) => {
  const { data: totalCost, isFetching } = useFetchTotalCost(selectedDays);
  const totalCostData = totalCost?.client_info;

  const chartData = totalCostData?.map((client: any) => ({
    clientName: client?.clientName,
    digitalServices: parseInt(client?.totalCost?.find((item: any) => item?.name === "Digital Services")?.value.replace('$', '')) || 0,
    operations: parseInt(client?.totalCost?.find((item: any) => item?.name === "Operations")?.value.replace('$', '')) || 0,
    infrastructure: parseInt(client?.totalCost?.find((item: any) => item?.name === "Infrastructure")?.value.replace('$', '')) || 0
  }));

  if (isFetching) {
    return (
      <Card className="project-details-card">
        <PsSkeleton height={300} />
      </Card>
    );
  }

  return (
    <Card className="project-details-card">
      <div className="chart-task-column">
        <Chart
          id="expensesChart"
          dataSource={chartData}
          width={500}
          height={300}
        >
          <CommonSeriesSettings
            argumentField="clientName"
            type="bar"
            ignoreEmptyPoints={true}
          />
          <Series
            valueField="digitalServices"
            name="Digital Services"
          />
          <Series
            valueField="operations"
            name="Operations"
          />
          <Series
            valueField="infrastructure"
            name="Infrastructure"
          />
          <ArgumentAxis
            title="Resources"
            label={{
              font: { size: 5 }
            }}
          />
          <ValueAxis
            title="Cost"
            label={{
              font: { size: 5 }
            }}
          />
          <Tooltip
            enabled={true}
            customizeTooltip={(info: any) => {
              return {
                text: `${info?.argumentText}\n${info?.seriesName}: ${convertToUSD(info?.valueText)}`
              };
            }}
          />
          <Legend />
        </Chart>
      </div>
    </Card>
  );
};

export default CostChart;
