import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import TaskDetails from "../../../../components/task/task-details/TaskDetails";
import Notes from "../../../../components/task/task-details/notesTab/notes";
import FinopsTab from "../../../../components/task/task-details/finopsTab/finopsTab";
import IntegrationTab from "../../../../components/task/task-details/integrationTab/integrationTab";
import CollaborationChats from "../../../../components/ui-components/chat/Chat";
import TaskDataTab from "../../../../components/task/task-details/TaskDataTab";

const TaskSummary = (props: any) => {
  const { selectedDays, taskData } = props;
  const TabMap = [
    { title: "Task Details", component: TaskDetails, componentProps: { selectedDays } },
    { title: "Notes", component: Notes },
    {
      title: "Client Data",
      component: TaskDataTab,
      componentProps: { selectedDays, taskData },
    },
    {
      title: "Invoice",
      component: FinopsTab,
      componentProps: { taskData },
    },
    { title: "Collaboration", component: CollaborationChats },
    { title: "Integration", component: IntegrationTab },
   
  ];

  const TabPanelItems = TabMap.map((tab, index) => {
    const Component = tab.component;
    return (
      <TabPanelItem title={tab.title} key={index}>
        <div style={{ minHeight: "465px" }}>
          <Component {...tab.componentProps} />
        </div>
      </TabPanelItem>
    );
  });

  return (
    <TabPanel
      focusStateEnabled={false}
      deferRendering={false}
      animationEnabled={true}
      swipeEnabled={true}
    >
      {TabPanelItems}
    </TabPanel>
  );
};

export default TaskSummary;
