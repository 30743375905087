import WorkbenchLayout from "../../layout/WorkbenchLayout";
import WorkbenchCards from "../../helper-component/workbenchCards/Workbenchcards";
import PsButton from "../../../../components/ui-components/button/Button";
import {
  getTransformedWorkbooks,
  projectIdsString,
  useCreateWorkBook,
  useFetchProjectInfo,
  useFetchRecentActivityInfo,
  useFetchWorkSpaceDetails,
  useWorkBench,
} from "../../../../hooks/workbench/useWorkbench";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddNewWorkBook from "../form/NewWorkBook";
import { useApp } from "../../../../contexts/app";
import ShareWorkbenchAccess from "../form/ShareAccess";
import { Item } from "devextreme-react/toolbar";
import { useNavigate } from "react-router-dom";
import { newWorkBookFormData, WorkBookData } from "../../types";
import AssignWorkSpaceForm from "../form/AssignWorkSpaceForm";
import WorkbookIcon from "../../../../assets/media/workbook.png";
import RecentActivityDataGrid from "../../../../components/core-component/recentActivity/recentActivity";
import DataGridTitleCardLayout from "../../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { Projects } from "../../../client/projects/projects";
import AddItems from "../../../../assets/images/icons/icon-add-item-1.png";
import ShareUsers from "../../../../assets/images/icons/icon-add-users-1.png";
import NewIcon from "../../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import { customDateRange } from "../../../../config/constants";

const WorkBooksDetails = () => {
  const { user } = useApp();
  const { workspaceName, workspaceId } = useParams();
  const navigate = useNavigate();
  const [workSpaceParams, setWorkSpaceParams] = useState({});
  const [selectedDays, setSelectedDays] = useState<any>(null);
  useEffect(() => {
    setWorkSpaceParams({ workspaceId });
  }, [user?.userId, workspaceId]);

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod] = value?.value;
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod });
      }
      else {
        setSelectedDays({ timePeriod });
      }
    },
    [setSelectedDays]
  );

  const {
    data: workspacesData,
    isLoading: isWorkBooksLoading,
    isSuccess,
  } = useFetchWorkSpaceDetails(workSpaceParams);

  const {
    state: { isWorkBookShow, isWorkSpaceAddItemShow, isShareWorkbenchShow },
    handleAddWorkBook,
    onHandleWorkSpaceItems,
    onHandleShareWorkBench,
    userTreeDataSource,
    syncTreeViewSelection,
    treeViewRender,
    shareWorkBenchData,
  } = useWorkBench();

  const [newWorkBookData, setNewWorkBookData] = useState<WorkBookData>({
    ...newWorkBookFormData,
  });

  const finalWorkBookData = getTransformedWorkbooks(
    workspacesData?.data || [],
    navigate
  );

  const projectDetailsData = projectIdsString(workspacesData || []);

  const { data: projectData = [], isLoading } =
    useFetchProjectInfo(projectDetailsData);
  const { data: recentActivityData = [] } =
    useFetchRecentActivityInfo(projectDetailsData);

  const {
    mutate: mutateWorkbook,
    isLoading: isWorkbookLoading,
    isSuccess: isSuccessWorkBook,
  } = useCreateWorkBook(handleAddWorkBook);
  const updateWorkbookField = useCallback(
    (field: string) => (value: any) => {
      setNewWorkBookData({ ...newWorkBookData, [field]: value });
    },
    [newWorkBookData]
  );

  const combinedWorkspacesData = useMemo(() => {
    const regularSharedWorkspace =
      finalWorkBookData?.map((item: any) => ({
        ...item,
        imageIcon: WorkbookIcon,
      })) || [];
    return [...regularSharedWorkspace];
  }, [finalWorkBookData]);

  const handleSaveSumbitWorkBook = useCallback(() => {
    mutateWorkbook({
      ...newWorkBookData,
      createdBy: user?.userId,
      createdName: user?.name,
      workspaceId: workspaceId,
    });
  }, [mutateWorkbook, newWorkBookData, user?.name, user?.userId, workspaceId]);

  const WorkBooksProps = useMemo(() => {
    return {
      data: combinedWorkspacesData,
      message: `Click "${"New Workbook"}" to create your first workbook.`,
      isLoading: isWorkBooksLoading,
      sidepanelProps: {
        title: "New Workbook",
        Component: AddNewWorkBook,
        componentProps: {
          isOpened: isWorkBookShow,
          changePanelOpened: handleAddWorkBook,
          workspaceId,
          onSave: handleSaveSumbitWorkBook,
          newWorkBookData,
          updateField: updateWorkbookField,
          isSuccessWorkBook,
        },
        isLoadingButton: isWorkbookLoading,
        isWorkBookShow,
        handleAddWorkBook,
      },
    };
  }, [
    combinedWorkspacesData,
    handleAddWorkBook,
    handleSaveSumbitWorkBook,
    isSuccessWorkBook,
    isWorkBookShow,
    isWorkBooksLoading,
    isWorkbookLoading,
    newWorkBookData,
    updateWorkbookField,
    workspaceId,
  ]);

  const projectDataProps = useMemo(() => {
    return {
      gridData: projectData,
      isLoading: isWorkBooksLoading,
      showToolbar: false,
      showExtrenalDataSource: true,
      height: "height-small",
    };
  }, [isWorkBooksLoading, projectData]);

  const recentActivityDataProps = useMemo(() => {
    return {
      dataSource: recentActivityData,
      externalDataSource: true,
      isLoading: isWorkBooksLoading,
      showToolbar: false,
      height: "height-small",
      selectedDays : selectedDays, 
      onCurrentValueChange : onCurrentValueChange
    };
  }, [isWorkBooksLoading, recentActivityData, selectedDays, onCurrentValueChange]);

  const handleBackButton = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const additionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        cssClass="toolbar-item-flex toolbar-item-label-buton"
      >
        <PSIconText
          src={AddItems}
          text={"Add Items"}
          alt="Add Items"
          onClick={onHandleWorkSpaceItems}
        />
        <PSIconText
          src={NewIcon}
          alt="add workbook"
          onClick={handleAddWorkBook}
          text="Add Workbook"
        />
        <PSIconText
          src={ShareUsers}
          alt="Share item with users"
          onClick={onHandleShareWorkBench}
          text="Share"
        />
      </Item>
    );
  }, [handleAddWorkBook, onHandleShareWorkBench, onHandleWorkSpaceItems]);

  const additionalToolbarBeforeContent = useMemo(() => {
    return (
      <Item location="before" locateInMenu="auto" cssClass="toolbar-item-flex">
        <PsButton
          icon="arrowleft"
          mode="text"
          width={"auto"}
          onClick={handleBackButton}
        />
      </Item>
    );
  }, [handleBackButton]);

  const cardDetails = useMemo(
    () => [
      {
        title: "Workbooks",
        Component: WorkbenchCards,
        componentProps: WorkBooksProps,
        componentId: "workbooks-card",
        hasCard: isSuccess,
      },
    ],
    [WorkBooksProps, isSuccess]
  );

  const WorkbenchLayoutProps = useMemo(() => {
    const rowTitles: any = (
      <>
        <span>Workspace</span>
        <span style={{ fontSize: "16px", marginLeft: "15px" }}>
          {workspaceName}
        </span>
      </>
    );

    return {
      headerText: rowTitles,
      additionalToolbarContent,
      additionalToolbarBeforeContent,
      cardDetails,
      activityData: {},
      isLoading: isLoading,
    };
  }, [
    additionalToolbarBeforeContent,
    additionalToolbarContent,
    cardDetails,
    isLoading,
    workspaceName,
  ]);

  const AssignWorkSpaceItems = useMemo(
    () => ({
      isWorkSpaceAddItemShow: isWorkSpaceAddItemShow,
      changePanelOpened: onHandleWorkSpaceItems,
      workspaceId,
      workspaceName,
    }),
    [isWorkSpaceAddItemShow, onHandleWorkSpaceItems, workspaceId, workspaceName]
  );

  const ShareAccessProps = useMemo(
    () => ({
      isShareWorkbenchShow: isShareWorkbenchShow,
      changePanelOpened: onHandleShareWorkBench,
      userTreeDataSource,
      syncTreeViewSelection,
      treeViewRender,
      shareWorkBenchData,
      workspaceId,
      workspaceName,
      assetType: "workspace",
      assetId: workspaceId,
      assetName: workspaceName,
    }),
    [
      isShareWorkbenchShow,
      onHandleShareWorkBench,
      userTreeDataSource,
      syncTreeViewSelection,
      treeViewRender,
      shareWorkBenchData,
      workspaceId,
      workspaceName,
    ]
  );

  const pageBodyComponent = () => [
    {
      title: "Project Details",
      Component: Projects,
      componentProps: projectDataProps,
      pageRoute: "/projects",
      reloadParams: "fetch-project-clientInfo",
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: recentActivityDataProps,
      pageRoute: "/activity-dashboard",
      reloadParams: "fetch-recent-activity",
    },
  ];

  const LayoutProps = {
    showToolbar: false,
    pageBodyComponent: pageBodyComponent(),
  };

  return (
    <>
      <WorkbenchLayout {...WorkbenchLayoutProps} />
      <DataGridTitleCardLayout {...LayoutProps} />
      <AssignWorkSpaceForm {...AssignWorkSpaceItems} />
      <ShareWorkbenchAccess {...ShareAccessProps} />
    </>
  );
};

export default WorkBooksDetails;
