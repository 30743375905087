import PsSkeleton from "../../skeleton/PsSkeleton";

const TableSkeleton = () => {
  return (
    <div style={{backgroundColor:"white"}}>
      <table className="table-skeleton">
        <thead>
          <tr>
            <th>
              <PsSkeleton height={30} />
            </th>
            <th>
              <PsSkeleton height={30} />
            </th>
            <th>
              <PsSkeleton height={30} />
            </th>
            <th>
              <PsSkeleton height={30} />
            </th>
            <th>
              <PsSkeleton height={30} />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array(10)
            .fill(null)
            .map((_, index) => (
              <tr key={index}>
                <td>
                  <PsSkeleton height={40} />
                </td>
                <td>
                  <PsSkeleton height={40} />
                </td>
                <td>
                  <PsSkeleton height={40} />
                </td>
                <td>
                  <PsSkeleton height={40} />
                </td>
                <td>
                  <PsSkeleton height={40} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
