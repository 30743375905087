import { useCallback, useState } from "react";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { OperationsData } from "../../../../../../types/operations-add";
import { newOperationsFormData } from "../../../../../../config/constants";
import { useCreateOperations } from "../../../../../../hooks/finops/useRatecard";
import { OperationsNewForm } from "../../../../../../components/finops/library/operations-form/OperationsNewForm";

export const OperationsFormPopup = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;

  const [newOperationsData, setNewOperationsData] = useState<OperationsData>({
    ...newOperationsFormData,
  });

  const updateField = (field: string) => (value: any) => {
    setNewOperationsData({ ...newOperationsData, ...{ [field]: value } });
  };

  const { mutate,isLoading } = useCreateOperations(changePopupVisibility);

  const handleFormSubmit = useCallback(() => {
      mutate(newOperationsData);
  }, [mutate, newOperationsData]);

  if(typeof window === 'undefined' ) return null;
  return (
    <FormPopup
      title="Add New Operations Role"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <OperationsNewForm
        newOperationsData={newOperationsData}
        updateField={updateField}    
      />
    </FormPopup>
  );
};
