import { useState, useCallback, useMemo, useEffect } from "react";
import { DropDownButton } from "devextreme-react/drop-down-button";
import "../../../../../../styles/client/opexStyles.scss";
import {
  dataConfig,
  documnetconfig,
  opexPivotViewData,
  documenntData,
} from "../../../../../../config/client/commonConfig";
import { useReportsInfo } from "../../../../../../hooks/datastudio/useDocumentCatalog";
import FileViewer from "../../../../../../components/ui-components/fileViewer/FileViewer";
import { DataSourceProps } from "../../../../../../components/ui-components/pivotgridWithCart/types";
import { dropDownOptions } from "../../../../../../config/constants";
import { useFetchSelectedClientFileData } from "../../../../../../hooks/client/useClientProfile";
import { useFetchMetricsDataApi } from "../../../../../../hooks/activity-monitor/useLogsMetrics";
import PivotWithChart from "../../../../../../components/ui-components/pivotgridWithCart/PivotGridWithChart";
import { GTAButtonEventHandler } from "../../../../../../utlis/GAT-event-handler";

const OpexPivotGrid = ({
  successData,
  failedData,
  urlsData,
  propertyData, 
  projectType,
  clientId,
  isSuccess,
  rejectedData,
  pendingData,
  completedData,
  reviewData,
  inProgressData,
  
}: any) => {
  const [pivotChartView, setPivotChartView] = useState<string>("");
  const [dropdownItems, setDropdownItems] = useState<any[]>([]); 
  const [openPopup, setOpenPopup] = useState(false); 
  const [title, setTitle] = useState<any>(""); 

  const [fileViewerParams, setFileViewerParams] = useState<any>({});

  const { data: fileViewerData, isLoading: isFileLoading, refetch } =
    useFetchSelectedClientFileData(fileViewerParams);

  const { data: metricsinfo, refetch: refetchMetrics, isLoading: isMetricsLoading } = useFetchMetricsDataApi({
    clientId,
    ...fileViewerParams,
  });

  const [selectedDays, setSelectedDays] = useState<{ startTime: string; endTime: string; status?: string; clientId?: string; ProjectId?: string; taskId?: string; processType?: string }>({ startTime: '', endTime: '' });

  const { data: catalogData, isLoading: isCatalogLoading } = useReportsInfo(selectedDays);

 


  const filterByPivotProjectView = useCallback((e: any) => {
    const { item } = e;
    setPivotChartView(item?.name);
    const eventName = `Pivot Project View: ${item?.name} - Click`;
    GTAButtonEventHandler(eventName);
  }, []);

  const dataToDisplay = useMemo(() => {
    const dataMapping: { [key: string]: any } = {
      propertyData,  
      urlsData,
      successData,
      failedData,
      rejectedData,
      pendingData,
      completedData,
      reviewData,
      inProgressData,
    };

    const dataKey = dataConfig[pivotChartView] || documnetconfig[pivotChartView];
    const selectedData = dataMapping[dataKey] || propertyData;

      return selectedData?.map((item: any) => ({
        ...item,
        executionId: item?.executionId ? `exec-${item.executionId}` : '',
      }));

  }, [
    pivotChartView,
    successData,
    failedData,
    urlsData,
    propertyData,
    rejectedData,
    pendingData,
    completedData,
    reviewData,
    inProgressData,
  ]);

  const customizeTooltip = useCallback(
    (args: { originalValue: number | bigint; seriesName: any }) => {
      const valueText = args?.originalValue?.toLocaleString("en-US");
      return {
        html: `${args?.seriesName} | Total<div class="currency">${valueText}</div>`,
      };
    },
    []
  );

  const handleCellClick = useCallback(
    async (e: any) => {
      const eventName = `Pivot Grid: ${pivotChartView} - Cell Click`;
      GTAButtonEventHandler(eventName);
      if (e?.area === "data") {
        const urlCount = Number(e?.cell?.text);
        const projectName = e?.cell?.rowPath?.[0];
        const taskName = e?.cell?.rowPath?.[1];
        const taskDate = e?.cell?.columnPath?.[3]; 
  
        let matchedProject: any;
        let matchedProjects: any[] = [];
  
        if (pivotChartView === "URLs Processed") {
          matchedProject = urlsData?.find((item: any) => {
            return (
              item?.count === urlCount &&
              item?.projectName === projectName &&
              item?.taskName === taskName &&
              item?.executionDate === taskDate
            );
          });
  
          if (matchedProject && matchedProject?.taskId) {
            setFileViewerParams({
              projectId: matchedProject?.projectId,
              filename: `${matchedProject?.taskId}.json`,
              type: matchedProject?.type,
              fileExtension: "json",
            });
  
            const rowTitle = { file: `${projectName}-${taskName}` };
            setTitle(rowTitle);
            setOpenPopup(true);
          }
        } else if (pivotChartView === "Success Executions") {
          matchedProject = successData?.find((item: any) => {
            return (
              item?.count === urlCount &&
              item?.projectName === projectName &&
              item?.taskName === taskName &&
              item?.executionDate === taskDate
            );
          });
  
          if (matchedProject && matchedProject?.projectId && matchedProject?.executionId) {
            setFileViewerParams({
              clientId: clientId,
              projectId: matchedProject?.projectId,
              executionId: matchedProject?.executionId,
              type: "logMetricsPath",
              fileExtension: "json",
            });
  
            const rowTitle = { file: `${projectName}-${taskName}` };
            setTitle(rowTitle);
            await refetchMetrics();
            setOpenPopup(true);
          }
        } else if (pivotChartView === "Failed Executions") {
          matchedProject = failedData?.find((item: any) => {
            return (
              item?.count === urlCount &&
              item?.projectName === projectName &&
              item?.taskName === taskName &&
              item?.executionDate === taskDate
            );
          });
  
          if (matchedProject && matchedProject?.projectId && matchedProject?.executionId) {
            setFileViewerParams({
              clientId: clientId,
              projectId: matchedProject?.projectId,
              executionId: matchedProject?.executionId,
              type: "logMetricsPath",
              fileExtension: "json",
            });
  
            const rowTitle = { file: `${projectName}-${taskName}` };
            setTitle(rowTitle);
            await refetchMetrics();
            setOpenPopup(true);
          }
        } else if (pivotChartView === "Data Processed") {
          matchedProject = propertyData?.find((item: any) => {
            return (
              item?.count === urlCount &&
              item?.projectName === projectName &&
              item?.taskName === taskName &&
              item?.executionDate === taskDate
            );
          });
  
          if (matchedProject && matchedProject?.projectId && matchedProject?.executionId) {
            setFileViewerParams({
              projectId: matchedProject?.projectId,
              filename: `${matchedProject?.executionId}.json`,
              type: matchedProject?.type,
              fileExtension: "json",
            });
  
            const rowTitle = { file: `${projectName}-${taskName}` };
            setTitle(rowTitle);
            setOpenPopup(true);
          }
        } else if (
          ["Pending", "Completed", "Rejected", "Ready For Review", "Failed", "In Progress"].includes(
            pivotChartView
          )
        ) {


          matchedProjects = (
            {
              Pending: pendingData,
              Completed: completedData,
              Rejected: rejectedData,
              "Ready For Review": reviewData,
              Failed: failedData,
              "In Progress": inProgressData,
            }[pivotChartView] || []
          ).filter((item: any) => {
            return item?.projectName === projectName && item?.taskName === taskName;
          });
  
          if (matchedProjects.length) {
            if (taskDate) {
              matchedProject = matchedProjects.find(
                (item: any) => item?.executionDate === taskDate
              );
              const processType = "Document Catalog";

  
              if (matchedProject) {
                setSelectedDays({
                  clientId: clientId,
                  startTime: matchedProject?.executionDate, 
                  endTime: matchedProject?.executionDate,
                  ProjectId: matchedProject?.projectId,
                  taskId: matchedProject?.taskId,
                  status: matchedProject?.status,
                  processType: processType,
                });
  
                const rowTitle = { file: `${projectName}-${taskName}` };
                setTitle(rowTitle);
                setOpenPopup(true);
              }
            } else {
              matchedProjects.sort(
                (a: any, b: any) =>
                  new Date(a.executionDate).getTime() - new Date(b.executionDate).getTime()
              );
  
              const startTime = matchedProjects[0]?.executionDate; 
              const endTime = matchedProjects[matchedProjects.length - 1]?.executionDate; 
  
              setSelectedDays({
                clientId: clientId,
                startTime: startTime,
                endTime: endTime,
                ProjectId: matchedProjects[0]?.projectId,
                taskId: matchedProjects[0]?.taskId,
                status: matchedProjects[0]?.status,
              });
  
              const rowTitle = { file: `${projectName}-${taskName}`};
              setTitle(rowTitle);
              setOpenPopup(true);
            }
          }
        }
      }
    },
    [
      clientId,
      urlsData,
      successData,
      failedData,
      propertyData,
      rejectedData,
      pendingData,
      reviewData,
      completedData,
      inProgressData,
      pivotChartView,
      refetchMetrics,
      setSelectedDays,
    ]
  );
  

  useEffect(() => {
    refetch();
  }, [fileViewerParams, refetch]);

  const closePopupException = () => {
    setOpenPopup(false);
  };



  
  

  const pivotChartProps = useMemo(() => {
    const dataSource: DataSourceProps = {
      fields: [
        { caption: "Project", dataField: "projectName", area: "row" },
        { caption: "Task", dataField: "taskName", area: "row" },
        { caption: "Execution Id", dataField: "executionId", area: "row" },
        { dataField: "date", dataType: "date", area: "column" },
        { caption: "Project Type", dataField: "projectType" },
        {
          caption: "executionDate",
          dataField: "executionDate",
          area: "column",
        },
        {
          dataField: "count",
          dataType: "number",
          summaryType: "sum",
          area: "data",
        },
      ],
      store: dataToDisplay,
    };

    

    return {
      id: "pivotGrid",
      dataSource,
      customizeTooltip,
      onCellClick: handleCellClick,
    };
  }, [dataToDisplay, customizeTooltip, handleCellClick]);

  useEffect(() => {
    if (projectType === "web scraping") {
      setDropdownItems(opexPivotViewData || []);
      setPivotChartView("Data Processed");
    } else if (projectType === "document processing") {
      setDropdownItems(documenntData || []);
      setPivotChartView("Completed");
    }
  }, [projectType]);

  return (
    <div>
      <div className="pivotGrid-Drop-down">
        <label htmlFor="pivotViewDropdown" className="pivotGrid-label">
          Select Data to Analyze:
        </label>
        <DropDownButton
          items={dropdownItems}
          stylingMode="text"
          displayExpr={"name"}
          text={pivotChartView}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByPivotProjectView}
        />
      </div>
      <PivotWithChart {...pivotChartProps} />
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={(() => {
          if (pivotChartView === "Success Executions") {
            return JSON.stringify(
              metricsinfo?.data?.filter((item: any) => item?.process_result === "success") || []
            );
          } else if (pivotChartView === "Failed Executions") {
            return JSON.stringify(
              metricsinfo?.data?.filter((item: any) => item?.process_result === "fail") || []
            );
          } else if (
            ["Ready For Review", "Pending", "Rejected", "Completed","Failed","In Progress"].includes(pivotChartView)
          ) {
            return JSON.stringify(catalogData);
          } else {
            return JSON.stringify(fileViewerData?.data);
          }
        })()}
        title={title}
        isLoading={isFileLoading || isMetricsLoading || isCatalogLoading}
        extensionType={"json"}
        type={projectType}
      />

    </div>
  );
};

export default OpexPivotGrid;
