import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createProjectApi,
  updateProjectApi,
  fetchTechnicalResouceDetailsApi,
  fetchOperationsResouceDetailsApi,
  fetchSpecificProjectEditDetailsApi,
  fetchProjectTypeApi,
  fetchRecentProjectApi,
  fetchManagementApi,
  fetchProjectApi,
  fetchManageApi,
  fetchProjectData,
  fetchYTDMTDCostApi,
  fetchSepecificProjectApi,
  fetchopexCardDataApi,
} from "../../service/client/api-service";
import { useCallback, useEffect, useReducer, useState } from "react";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { ProjectState } from "../../pages/client/projects/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { useHelperHooks } from "../helper/utils";
import { replaceQueryUrl } from "../../utlis/helper";
import { GTAButtonEventHandler } from "../../utlis/GAT-event-handler";

const initialState: ProjectState = {
  showProjectModal: false,
  popupVisible: false,
  editPopupVisible: false,
  selectedProject: null,
  selectedProjectType: "",
  projetView: "gridView",
};

const reducer = (state: ProjectState, action: any) => {
  switch (action.type) {
    case "SHOW_PROJECT_MODAL":
      return {
        ...state,
        showProjectModal: action.payload,
      };
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload,
      };
    case "SELECTED_PROJECT":
      return {
        ...state,
        selectedProject: action.payload,
      };
    case "SELECTED_PROJECT_TYPE":
      return {
        ...state,
        selectedProjectType: action.payload,
      };
    case "PROJETVIEW":
      return {
        ...state,
        projetView: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useProjects = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentView = useHelperHooks().getQueryParams("view");
  useEffect
  (() => {
    if (currentView) {
      dispatch({ type: "PROJETVIEW", payload: currentView });
    }
  }, [currentView]);

  const onToggleProjectClick = useCallback(
    (e?: any) => {
      const { name } = e || {};
      dispatch({ type: "SELECTED_PROJECT_TYPE", payload: name });
      dispatch({
        type: "SHOW_PROJECT_MODAL",
        payload: !state.showProjectModal,
      });
    },
    [state.showProjectModal]
  );

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectProject = (data: any) => {
    dispatch({ type: "SELECTED_PROJECT", payload: data });
  };

  const onEditProjectClick = useCallback((data: any) => {
    handleSelectProject(data);
    dispatch({ type: "SELECTED_PROJECT", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "SELECTED_PROJECT", payload: "" });
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: false });
    },
    []
  );

  const filterByProjectView = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: view }: { item: any } = e;
      dispatch({ type: "PROJETVIEW", payload: view.value });
      replaceQueryUrl('view', view.value);
      const eventName = view.name === "gridView" ? "Grid View" : "Project View";
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: view.name,
      });  
    },
    []
  );

  return {
    state,
    onToggleProjectClick,
    changePopupVisibility,
    onEditProjectClick,
    changeEditPopupVisibility,
    filterByProjectView,
  };
};

export const useFetchTechnicalResouce = () => {
  return useQuery("fetch-tech-resource", fetchTechnicalResouceDetailsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; 

export const useFetchOperationsResouce = () => {
  return useQuery(
    "fetch-operations-resource",
    fetchOperationsResouceDetailsApi,
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchRecentProjects = () => {
  const recent = "True";
  const count = 5;

  return useQuery(
    ["fetch-recentproject"],
    () => fetchRecentProjectApi(recent, count),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useMangaProject = () => {
  const viewType = "managementview";

  return useQuery(["fetch-manageproject"], () => fetchManagementApi(viewType), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateProject = (
  toggleProjectModal?: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const [responseData, setResponseData] = useState<any>(null);

  const projectfunc = isEdit ? updateProjectApi : createProjectApi;

  const mutation = useMutation(projectfunc, {
    onSuccess: (data) => {
      toggleProjectModal && toggleProjectModal();
      fnQueryClient.invalidateQueries("fetch-project");
      fnQueryClient.invalidateQueries("fetch-clients-name");
      fnQueryClient.invalidateQueries("fetch-clients");
      setResponseData(data);
      const message = isEdit ? "Project Updated Successfully" : "Project Added Succefully";
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
  return {
    ...mutation,
    responseData,
  };
};

export const useFetchProjectClientInfo = (clientId: any) => {
  return useQuery(
    ["fetch-project-clientInfo", clientId],
    () => fetchProjectApi(clientId),
    {
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchSpecificProjectDetails = (projectId: string) => {
  return useQuery(
    ["fetch-project-byId", projectId],
    () => fetchSpecificProjectEditDetailsApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchProjectType = (func_type: any) => {
  return useQuery(
    ["fetch-project-type", func_type],
    () => fetchProjectTypeApi(func_type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useManageInfo = (projectId: any, viewtype: any) => {
  return useQuery(
    ["fetch-manageInfo", projectId, viewtype],
    () => fetchManageApi(projectId, viewtype),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchClientDocument(
  activitytype: string,
  process: string,
  file: string
) {
  const queryParams = {
    activitytype: activitytype,
    process: process,
    filename: file,
  };
  return useQuery(
    ["fetch-client-document", queryParams],
    () => fetchProjectData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!activitytype && !!process && !!file,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchYTDMTDCost(projectId: string) {
  const frequency = "YTD,MTD";
  const queryParams = {
    projectId: projectId,
    frequency: frequency,
  };
  return useQuery(
    ["fetch-ytdmtd-cost", queryParams],
    () => fetchYTDMTDCostApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchProjectInfo = (params: any, activityType: string) => {
  const { projectId, projectName, clientName } = params;
  return useQuery(
    ["fetch-projectInfo", params, activityType],
    () =>
      fetchSepecificProjectApi(
        projectId,
        activityType,
        projectName,
        clientName
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!activityType && !!projectName && !!clientName,
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchopexCard = (clientId: any) => {
  return useQuery(
    ["fetch-opex-card", clientId],
    () => fetchopexCardDataApi(clientId),
    {
      refetchOnWindowFocus: false,

      select: (data: any) => {
        return data;

      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
