import DataGrid, {
  Column,
  Paging,
  MasterDetail,
  Scrolling,
  Pager,
  HeaderFilter,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CustomItem } from "./customManager";
import { CustomTitle } from "./customManager";
import PsAccordion from "../../../../../../components/ui-components/accordion/accordion";
import { useManageInfo } from "../../../../../../hooks/client/useProject";
import { ContactStatus } from "../../../../../../components/ui-components/contact-status/ContactStatus";
import { cellStartTime } from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../../config/client/commonConfig";
import { GTAButtonEventHandler } from "../../../../../../utlis/GAT-event-handler";

const ManagementPage = (props: any) => {
  const { data, gridRef } = props;
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [activityType, setActivityType] = useState("");
  const navigate = useNavigate();
  const {
    data: detailData,
    isLoading: isDetailLoading,
  }: { data: any; isLoading: boolean } = useManageInfo(
    expandedRowId,
    activityType
  );
  const handleProjectLinkClick = (event: any, projectId: string) => {
    navigate(`/project-profile?projectId=${projectId}`);
    event.preventDefault();
    const eventName = `Project Profile: ${projectId}-managementview`;
    GTAButtonEventHandler(eventName);
  };

  const detailRenderTask = () => {
    try {
      if (isDetailLoading) {
        return <div>Loading...</div>;
      }
      const departments = detailData?.resources?.[0]?.departments || [];
      if (!departments || departments.length === 0) {
        return <div>Cost Data will be displayed here</div>;
      }

      return (
        <PsAccordion
          dataSource={departments}
          Component={CustomItem}
          CustomTitle={CustomTitle}
        />
      );
    } catch (error) {
      console.error("Error while rendering departments:", error);
      return;
    }
  };

  const handleRowExpanding = (e: any) => {
    try {
      const viewType = "managementview";
      const { projectId } = e.key;
      setExpandedRowId(projectId);
      setActivityType(viewType);
      const eventName = `Project Row Expand: ${projectId}-managementview`;
      GTAButtonEventHandler(eventName);
    } catch (error) {
      console.error("Error while handling row expanding:", error);
    }
  };

  return (
    <div className="recent-activity-grid">
      <DataGrid
        className="grid theme-dependent height-large"
        dataSource={data}
        noDataText={"No Projects to display at this time"}
        allowColumnReordering={true}
        showBorders={true}
        onRowExpanding={handleRowExpanding}
        ref={gridRef}
        allowColumnResizing={true}
      >
        <SearchPanel visible={true} width={200} />
        <HeaderFilter visible={true} />
        <Column
          dataField="projectName"
          caption="Project Name"
          cellRender={(data) => {
            const projectName = data?.data?.projectName;
            const projectId = data?.data?.projectId;
            return (
              <a href="*" onClick={(e) => handleProjectLinkClick(e, projectId)}>
                {projectName}
              </a>
            );
          }}
        />
        <Column dataField="projectDescription" />
        <Column dataField="projectType" />
        <Column
          dataField="status"
          caption="status"
          cellRender={ContactStatus}
        />
        <Column
          dataField="startDate"
          caption="Start Date"
          dataType="string"
          cellRender={cellStartTime}
        />
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <MasterDetail enabled={true} render={detailRenderTask}></MasterDetail>
      </DataGrid>
    </div>
  );
};

export default ManagementPage;
