import React, { useReducer, useEffect } from "react";
import { DropDownButton } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { initialState } from "./types";
import { dropDownOptions } from "../../../../config/constants";
import { ToolbarAnalytics } from "../../../../components/ui-components/toolbar-analytics";
import "../../../../styles/activity-monitor/filterToolbar.scss";

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_CLIENT":
      return {
        ...state,
        currentClient: action?.payload,
        currentProject: null,
        currentTask: null,
        currentExecution: null,
        currentPdf: null,
      };
    case "SET_PROJECT":
      return {
        ...state,
        currentProject: action?.payload,
        currentTask: null,
        currentExecution: null,
        currentPdf: null,
      };
    case "SET_TASK":
      return {
        ...state,
        currentTask: action?.payload,
        currentExecution: null,
        currentPdf: null,
      };
    case "SET_EXECUTION":
      return { ...state, currentExecution: action?.payload, currentPdf: null };
    case "SET_PDF":
      return { ...state, currentPdf: action?.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export const LogsAndMetricsFilter = (props: any) => {
  const {
    clientDropDownData,
    filterByClientData,
    filterByProject,
    filterByTask,
    filterByExecutions,
    filterByPdf,
    projectData,
    taskData,
    executionData,
    pdfData,
    selectedClient,
    selectedProject,
    selectedTask,
    selectedExecution,
    selectedPdf,
    projectType,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (selectedClient !== state?.currentClient) {
      dispatch({ type: "SET_CLIENT", payload: selectedClient });
    } else if (selectedProject !== state?.currentProject) {
      dispatch({ type: "SET_PROJECT", payload: selectedProject });
    } else if (selectedTask !== state?.currentTask) {
      dispatch({ type: "SET_TASK", payload: selectedTask });
    } else if (selectedExecution !== state?.currentExecution) {
      dispatch({ type: "SET_EXECUTION", payload: selectedExecution });
    } else if (selectedPdf !== state?.currentPdf) {
      dispatch({ type: "SET_PDF", payload: selectedPdf });
    }
  }, [
    selectedClient,
    selectedProject,
    selectedTask,
    selectedExecution,
    selectedPdf,
    state?.currentClient,
    state?.currentProject,
    state?.currentTask,
    state?.currentExecution,
    state?.currentPdf,
  ]);

  const handleClientChange = (e: any) => {
    dispatch({ type: "SET_CLIENT", payload: e?.itemData });
    filterByClientData(e);
  };

  const handleProjectChange = (e: any) => {
    dispatch({ type: "SET_PROJECT", payload: e?.itemData });
    filterByProject(e);
  };

  const handleTaskChange = (e: any) => {
    dispatch({ type: "SET_TASK", payload: e?.itemData });
    filterByTask(e);
  };

  const handleExecutionChange = (e: any) => {
    dispatch({ type: "SET_EXECUTION", payload: e?.item });
    filterByExecutions(e);
  };

  const handlePdfChange = (e: any) => {
    dispatch({ type: "SET_PDF", payload: e?.itemData });
    filterByPdf(e);
  };

  if (typeof window === "undefined") return null;

  const AdditionalToolbarBeforeContent = (
    <Item location="before" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={clientDropDownData || []}
        hoverStateEnabled
        displayExpr="name"
        dropDownOptions={dropDownOptions}
        text={
          state?.currentClient ? state?.currentClient?.clientName : "Clients"
        }
        useSelectMode
        onSelectionChanged={handleClientChange}
      />
      <DropDownButton
        stylingMode="text"
        displayExpr={"projectName"}
        text={
          state?.currentProject
            ? state?.currentProject?.projectName
            : "Projects"
        }
        dataSource={projectData || []}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={handleProjectChange}
      />
      <DropDownButton
        dataSource={taskData || []}
        displayExpr="taskName"
        stylingMode="text"
        dropDownOptions={dropDownOptions}
        onSelectionChanged={handleTaskChange}
        text={state?.currentTask ? state?.currentTask?.taskName : "Tasks"}
        useSelectMode
      />
      <DropDownButton
        dataSource={executionData || []}
        displayExpr="executionName"
        stylingMode="text"
        dropDownOptions={dropDownOptions}
        onSelectionChanged={handleExecutionChange}
        text={
          state?.currentExecution
            ? state?.currentExecution?.executionName
            : "Executions"
        }
        useSelectMode
      />
      {projectType === "Document" && (
        <DropDownButton
          dataSource={pdfData || []}
          displayExpr={"outputFileName"}
          stylingMode="text"
          dropDownOptions={dropDownOptions}
          onSelectionChanged={handlePdfChange}
          text={
            state?.currentPdf ? state?.currentPdf?.documentFile : "Select File"
          }
          useSelectMode
        />
      )}
    </Item>
  );

  return (
    <ToolbarAnalytics
      title={""}
      additionalToolbarBeforeContent={AdditionalToolbarBeforeContent}
    />
  );
};
