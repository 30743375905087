import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import Card from "../../../ui-components/card/card";
import { useFetchTaskDetails } from "../../../../hooks/task/useTask";

export const TaskInfo = () => {
  const { data: taskData, isFetching } = useFetchTaskDetails();

  if (isFetching) {
    return (
      <Card className="project-details-card">
        <PsSkeleton count={9} height={30} />
      </Card>
    );
  }

  return (
    <Card className="project-details-card">
      <div className="chart-task-column">
        {taskData?.length > 0 && (
          <>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>
                {"Task Name"}
              </span>
              <span
                className="value task-display-values"
                data-tooltip={taskData[0].taskName}
              >
                {taskData[0].taskName.slice(0, 50)}
                {taskData[0].taskName.length > 50 ? "..." : ""}
              </span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>
                {"Container Image"}
              </span>
              <span
                className="value task-display-values"
                data-tooltip={taskData[0].imageName}
              >
                {taskData[0].imageName.slice(0, 70)}
                {taskData[0].imageName.length > 70 ? "..." : ""}
              </span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>
                {"Container Instances"}
              </span>
              <span className="value">{taskData[0].maxContainers}</span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>
                {"Task Description"}
              </span>
              <span
                className="value task-display-values"
                data-tooltip={taskData[0].taskDescription}
              >
                {taskData[0].taskDescription.slice(0, 50)}
                {taskData[0].taskDescription.length > 50 ? "..." : ""}
              </span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>
                {"Schedule"}
              </span>
              <span className="value">{taskData[0].taskScheduling}</span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>
                {"Container Size"}
              </span>
              <span className="value">{taskData[0].containerSize}</span>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default TaskInfo;
