import "../../../../../../styles/client/portfolioStyles.scss";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { convertToUSD } from '../../../../../../utlis/helper';
import { MetricsTicker } from "../../../../../../components/ui-components/ticker-card/TickerCardNew";
import { useFetchRecentActivity, useFetchTotalCost } from "../../../../../../hooks/app/useApps";

const PortfolioRowCard = ({ selectedValue }: any) => {
  const { data: finopsData, isLoading: finopsLoading } =
  useFetchTotalCost(selectedValue);
  const { data: activityData, isLoading: isActLoading } =
    useFetchRecentActivity(selectedValue);
  const { data: totalCostData, isLoading: totalCostLoading } =
    useFetchTotalCost(selectedValue);

    
  const renderFinance = () => {
    try {
      if (finopsData?.totalCost) {
        const primaryValue = convertToUSD(finopsData.totalCost[0].value);
        
        const secondaryValue = finopsData?.totalCost
          .filter((finops: any) => finops?.name !== "Total")
          .map((finops: any) => ({
            name: finops?.name,
            value: convertToUSD(finops?.value),
            percentage: finops?.percentage,
            count: finops?.count
          }));
  
        return (
          <MetricsTicker 
            title="Financials" 
            icon="money" 
            primaryValue={primaryValue} 
            secondaryValue={secondaryValue}
          />                  
        );
      }
    } catch (err) {
      console.error("Error rendering Finance:", err);
    }
    return null;
  };
   
  const renderTeamActivity = () => {
    try {
      if (totalCostData?.totalCost) {
        const secondaryValue = totalCostData?.totalCost
          .filter((team: any) => team?.name !== "Total")
          .map((team: any) => ({
            name: team?.name,
            value: team?.count?.toString() || "0",
          }));
  
        
  
        return (
          <MetricsTicker 
            title="Resources" 
            icon="group" 
            secondaryValue={secondaryValue}
          />                  
        );
      }
    } catch (err) {
      console.error("Error rendering Team Activity:", err);
    }
    return null;
  };
  

  
  // const renderPerformance = () => {
  //   try {
  //     if (finopsData?.totalCost) {
  //       const secondaryValue = [
  //         { name: "URLs processed", value: "20" },
  //         { name: "PDFs processed", value: "15" },
  //       ];
  
  //       return (
  //         <MetricsTicker 
  //           title="Performance" 
  //           icon="datausage" 
  //           // primaryValue={primaryValue} 
  //           secondaryValue={secondaryValue}
  //         />                  
  //       );
  //     } 
  //   } catch (err) {
  //     console.error("Error rendering Performance:", err);
  //   }
  //   return null;
  // };

  // const renderMileStone = () => {
  //   try {
  //     if (finopsData?.totalCost) {
  //       const finance = {
  //         name: "Project Milestones",
  //         data: [
  //           { key: "Active", value: "20" },
  //           { key: "Inactive", value: "15" },
  //         ],
  //         image: image,
  //       };
  //       return <TileCard {...finance} />;
  //     } 
  //   } catch (err) {
  //     console.error("Error rendering MileStone:", err);
  //   }
  //   return null;
  // };


  const renderHealth = () => {
    try {
      if (activityData) {
        const statusCount = activityData?.reduce(
          (acc: { [x: string]: number }, task: { status: string }) => {
            const status = task?.status;
            acc[status] = (acc[status] || 0) + 1;
            return acc;
          },
          {}
        );
  
        const secondaryValue = Object?.keys(statusCount)?.map((key) => ({
          name: key,
          value: statusCount[key].toString(),
        }));
  
        if (secondaryValue.length !== 0) {
          return (
            <MetricsTicker 
              title="Activity Summary" 
              icon="description" 
              secondaryValue={secondaryValue} 
            />
          );
        }
      }
    } catch (err) {
      console.error("Error rendering Health:", err);
    }
    return null;
  };

  if (finopsLoading || isActLoading || totalCostLoading) {
    const skeletons = Array.from({ length: 3 }, (_, index) => (
      <PsSkeleton key={index} count={1} height={150} />
    ));
  
    return <div className="portfolio-row-card">{skeletons}</div>;
  }
  
  return (
    <div className="portfolio-row-card">
      {renderFinance()}
      {renderTeamActivity()}
      {/* {renderPerformance()}
      {renderMileStone()} */}
      {renderHealth()}
    </div>
  );
};

export default PortfolioRowCard;
