import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellStartTime,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import { onExporting } from "../../../../config/constants";
import { PskanbanBoard } from "../../kanban";
import { useState, useEffect } from "react";

export const recentStatus = ["In Progress", "Completed", "Failed"];

const RecentActivityBoardView = (props: any) => {
  const {
    onRowClick,
    data,
    PagerVisible,
    handleClientLinkClick,
    handleTaskLinkClick,
    handleProjectLinkClick,
    client,
    gridRef
  } = props;

  const [clickedListData, setClickedListData] = useState<any[]>([]);
  const [gridTitleStatus, setGridTitleStatus] = useState("");

  const processData = (data: any[]) => {
    const clientStatusCount: {
      [clientName: string]: {
        [status: string]: {
          clientId: string;
          priority: any;
          taskCounts: { [taskName: string]: number };
        };
      };
    } = {};

    const getPriority = (status: string) => {
      switch (status) {
        case "In Progress":
          return 3;
        case "Completed":
          return 2;
        case "Failed":
          return 4;
        default:
          return 0;
      }
    };

    data?.forEach((entry) => {
      const { clientName, status, clientId, taskName } = entry;
      if (!clientStatusCount[clientName]) {
        clientStatusCount[clientName] = {};
      }
      if (!clientStatusCount[clientName][status]) {
        clientStatusCount[clientName][status] = {
          clientId,
          priority: getPriority(status),
          taskCounts: {},
        };
      }

      if (!clientStatusCount[clientName][status]?.taskCounts[taskName]) {
        clientStatusCount[clientName][status].taskCounts[taskName] = 0;
      }
      clientStatusCount[clientName][status].taskCounts[taskName]++;
    });

    const result = [];
    for (const clientName in clientStatusCount) {
      for (const status in clientStatusCount[clientName]) {
        const { clientId, priority, taskCounts } =
          clientStatusCount[clientName][status];
        const formattedTaskCounts = Object?.entries(taskCounts)?.map(
          ([taskName, count]) => ({
            [taskName]: count,
          })
        );

        result?.push({
          clientName,
          status,
          clientId,
          priority,
          taskCounts: formattedTaskCounts,
        });
      }
    }
    return result;
  };

  useEffect(() => {
    setClickedListData([]);
  }, [client]);

  const clientFiltered = data?.filter((item: any) =>
    (client === 'All Clients' || item?.clientName === client)
  );
  const formattedData = processData(clientFiltered);

  const handleCardClick = (datas: any) => {
    const { status, clientId } = datas[0];
    const filteredData = data?.filter(
      (item: any) => item?.status === status && item?.clientId === clientId
    );
    setGridTitleStatus(status);
    setClickedListData(filteredData);
  };

  return (
    <>
      <PskanbanBoard
        status={recentStatus}
        data={formattedData}
        onCardClick={handleCardClick}
      />
      {clickedListData?.length > 0 && (
        <>
          <div className="t-gap recent-activity-grid">
            <h6>{gridTitleStatus}</h6>
            <DataGrid
              className="grid theme-dependent"
              noDataText={"No Recent Activity to display at this time"}
              height="100%"
              dataSource={clickedListData}
              onExporting={onExporting}
              allowColumnReordering
              showBorders
              onRowClick={onRowClick}
              ref={gridRef}
            >
              <HeaderFilter visible />
              <Sorting mode="multiple" />
              <Scrolling mode="virtual" />
              <Column
                dataField="clientName"
                caption="Client"
                cellRender={(data) => {
                  const clientName = data?.data?.clientName;
                  const clientId = data?.data?.clientId;
                  return (
                    <a
                      href="null"
                      onClick={(e) => handleClientLinkClick(e, clientId)}
                    >
                      {clientName}
                    </a>
                  );
                }}
              />
              <Column
                dataField="projectName"
                caption="Project Name"
                cellRender={(data) => {
                  const projectName = data?.data?.projectName;
                  const projectId = data?.data?.projectId;
                  return (
                    <a
                      href="null"
                      onClick={(e) => handleProjectLinkClick(e, projectId)}
                    >
                      {projectName}
                    </a>
                  );
                }}
              />
              <Column
                dataField="taskName"
                caption="Task Name"
                cellRender={(data) => {
                  const TaskName = data?.data?.taskName;
                  const taskId = data?.data?.taskId;
                  return (
                    <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                      {TaskName}
                    </a>
                  );
                }}
              />
              <Column
                dataField="executionId"
                caption="Execution ID"
                cellRender={(data) => {
                  const executionId = data?.data?.executionId;
                  const truncatedId = executionId ? executionId.substr(0, 10) : "";
                  return <>{truncatedId}</>;
                }}
              />
              <Column
                dataField="projectType"
                caption="Activity Type"
                dataType="string"
              />
              <Column
                dataField="status"
                caption="Status"
                dataType="string"
                cellRender={ContactStatus}
              />
              <Column
                dataField="timestamp"
                caption="Time Started"
                cellRender={cellStartTime}
              />
              <Column
                dataField="endTime"
                caption="Time Completed"
                cellRender={cellEndTime}
              />
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={25} />
              <Pager
                visible={PagerVisible}
                allowedPageSizes={allowedPageSizes}
                displayMode={"full"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
            </DataGrid>
          </div>
        </>
      )}
    </>
  );
};

export default RecentActivityBoardView;
