import { Popup } from "devextreme-react/popup";
import { useState } from "react";
import {
  useFetchExceptionData,
  useFetchExecutionDataList
} from "../../../../../../hooks/app/useApps";
import { DataSection } from "./dataSection";

const RenderTreeViewItem = (props: any) => {
  const {
    selectedExecution,
    clientName,
    moduleName,
    eventData,
    status,
    timeStamp,
    instanceName,
    containerGroupName,
    name,
    fileName,
  } = props;
  const { clientId, projectId, executionId } = selectedExecution || {};

  const { data: list, isLoading: isDataLoading } = useFetchExecutionDataList(executionId);
  const { data: exceptionData, isLoading: isExceptionLoading } = useFetchExceptionData(clientId, projectId, executionId);

  const [openPopup, setOpenPopup] = useState(false);

  const openPopupDataException = () => {
    setOpenPopup(true);
  };

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const renderStatus = (status: string) => {
    const text = status?.split(" ").join("");
    return (
      <span
        className={`status status-contact status-${text?.toLowerCase()}`}
      >{` (${text})`}</span>
    );
  };

  if (fileName === "data.json") {
    return (
      <>
        {isDataLoading ? (
          <div>Loading...</div>
        ) : list ? (
          <DataSection list={list} />
        ) : (
          <div>No data to display at this time.</div>
        )}
      </>
    );
  }
  if (fileName === "exceptionData.json") {
    const exceptionFromApi = exceptionData;

    return (
      <>
        <div onClick={openPopupDataException}>
          {isExceptionLoading ? (
            <div>Loading...</div>
          ) : exceptionFromApi && exceptionData?.moduleExceptions?.length > 0 ? (
            <>
              <div>
                <div>ExceptionData.json</div>
              </div>
              <Popup
                visible={openPopup}
                onHiding={closePopupException}
                dragEnabled={true}
                fullScreen={true}
                title={`Task Name: ${selectedExecution?.taskName} - Exception Data`}
                width={600}
                height={400}
              >
                  {exceptionData && exceptionData?.moduleExceptions?.length > 0 ? (
                    <div>
                      {exceptionData?.moduleExceptions?.map(
                        (exception: any, index: number) => (
                          <pre key={index}>{JSON?.stringify(exception, null, 2)}</pre>
                        )
                      )}
                    </div>
                  ) : (
                    <div>No exception to display at this time.</div>
                  )}
              </Popup>
            </>
          ) : (
            <div>No exception to display at this time.</div>
          )}
        </div>
      </>
    );
  }
  if (clientName) {
    return (
      <div className="telemetry-title">
        {clientName}
        {status && renderStatus(status)}
      </div>
    );
  } else if (moduleName) {
    return (
      <div className="telemetry-sub-title">
        {moduleName}
        {status && renderStatus(status)}
      </div>
    );
  } else if (eventData) {
    return <span>{`${eventData} (${timeStamp})`}</span>;
  }
  else if (containerGroupName){
    return <div className="telemetry-sub-title">{containerGroupName}</div>;
  } 
  else if (instanceName) {
    return <div className="telemetry-sub-title">{instanceName}</div>;
  } else if (name) {
    return <div className="telemetry-sub-title">{name}</div>;
  } else if (fileName) {
    return <span>{fileName}</span>;
  } else {
    return <div className="telemetry-sub-title">Execution Details</div>;
  }
};

export default RenderTreeViewItem;
