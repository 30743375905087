import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import {
  editCellStatusRender,
  cellComplexityRender,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { onExporting } from "../../../../config/constants";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { useNavigate } from "react-router-dom";
import { PskanbanBoard } from "../../../../components/core-component/kanban";
import { useState, useEffect } from "react";
import { MetricsTicker } from "../../../../components/ui-components/ticker-card/TickerCardNew";
import { keyToImageMap } from "../../../../utlis/image"
import { useFetchUrlSummary } from "../../../../hooks/client/useClientUrl";

export const taskStatuses = ["Pending", "Development", "QA", "Production"];

const UrlKanbanPage = (props: any) => {
  const { data, onEditUrlClick, onDeletePopupOpen, client, urlStatus, gridRef } = props;
  const [clickedListData, setClickedListData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [gridTitleStatus, setGridTitleStatus] = useState("Pending");

  const { data: summaryData } = useFetchUrlSummary();

  const handleClientLinkClick = (event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
  };

  const processData = (data: any[]) => {
    const clientStatusCount: {
      [key: string]: {
        [key: string]: { urlCount: number; clientId: string; priority: any };
      };
    } = {};

    data?.forEach((entry) => {
      const { clientName, status, clientId, priority } = entry;
      if (!clientStatusCount[clientName]) {
        clientStatusCount[clientName] = {};
      }
      if (!clientStatusCount[clientName][status]) {
        clientStatusCount[clientName][status] = {
          urlCount: 0,
          clientId,
          priority,
        };
      }
      clientStatusCount[clientName][status].urlCount += 1;
    });

    const result = [];
    for (const clientName in clientStatusCount) {
      for (const status in clientStatusCount[clientName]) {
        result.push({
          clientName,
          urlCount: clientStatusCount[clientName][status].urlCount,
          status,
          clientId: clientStatusCount[clientName][status].clientId,
          priority: clientStatusCount[clientName][status].priority,
        });
      }
    }

    return result;
  };

  useEffect(() => {
    const defaultStatus = "Pending";
    setGridTitleStatus(defaultStatus);
    const filteredData = data?.filter(
      (item: any) => item?.status === defaultStatus
    );
    setClickedListData(filteredData);
  }, [data]);

  const kanbanFiltered = data?.filter((item: any) =>
    (client === 'All Clients' || item?.clientName === client) &&
    (urlStatus === 'All Items' || item?.status === urlStatus)
  );
  const formtatedData = processData(kanbanFiltered);
  const gridData = data

  const handleTileCardClick = (data: any) => {
    setGridTitleStatus(data);
    const filteredData = gridData?.filter(
      (item: any) => item?.status === data
    );
    setClickedListData(filteredData);
  };

  const handleCardClick = (datas: any) => {
    const { status, clientId } = datas[0];
    const filteredData = data?.filter(
      (item: any) => item?.status === status && item?.clientId === clientId
    )
    setGridTitleStatus(status);
    setClickedListData(filteredData);
  };

  const groupAndTransformData = (data: any[]) => {
    const groupedData: { [key: string]: { title: string; data: any[]; icon: string } } = {};

    const cardFiltered = data?.filter((item: any) =>
      (client === 'All Clients' || item?.clientName === client) &&
      (urlStatus === 'All Items' || item?.urlcatalog.some((url: any) => url?.name === urlStatus))
    );

    cardFiltered?.forEach((client_urls: { clientName: string; urlcatalog: { name: any; value: any; }[] }) => {
      client_urls?.urlcatalog?.forEach((process: { name: any; value: any; }) => {
        const title = process?.name;
        if (!groupedData[title]) {
          groupedData[title] = {
            title,
            data: [],
            icon: keyToImageMap[title] || ''
          };
        }
        groupedData[title]?.data?.push({
          name: client_urls?.clientName,
          value: process?.value,
        });
      });
    });

    return Object.values(groupedData);
  };

  const transformedData = groupAndTransformData(summaryData || []);

  return (
    <>
      <div className="doc-row-card">
        {taskStatuses.map((status) => {
          const metric = transformedData.find((item) => item.title === status) || {
            title: status,
            data: [],
            icon: keyToImageMap[status] || '',
          };
          return (
            <MetricsTicker
              key={metric?.title}
              title={metric?.title}
              secondaryValue={metric?.data}
              icon={metric?.icon}
              onClick={() => handleTileCardClick(metric?.title)}
            />
          );
        })}
      </div>
      <PskanbanBoard
        status={taskStatuses}
        data={formtatedData}
        onCardClick={handleCardClick}
      />
      <br />
      <div className="t-gap recent-activity-grid">
        <h6>{gridTitleStatus}</h6>
        <DataGrid
          className="grid theme-dependent height-small"
          noDataText={"No urls to display at this time"}
          height="100%"
          dataSource={clickedListData}
          onExporting={onExporting}
          allowColumnReordering
          showBorders
          ref={gridRef}
          allowColumnResizing={true}
        >
          <HeaderFilter visible />
          <Sorting mode="multiple" />
          <Scrolling mode="virtual" />
          <Column
            dataField="clientName"
            caption="Client"
            cellRender={(data) => {
              const clientName = data?.data?.clientName;
              const clientId = data?.data?.clientId;
              return (
                <a
                  href="null"
                  onClick={(e) => handleClientLinkClick(e, clientId)}
                >
                  {clientName}
                </a>
              );
            }}
          />
          <Column
            dataField="url"
            caption="URL"
            cellRender={(data) =>
              data.value ? data.value.substring(0, 50) : ""
            }
          />
          <Column
            dataField="status"
            caption="Status"
            cellRender={ContactStatus}
            editCellRender={editCellStatusRender}
          />
          <Column
            dataField="group"
            caption="Group Name"
            width="10%"
          />
          <Column
            dataField="complexity"
            caption="Complexity"
            minWidth={150}
            cellRender={cellComplexityRender}
          />
          <Column dataField="note" caption="Notes" minWidth={150} />
          <Column
            dataField="url_template_path"
            caption="Template"
            minWidth={200}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating
            allowDeleting={true}
            key={"id"}
          />
          <Column caption="" type="buttons" minWidth={150}>
            <ActionsButton
              name="edit"
              onClick={onEditUrlClick}
              hint="Edit"
              cssClass="action-image"
            />
            <ActionsButton name="delete" onClick={onDeletePopupOpen} />
          </Column>
          <Scrolling rowRenderingMode="virtual"></Scrolling>
          <Paging defaultPageSize={25} />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
        </DataGrid>
      </div>
    </>
  );
};

export default UrlKanbanPage;
