import { useCallback, useRef } from "react";
import { SelectBox } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { useDocumentProcessingStateProvider } from "../../../hooks/datastudio/useDocumentProcessing";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import { useNavigate } from "react-router-dom";
import PsButton from "../../../components/ui-components/button/Button";
import PsSkeleton from "../../../components/ui-components/skeleton/PsSkeleton";
import { DocumentProcessingViewer } from "./helper-component/documentProcessingViewer/DocumentProcessingViewer";
import "../../../styles/datastudio/documentProcessing.scss";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useHelperHooks } from "../../../hooks/helper/utils";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import SaveIcon from "../../../assets/icons/icon-save-1.png";
import FinalizeIcon from "../../../assets/images/icons/finalize.png";
import RejectIcon from "../../../assets/images/icons/reject.png";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";

export const DocumentProcessing = () => {
  const {
    state: { selectedConfidence, pdfPage, confidenceValue },
    DocumentProcessingData,
    isDocumentProcessingDataLoading,
    refetch,
    handleSaveDocumentProcessing,
    handleFinalizeDocumentProcessing,
    handleRejectDocumentProcessing,
    handleApplyConfidence,
    fetchFunctionDropDownFeatures,
    setPdfPage
  } = useDocumentProcessingStateProvider();

  const confidenceSelectBoxRef = useRef<any>(null);

  const refetchs = () => {
    refetch();
    confidenceSelectBoxRef?.current?.instance?.reset();
  };

  const executionStatus = useHelperHooks()?.getQueryParams("executionStatus");
  const DocumentName = useHelperHooks()?.getQueryParams("DocumentName");

  const confidenceData = fetchFunctionDropDownFeatures?.confidenceDropdown || [];
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      selectionModePlugin: {
        selectionMode: SelectionMode?.Hand,
      },
    },
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const handlePageChange = useCallback((page: number) => {
    setPdfPage(page);
    jumpToPage(page - 1);
  }, [setPdfPage, jumpToPage]);

  const pdfData = DocumentProcessingData?.pdf_data;
  const documentData = DocumentProcessingData?.document_data;

  const commonComponents = (
    <>
      <SelectBox
        ref={confidenceSelectBoxRef}
        dataSource={confidenceData}
        defaultValue={confidenceValue}
        displayExpr="confidence"
        width={180}
        showClearButton={true}
        placeholder={"Select Confidence"}
        onValueChange={handleApplyConfidence}
      />
      <PSIconText
        text="Save"
        alt="save"
        onClick={handleSaveDocumentProcessing}
        src={SaveIcon}
      />
      <PSIconText
        text="Finalize"
        alt="finalize"
        onClick={handleFinalizeDocumentProcessing}
        src={FinalizeIcon}
      />
    </>
  );
  const statusComponents: { [key: string]: JSX.Element } = {
    'Completed': (
      <PSIconText
        text="Reject"
        alt="reject"
        src={RejectIcon}
        onClick={handleRejectDocumentProcessing}
      />
    ),
    'Ready for Review': commonComponents,
    'Rejected': commonComponents
  };
  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      {executionStatus && statusComponents[executionStatus] ? statusComponents[executionStatus] : null}
    </Item>
  );

  const AdditionalToolbarBeforeContent = executionStatus === 'Completed' || 'Ready for Review' || 'Pending' || 'Failed' ? (
    <Item location='before' locateInMenu="auto">
      <div style={{ marginLeft: '1px' }} >
        <PsButton icon='arrowleft' mode='text' width={'auto'} onClick={handleBackButton} />
      </div>
    </Item>
  ) : null;

  const title: any = (
    <span>Document Processing  <small>{DocumentName}  {executionStatus}</small></span>
  )

  const renderPdf = useCallback(() => {
    if (isDocumentProcessingDataLoading) {
      return <PsSkeleton count={1} height={800} width={'100%'} />;
    }
    if (pdfData) {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfData}
            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance]}
            initialPage={pdfPage - 1}
            defaultScale={SpecialZoomLevel?.PageFit}
          />
        </Worker>
      );
    }
    return null;
  }, [isDocumentProcessingDataLoading, pdfData, defaultLayoutPluginInstance, pageNavigationPluginInstance, pdfPage]);

  const renderAnalyzedData = useCallback(() => {
    if (documentData) {
      return (
        <DocumentProcessingViewer
          pageData={documentData}
          isLoading={isDocumentProcessingDataLoading}
          selectedConfidence={selectedConfidence}
          onPageChange={handlePageChange}
        />
      );
    }
  }, [documentData, isDocumentProcessingDataLoading, selectedConfidence, handlePageChange]);

  if (isDocumentProcessingDataLoading) {
    return <PsSkeleton count={1} height={"75vh"} width={"100vh"} />;
  }

  return (
    <>
      <ToolbarAnalytics
        additionalToolbarBeforeContent={AdditionalToolbarBeforeContent}
        title={title}
        refresh={refetchs}
        additionalToolbarContent={AdditionalToolbarContent}
      />
      <div className="document-processing-container">
        <div className="pdf-wrapper">{renderPdf()}</div>
        {executionStatus !== 'Pending' && executionStatus !== 'In Progress' && executionStatus !== 'Failed' && (
          <div className="data-wrapper">
            {renderAnalyzedData()}
          </div>
        )}
      </div>
    </>
  );
};

