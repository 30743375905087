import React, { useEffect, useState } from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ArgumentAxis,
  ValueAxis,
  Title,
  Legend,
  Tooltip
} from 'devextreme-react/chart';
import { convertToUSD } from "../../../../utlis/helper";
import Card from "../../../ui-components/card/card";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useFetchTotalCost } from "../../../../hooks/app/useApps";

export const ExpensesChartCard = ({ selectedDays }: any) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const { data: totalCost, isFetching } = useFetchTotalCost(selectedDays);

  useEffect(() => {
    if (totalCost?.client_info) {
      const data = totalCost.client_info.map((client: any) => ({
        clientName: client?.clientName,
        digitalServices: parseInt(client?.totalCost?.find((item: any) => item?.name === "Digital Services")?.value.replace('$', '')) || 0,
        operations: parseInt(client?.totalCost?.find((item: any) => item?.name === "Operations")?.value.replace('$', '')) || 0,
        infrastructure: parseInt(client?.totalCost?.find((item: any) => item?.name === "Infrastructure")?.value.replace('$', '')) || 0
      }));
      setChartData(data);
    }
  }, [totalCost]);

  if (isFetching) {
    return (
      <Card className="card_wrapper">
        <div className="chart-client-column">
          <PsSkeleton height={300} width={500} />
        </div>
      </Card>
    );
  }

  return (
    <Card className="card_wrapper">
      <div className="chart-client-column">
        <Chart
          id="expensesChart"
          dataSource={chartData}
          min-width={200}
          width={500}
          height={300}
          min-height={200}
        >
          <CommonSeriesSettings
            argumentField="clientName"
            type="bar"
            ignoreEmptyPoints={true}
          />
          <Series
            valueField="digitalServices"
            name="Digital Services"
          />
          <Series
            valueField="operations"
            name="Operations"
          />
          <Series
            valueField="infrastructure"
            name="Infrastructure"
          />
          <ArgumentAxis
            title="Resources"
            label={{
              font: { size: 5 }
            }}
          />
          <ValueAxis
            title="Cost"
            label={{
              font: { size: 5 }
            }}
          />
          <Tooltip
            enabled={true}
            customizeTooltip={(info: any) => {
              return {
                text: `${info?.argumentText}\n${info?.seriesName}:  ${convertToUSD(
                  info?.valueText
                )}`
              };
            }}
          />
          <center><Title text="Total Expenses (YTD)" /></center>
          <Legend />
        </Chart>
      </div>
    </Card>
  );
};

export default ExpensesChartCard;
