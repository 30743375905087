import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  editDataProcessingApi,
  fetchDataProcessAddFilesApi,
  getDataProcessingDataApi,
  fetchDataProcessingMyFilesApi,
  fetchtemplatedropdown,
} from "../../service/datastudio/api-service";
import { DataProcessingState } from "../../pages/datastudio/dataAnalyze/types";
import { useCallback, useEffect, useReducer, useState } from "react";
import { formatDate } from "devextreme/localization";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { useApp } from "../../contexts/app";
import { useHelperHooks } from "../helper/utils";
import { exportToCSV } from "../../utlis/helper";
import { useFetchFunctionDropDownData } from "../app/useApps";

export const initialState: DataProcessingState = {
  basicFunctionType: { functionType: "", value: false },
  fileDetails: { filename: "", filepath: "" },
  gridDataSource: [],
  isRowUpdated: false,
  paramsFileData: { filename: "", filepath: "", isFileUploaded: false },
  tabView: {
    addFiles: {
      type: "addFiles",
      toggle: true,
    },
  },
  showConfirmModal: false,
  selectedTemplate: "Select a Template",
  totalrowsvalidated: 0,
  totalrowsprocessed: 0,
  totalrowswitherrors: 0,
  isVisibile: false,
  replaceNullConfirmModal: false,
  columnData: [],
};

export const reducer = (state: DataProcessingState, action: any) => {
  switch (action?.type) {
    case "SELECTED_BASIC_FUNCTION":
      return { ...state, basicFunctionType: action?.payload };
    case "SET_TAB_VIEW":
      return {
        ...state,
        tabView: {
          [action.payload.name]: {
            type: action.payload.name,
            toggle: action.payload.toggle,
          },
        },
      };
    case "SET_GRID_DATA_SOURCE":
      return { ...state, gridDataSource: action?.payload };
    case "SET_FilE_DETAILS":
      return { ...state, fileDetails: action?.payload };
    case "SET_EDITING_DATA":
      return { ...state, isRowUpdated: action?.payload };
    case "SET_PRAMS_FILE_DATA":
      return { ...state, paramsFileData: action.payload };
    case "SET_CONFIRM_MODAL":
      return { ...state, showConfirmModal: action.payload };
    case "SET_SELECTED_TEMPLATE":
      return { ...state, selectedTemplate: action.payload };
    case "RESET_SELECTED_TEMPLATE":
      return { ...state, selectedTemplate: "Select a Template" };
    case "SET_TOTAL_ROWS_PROCESSED":
      return { ...state, totalrowsprocessed: action.payload };
    case "SET_TOTAL_ROWS_VALIDATED":
      return { ...state, totalrowsvalidated: action.payload };
    case "SET_TOTAL_ROWS_ERRORS":
      return { ...state, totalrowswitherrors: action.payload };
    case "RESET_TOTAL_ROWS":
      return {
        ...state,
        totalrowsprocessed: 0,
        totalrowsvalidated: 0,
        totalrowswitherrors: 0,
      };
    case "VISIBLE_VALIDATION_STATS":
      return { ...state, isVisibile: action.payload };
    case "REPLACE_NULL_CONFIRM_MODAL":
      return { ...state, replaceNullConfirmModal: action.payload };
    case "GET_COLUMN_DATA":
      return { ...state, columnData: action.payload };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useDataAnalyzer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const folder_Name = "Data Analyzer";
  const queryClient = useQueryClient();
  const workbookFileName = useHelperHooks().getQueryParams("fileName");
  const workbookFilePath = useHelperHooks().getQueryParams("filePath");
  const { user } = useApp();
  const { id: userId, name: userName } = user || {};
  const { mutate: createMutate, isLoading: isFileUploading } =
    useCreateDataProcessing();
  const { mutate, responseData, isLoading: isDataLoading, } = useEditDataProcessing();

  const setIsVisible = (value: boolean) => {
    dispatch({ type: "VISIBLE_VALIDATION_STATS", payload: value });
  };

  const handleApplyClick = () => {
    hanldeOnclickApply();
    setIsVisible(true);
  };

  const onClose = () => {
    dispatch({ type: "REPLACE_NULL_CONFIRM_MODAL", payload: false });
  };

  const dataProcessing = useGetDataProcessingDataApi(state.paramsFileData);

  const { data: fetchFunctionDropDownFeatures } = useFetchFunctionDropDownData("dropdown");

  const {
    data: userRecentData,
    isLoading: isFilesLoading,
    isFetching: isFilesFetching,
    refetch: refetchUserRecentData
  } = useFetchDataProcessingRecentUserGrid(userId);

  const handleShowAnalyzedData = () => {
    dispatch({
      type: "SET_TAB_VIEW",
      payload: {
        name: "resultGrid",
        toggle: !state.tabView["resultGrid"]?.toggle,
      },
    });
  };

  const handleSelectionChanged = (e: any) => {
    const selectedItem = e?.item;
    if (selectedItem) {
      dispatch({ type: "SET_SELECTED_TEMPLATE", payload: selectedItem });
    }
  };

  useEffect(() => {
    if (responseData?.rows_count) {
      dispatch({ type: "SET_TOTAL_ROWS_PROCESSED", payload: responseData?.rows_count.Total_rows_processed });
      dispatch({ type: "SET_TOTAL_ROWS_VALIDATED", payload: responseData?.rows_count.Total_rows_validated });
      dispatch({ type: "SET_TOTAL_ROWS_ERRORS", payload: responseData?.rows_count.Total_no_of_errors });
  
      showNotification({
        message: "Data analyzed successfully",
        type: "success",
      });
    }
  }, [responseData]);

  useEffect(() => {
    if (state.fileDetails?.filename && state.fileDetails?.filepath) {
      dispatch({ type: "SET_PRAMS_FILE_DATA", payload: state.fileDetails });
    } else if (workbookFileName && workbookFilePath) {
      dispatch({
        type: "SET_TAB_VIEW",
        payload: {
          name: "functions",
          toggle: !state.tabView["functions"]?.toggle,
        },
      });
      dispatch({
        type: "SET_PRAMS_FILE_DATA",
        payload: { filename: workbookFileName, filepath: workbookFilePath },
      });
    }
  }, [state.fileDetails, workbookFileName, workbookFilePath]);

  useEffect(() => {
    if (dataProcessing?.data?.data) {
      dispatch({
        type: "SET_GRID_DATA_SOURCE",
        payload: dataProcessing?.data?.data || [],
      });
    }
  }, [dataProcessing?.data?.data]);

  useEffect(() => {
    if (responseData?.doc_data) {
      dispatch({
        type: "SET_GRID_DATA_SOURCE",
        payload: responseData?.doc_data || [],
      });
    }
  }, [responseData?.doc_data]);

  const onTabChange = useCallback(
    (name: string) => {
      if (name !== "validation" && name !== "functions") {
        dispatch({ type: "CLEAR" });
      }
      if (name !== "validation") {
        dispatch({ type: "RESET_SELECTED_TEMPLATE" });
      }
      dispatch({
        type: "SET_TAB_VIEW",
        payload: {
          name: name,
          toggle: !state.tabView[name]?.toggle,
        },
      });
      if (name === "functions") {
        dispatch({ type: "VISIBLE_VALIDATION_STATS", payload: true });
      }
      if(state?.totalrowsprocessed===0){
        dispatch({ type: "VISIBLE_VALIDATION_STATS", payload: false });
      }
    },
    [state.tabView, state?.totalrowsprocessed]
  );

  const handleDuplicates = useCallback((functionType: string, value: any) => {
    dispatch({
      type: "SELECTED_BASIC_FUNCTION",
      payload: { functionType, value },
    });
    if (functionType === "null replace" && value === true) {
      dispatch({ type: "REPLACE_NULL_CONFIRM_MODAL", payload: true });
      dispatch({
        type: "GET_COLUMN_DATA",
        payload: state.gridDataSource.length > 0 ? Object.keys(state.gridDataSource[0]) : [],
      });
    }
    else if(functionType==="null replace" && value===false){
      dispatch({ type: "REPLACE_NULL_CONFIRM_MODAL", payload: false });
    }
  }, [state.gridDataSource]);

  const handleSave = (files: any, fileType: any) => {
    const payload = {
      userName: userName,
      userId: userId,
      assetType: fileType,
      processType: folder_Name,
      fileName: files,
    };
    return new Promise<void>((resolve, reject) => {
      createMutate(payload, {
        onSuccess: () => {
          const fileName = files[0]?.name;
          const filePath = `users/${userId}/${folder_Name}`;
          dispatch({
            type: "SET_FilE_DETAILS",
            payload: { filename: fileName, filepath: filePath },
          });
          handleShowAnalyzedData();
          showNotification({
            message: `File uploaded successfully`,
            type: "success",
          });
          queryClient.invalidateQueries(["fetch-recent-document"]);
          resolve();
        },
        onError: (error: any) => {
          console.error("Save failed", error);
          showNotification({
            message: `Save failed. Please try again`,
            type: "error",
          });
          reject(error);
        },
      });
    });
  };

  const handleFilesUpload = async (e: any) => {
    dispatch({ type: "CLEAR" });
    const files = e.value;
    try {
      const encodedFiles = await Promise.all(
        files.map((file: any) => {
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const timestamp = formatDate(new Date(), "yyyyMMddHHmm");
              const renamedFile = `${file?.name
                ?.split(".")
                ?.slice(0, -1)
                ?.join(".")}_${timestamp}.${file?.name?.split(".")?.pop()}`;
              resolve({ name: renamedFile, content: reader?.result });
            };
            reader.onerror = (error) => reject(error);
          });
        })
      );
      const fileType = encodedFiles[0]?.name.split(".").pop();
      await handleSave(encodedFiles, fileType);
    } catch (error) {
      console.error("File upload failed", error);
      showNotification({
        message: `File upload failed. Please try again`,
        type: "error",
      });
    }
  };

  const getColumnNames = (data: Array<Record<string, any>>): string[] => {
    if (data?.length > 0) {
      return Object?.keys(data[0]);
    }
    return [];
  };

  const handleItemClick = (e: any) => {
    const item = e?.itemData;
    if (item?.text === "Download") {
      let fileName = state.fileDetails?.filename;
      if (fileName) {
        fileName = fileName.split(".").slice(0, -1).join(".");
      }
      exportToCSV(state.gridDataSource, `${fileName}.csv`);
    } else if (item?.text === "Export to Project") {
    }
  };

  const handleListClick = (e: any) => {
    const data = e;
    const formattedData = data?.itemData?.split(":");
    if ((formattedData[0] === "Errors found" || formattedData[0] === "Rows with errors") && Array?.isArray(responseData?.doc_data)) {
      const filteredData = responseData?.doc_data?.filter((row: { status_message: boolean; }) => row?.status_message === true);
      dispatch({ type: "SET_GRID_DATA_SOURCE", payload: filteredData });
      return filteredData;
    }
    if (state?.totalrowsprocessed === 0) {
      dispatch({ type: "VISIBLE_VALIDATION_STATS", payload: false });
      showNotification({
        message: "Invalid file to validate",
        type: "success",
      });
    }
    else {
      dispatch({ type: "SET_GRID_DATA_SOURCE", payload: responseData?.doc_data });
    }
  };

  const hanldeOnclickApply = (e?:any) => {
    showNotification({
      message: "Analyzing data...",
      type: "success",
    });
    const data:any = {
      filename: state.fileDetails?.filename,
      filepath: state.fileDetails?.filepath,
      process: state.basicFunctionType?.functionType,
    };
    if (state.basicFunctionType?.functionType === "null replace") {
      data.replacevalue = e?.replaceValue;
      data.columnName = e?.columnName;
    }
    if (state.selectedTemplate && state.selectedTemplate !== "Select a Template") {
      data.process = "validate";
      data.templatename = state.selectedTemplate;
    }
    mutate({ data, records: state.gridDataSource });
    dispatch({ type: "REPLACE_NULL_CONFIRM_MODAL", payload: false });
  };
  useEffect(() => {
    if (responseData) {
      showNotification({
        message: "Data analyzed successfully",
        type: "success",
      });
    }
  }, [responseData]);


  const rowUpdated = () => {
    dispatch({ type: "SET_EDITING_DATA", payload: true });
  };

  const handleSaveDocument = () => {
    showNotification({
      message: "Saving Data",
      type: "success",
    });
    const { versions } = state.gridDataSource;
    const data = {
      filename: state.fileDetails?.filename,
      filepath: state.fileDetails?.filepath,
      process: "edit",
      version: versions?.current.toString(),
    };
    mutate(
      { data, records: state.gridDataSource },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["fetch-recent-document"]);
          showNotification({
            message: "Saved Successfully",
            type: "success",
          });
        },
        onError: () => {
          showNotification({
            message: "Save Failed",
            type: "error",
          });
        },
      }
    );
  };

  const handleRowPrepared = useCallback((e: any) => {
    const { data } = e || {};
    if (data?.is_duplicate) {
      e?.rowElement?.classList?.add("duplicate-row");
    }
    if (data?.show_null === true) {
      e?.rowElement?.classList?.add("null-row");
    }
    if (data?.status_message === true) {
        e?.rowElement?.classList?.add("status-row");
    }
  }, []);

  const handleResetButton = () => {
    dispatch({ type: "SET_CONFIRM_MODAL", payload: !state.showConfirmModal });
  };

  const onFunctionReset = () => {
    dispatch({
      type: "SELECTED_BASIC_FUNCTION",
      payload: { functionType: "", value: false },
    });
    const { refetch, remove } = dataProcessing;
    remove();
    const { filename, filepath, isFileUploaded } = state.paramsFileData || {};
    if (filename && filepath && isFileUploaded) {
      refetch();
    }
    handleResetButton();
    dispatch({ type: "RESET_TOTAL_ROWS" });
    dispatch({ type: "RESET_SELECTED_TEMPLATE"});
    dispatch({ type: "VISIBLE_VALIDATION_STATS", payload: false });
  };

  const getGridData = (params: any) => {
    const { file_name, folder_path, status } = params;
    const rowTitle = `File Name - ${file_name}, Status - ${status}`;
    dispatch({
      type: "SET_FilE_DETAILS",
      payload: { filename: file_name, filepath: folder_path },
    });
    handleShowAnalyzedData();
  };

  const onActionHandler = (e: any) => {
    const { text } = e.itemData;
    if (text === "Merge") {
      dispatch({
        type: "SET_TAB_VIEW",
        payload: { name: "merge", toggle: !state.tabView["merge"]?.toggle },
      });
    }
    return null;
  };

  return {
    state,
    isFileUploading,
    dataProcessing,
    handleDuplicates,
    fetchFunctionDropDownFeatures,
    onTabChange,
    handleFilesUpload,
    getColumnNames,
    handleItemClick,
    hanldeOnclickApply,
    handleApplyClick,
    handleListClick,
    handleSelectionChanged,
    rowUpdated,
    handleSaveDocument,
    handleRowPrepared,
    handleResetButton,
    getGridData,
    onFunctionReset,
    onClose,
    onActionHandler,
    userRecentData,
    isFilesLoading,
    isFilesFetching,
    isDataLoading,
    refetchUserRecentData
  };
};

export const useGetDataProcessingDataApi = (paramsFileData: any) => {
  const { filename, filepath, isFileUploaded } = paramsFileData || {};
  return useQuery(
    ["fetch-pdf-analyze-new", filename, filepath],
    () => getDataProcessingDataApi(filename, filepath),
    {
      refetchOnWindowFocus: false,
      enabled: !!filename && !!filepath && isFileUploaded,
      select: (data: any) => {
        const getApiData = {
          data: data,
        };
        return getApiData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

export const useFetchTemplatedropdown = () => {
  return useQuery(["fetch-template-dropdown-data"], () => fetchtemplatedropdown(), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data?.templateName;
    },
    onError: (error: Error) => {
      console.error(error);
    }
  });
};

export const useFetchDataProcessingRecentUserGrid = (userId: string) => {
  const queryParams = {
    processType: "Data Analyzer",
    process: "latest",
  };
  return useQuery(
    ["fetch-recent-document", queryParams],
    () => fetchDataProcessingMyFilesApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; //used

export const useCreateDataProcessing = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(fetchDataProcessAddFilesApi, {
    onSuccess: () => {
      fnQueryClient.invalidateQueries("fetch-create-data-analyze");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; //used

export const useEditDataProcessing = () => {
  const [responseData, setResponseData] = useState<any>(null);
  const mutation = useMutation(editDataProcessingApi, {
    onSuccess: (data) => {
      setResponseData(data);
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
  return {
    ...mutation,
    responseData,
  };
}; //used
